import React from "react";
import dayoff from "assets/svg/enjoy your day off.svg";
export default function NoTaskIco() {
  return (
    <div>
      <img src={dayoff} alt="dayoff" style={{ display: "flex", justifyContent: "center", width: "300px" }} />
      <p className="text-center font-bold mt-4">Yay!! You have No tasks</p>
    </div>
  );
}

