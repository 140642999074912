import React from "react";
import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import AppSideBar from "layout/AppSideBar/AppSideBar";
import ToggleMenu from "components/ToggleMenu/ToggleMenu";
import AdminSideBar from "./AdminSideBar";

const AdminLayout = () => {
  const appSideBarState = useSelector((state) => state.left_menu_on);

  return (
    <div className="bg-white dark:bg-dark-500 dark:text-white fixed-height min-h-screen">
    {/* <AppHeader /> */}
    <motion.div className="flex gap-2 mx-auto w-full ">
      {appSideBarState.isOpen ? <AdminSideBar /> : <ToggleMenu />}
      <motion.main className={"w-full mt-4  pb-10 overflow-x-hidden overflow-y-auto h-[100vh]"}>
        <Outlet />
      </motion.main>
    </motion.div>
  </div>
  )
}

export default AdminLayout