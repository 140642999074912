import React from "react";
import "./Loading.scss";
import Lottie from "lottie-react";
import loader from "../../assets/json/loader.json";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner">
        <Lottie animationData={loader} />
      </div>
    </div>
  );
};

export default Loading;

