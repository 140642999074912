import React, { useEffect,  useState } from "react";
import { HiOutlinePlus as AddIcon } from "react-icons/hi";
// components
import TaskBar from "components/TaskBar/TaskBar";
// auth context
import { TaskbarSkeleton } from "layout/Skeleton";
import AddTaskForm from "components/AddTaskForm/AddTaskForm";
import { useSelector, useDispatch } from "react-redux";
import { getAllCompletedTasks, getAllTasksForUserAPI } from "ApiService";
import { InsertTasks } from "store/TodayTasksDataSlice";
import NoTaskIco from "assets/NoTaskIco";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TaskOptions from "Models/TaskOptions/TaskOptions";
import CompletedTasks from "components/CompletedTasks/CompletedTasks";
import { handleShowCompletedTasks } from "store/LeftMenuSlice";

const InboxTasks = () => {
  const dispatch = useDispatch();

  const todayTasks = useSelector((state) => state.todayTask.todayTasks?.filter((filData) => filData.completed === false));
  // const [todayTasks, setTodayTasks] = useState(AllTasks);
  const projectDetails = useSelector((state) => state.userProjects.projectDetails);

  const [showTaskInput, setShowTaskInput] = useState(false);

  const [EditId, setEditId] = useState("");

  const showCompletedTasks = useSelector((state) => state.left_menu_on.showCompletedTasks);
  // const [todayTasks, setTodayTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const GetTodayTasks = async () => {
    const res = await getAllTasksForUserAPI();
    if (res.data.status === 1) {
      dispatch(InsertTasks(res.data.tasks));
    }
  };

  useEffect(() => {
    GetTodayTasks();
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return; // Task was not dropped in a valid location

    const reorderedTasks = [...todayTasks];
    const [movedTask] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, movedTask);

    // setTasks(reorderedTasks);
    // console.log(reorderedTasks);
    dispatch(InsertTasks(reorderedTasks));
  };

  const handleCompletedTasksCallBack = (value) => {
    dispatch(handleShowCompletedTasks());
  };

  return (
    <>
      <div className="md:py-8 md:px-10 px-3 w-full md:max-w-8xl lg:max-w-9xl xl:max-w-8xl mx-auto">
        {/* <Header /> */}
        <div className="flex items-center justify-between w-full gap-2 static top-0 left-0">
          <div className="flex items-center gap-1">
            <span className="block text-xl font-bold">Inbox</span>

            {/* <span className="block text-sm text-gray-800 dark:text-gray-400">
              {new Date().toLocaleDateString("en-US", {
                month: "short",
                weekday: "short",
                day: "numeric",
              })}
            </span> */}
          </div>
          <TaskOptions showCompletedTasks={showCompletedTasks} setShowCompletedTasks={handleCompletedTasksCallBack} />
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="task-list">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {todayTasks.map((task, index) => (
                  <Draggable key={task?.id?.toString()} draggableId={task?.id?.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // className='border p-2'
                      >
                        <TaskBar key={task.id} task={task} tasks={todayTasks} setEditId={setEditId} EditId={EditId} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {showTaskInput ? (
          <AddTaskForm formAbort={() => setShowTaskInput(false)} isQuick={false} />
        ) : (
          <>
            {!isLoading && (
              <button
                type="button"
                className="flex gap-2 py-2 my-2 items-center group w-full"
                onClick={() => {
                  setShowTaskInput(true);
                }}>
                <span className="text-primary transition-all group-hover:rounded-full group-hover:bg-primary group-hover:text-white p-1" aria-hidden="true">
                  <AddIcon />
                </span>
                <span className="group-hover:text-primary dark:text-gray-400">Add task</span>
              </button>
            )}
          </>
        )}
        {showCompletedTasks && <CompletedTasks />}
        {todayTasks?.length <= 0 && !isLoading && !showCompletedTasks && (
          <>
            {!showTaskInput && (
              <div className="flex items-center flex-col gap-2">
                <NoTaskIco />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};



export default InboxTasks;