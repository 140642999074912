import React, { useState } from "react";
import EyeOpen from "assets/svg/eye-off.svg";
import EyeClosed from "assets/svg/eye-on.svg";
import { FeatherIcons } from "assets/svg/feather-icons";
import { Link } from "react-router-dom";
import api from "../../Services/api"; // Import your common API
import Loading from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { CaptureAuthData } from "store/AuthDataSlice";
import { useDispatch } from "react-redux";
import login from "assets/svg/login.svg";
import LogoSmall from "assets/svg/taskpareto.svg";
import GoogleButton from "./GoogleButton";
import VerifyEmail from "./VerifyEmail";
import { useForm } from "react-hook-form"
import { validationRegex } from "utils/Regex";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
export const LoginForm = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  /**
   * type 1 is regular login
   * type 2 is social login
   */
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [formState, setFormState] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLoginSubmit = async (event) => {
    setAuthenticating(true);
    const { email, password } = event;

    setFormState({
      email: email,
      password: password,
    });
    setLoading(true);

    try {
      const response = await api.login({ email: email.toLowerCase(), password, type: 1 }).finally(() => {
        setLoading(false);
      });
      console.log(response, "respomvee");
      if (response.status === 1 && response.data.user.blockUser === 0) {
        enqueueSnackbarWithClose(response.message, { variant: "success" });
        setLoading(false);

        localStorage.setItem("refresh_token", response.data.refreshToken);
        localStorage.setItem("access_token", response.data.accessToken);
        let loginData = { refreshToken: response.data.refreshToken, accessToken: response.data.accessToken, userDetails: response.data.user };
        dispatch(CaptureAuthData(["email", loginData, true]));
        navigate("/app/today");
      } else if (response.data.user.blockUser === 0) {
        enqueueSnackbarWithClose(response.message, { variant: "error" });
        setLoading(false);
      } else {
        enqueueSnackbarWithClose("Your Account Has Been Blocked By Admin", { variant: "error" });
      }

      setAuthenticating(false);
    } catch (error) {
      if (error.response.status === 500) {
        const rateLimitMessage = error.response.headers.get('x-ratelimit-message');
        enqueueSnackbarWithClose(rateLimitMessage, { variant: "error" });
      }
      if (error?.response?.data.status === 0 && error?.response?.data?.type === 0) {
        setShowVerifyEmail(true);
      }
      if (error.response.status === 401) {
        enqueueSnackbarWithClose(error?.response?.data?.message, { variant: "error" });
      }
      setAuthenticating(false);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <section className="text-gray-800 body-font">
        <div className="container-fluid mx-auto ">
          <div className="flex flex-wrap -mx-4 -mb-10 text-center">
            <div className="sm:w-1/2     xs:hidden lg:block">
              <div className="max-w-screen-xl m-auto">
                <div className="w-full relative select-none">
                  <div className="sideImg">
                    <img src={login} style={{ height: "100vh", margin: "0 auto" }} alt="main app logo" />{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:w-1/2">
              <div className="error-block"></div>
              <div className="auth-page__logo">
                <Link to="/">
                  <img src={LogoSmall} width={"100px"} alt="app small logo" />
                </Link>
              </div>
              {!showVerifyEmail ? (
                <div className="signup">
                  <div className="signup__wrapper">
                    <div className="step_one">
                      <GoogleButton />

                      <div className="separator">
                        <div className="middle_separator">OR</div>
                      </div>
                      <form className="login-form" onSubmit={handleSubmit(handleLoginSubmit)}>
                        <div className="field">
                          <label htmlFor="email" className="label">
                            Email
                          </label>
                          <input {...register("email", { required: { value: true, message: "please enter email Id" }, pattern: { value: validationRegex.Email_valid_regex, message: "please enter valid email Id" } })} placeholder="Enter Email" id="email" />

                          <span className="error-message">
                            {errors?.email?.message && <FeatherIcons id="alert-circle" width={20} height={20} fill="#db4c3f" stroke={"#fff"} strokeWidth={2} currentColor={"#fff"} />}
                            {errors?.email?.message}
                          </span>
                        </div>
                        <div className="field">
                          <label className="label" htmlFor="password">
                            Password
                          </label>
                          <div className="toggle_password">
                            <input {...register("password", { required: { value: true, message: "please enter password" } })} className="form_field_control" type={showPassword ? "text" : "password"} id="password" placeholder="Enter password" />
                            <span className="toggle" role="checkbox" tabIndex="0" aria-checked="false" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <img src={EyeOpen} alt="Show Password" /> : <img src={EyeClosed} alt="Hide Password" />}
                            </span>
                          </div>
                          <div className="error-message">
                            {errors?.password?.message && <FeatherIcons id="alert-circle" width={20} height={20} fill="#db4c3f" stroke={"#fff"} strokeWidth={2} currentColor={"#fff"} />}
                            {errors?.password?.message}
                          </div>
                        </div>
                        <button className="auth-button submit-button" disabled={authenticating} type="submit">
                          Log in
                          {authenticating && <Loading />}
                        </button>
                        <hr />
                        <div className=" flex justify-between">
                          <p>
                            Don't have an account? <Link to="/signup">Sign Up</Link>
                          </p>
                          <p>
                            <Link to="/forgotPassword">Forgot Password!</Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <VerifyEmail userData={formState} setShowVerifyEmail={setShowVerifyEmail} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );






























};