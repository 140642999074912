import React, { useEffect, useState } from 'react'
import { Table } from 'ka-table';
import { DataType, SortingMode } from 'ka-table/enums';
import { BsFillEyeFill } from 'react-icons/bs'
import { AdminGetUsersDetailsApi, AdminLoginWithUserId, AdminToggleBlockUserApi } from 'ApiService';
import { enqueueSnackbar } from 'notistack';
import { Tooltip } from 'react-tooltip'
import { AiOutlineInfoCircle, AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ImBlocked } from "react-icons/im";
import { IoIosLogIn } from "react-icons/io";
import { CaptureAuthData } from 'store/AuthDataSlice';
import { useDispatch } from "react-redux";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const AdminUsers = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [appUsers, setAppUsers] = useState([])
  const [searchText, setSearchText] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [blockUserDetails, setBlockUserDetails] = useState('')
  const AdminGetUsersDetailsApiCall = async () => {
    try {
      const result = await AdminGetUsersDetailsApi()
      if (result.data.status === 1) {
        const mapForSerial = result.data.data.users.map((data, index) => {
          return { ...data, serialNo: index + 1 }
        })
        setAppUsers(mapForSerial)

      } else {
        enqueueSnackbarWithClose(result.data.message, { variant: 'error' })
      }
    } catch (error) {
      console.log(error);
    }



  }

  useEffect(() => {
    AdminGetUsersDetailsApiCall()
  }, [])

  const blockUnBlockUser = async (userId) => {
    try {
      const blockUserApi = await AdminToggleBlockUserApi(userId).finally(() => {
        setBlockUserDetails('')
        setShowConfirmModal(false)
      })
      console.log(blockUserApi, 'blockUserApi');
      if (blockUserApi.data.status === 1) {
        // if(blockUserDetails.blockUser===)
        const blockMapData = appUsers.map((data, index) => {
          if (data.id === userId) {
            return { ...data, blockUser: blockUserDetails.blockUser === 0 ? 1 : 0 }
          }
          return data
        })
        setAppUsers(blockMapData)

        enqueueSnackbarWithClose(blockUserApi.data.message, { variant: 'success' })

      } else {
        enqueueSnackbarWithClose(blockUserApi.data.message, { variant: 'error' })
      }

    } catch (error) {

    }

  }

  const handleAdminLoginAsUser = async (userId) => {
    let screen_width = window.innerWidth
    let screen_height = window.innerHeight

    let AdminPay = {
      "userId": userId
    }
    try {
      const AdminLoginAsUser = await AdminLoginWithUserId(AdminPay)
      if (AdminLoginAsUser.data.status === 1) {
        localStorage.setItem("refresh_token", AdminLoginAsUser.data.data.refreshToken);
        localStorage.setItem("access_token", AdminLoginAsUser.data.data.accessToken);
        let loginData = { refreshToken: AdminLoginAsUser.data.data.refreshToken, accessToken: AdminLoginAsUser.data.data.accessToken, userDetails: AdminLoginAsUser.data.data.user };
        dispatch(CaptureAuthData(["email", loginData, true]));
        window.open('/', '_blank', `height=${screen_height},width=${screen_width}`)
        enqueueSnackbarWithClose(`Logged in successfully as ${AdminLoginAsUser.data.data.user.username}`, { variant: 'success' })
      } else {
        enqueueSnackbarWithClose(AdminLoginAsUser.data.message, { variant: 'error' })
      }

    } catch (error) {
      console.log(error, 'error');
    }


  }
  const AlertCell = ({
    rowData,
  }) => {
    return (
      <div className=' flex gap-3 justify-center align-middle items-center'>
        <IoIosLogIn
          // onClick={()=>navigate(`/admin/userDetails/${rowData.id}`)}
          onClick={() => handleAdminLoginAsUser(rowData.id)}
          data-tooltip-id="my-tooltip" data-tooltip-content={`Login as ${rowData.username}`}
          className='alert-cell-button text-2xl cursor-pointer text-purple-700 z-20'
        />
        <BsFillEyeFill
          onClick={() => navigate(`/admin/userDetails/${rowData.id}`)}
          data-tooltip-id="my-tooltip" data-tooltip-content="View User Details"
          className='alert-cell-button text-xl cursor-pointer text-blue-700 z-20'
        />
        {rowData.blockUser === 0 ? <ImBlocked data-tooltip-id="my-tooltip" data-tooltip-content="Block user"
          className='alert-cell-button text-xl cursor-pointer text-green-700'
          onClick={() => { setBlockUserDetails(rowData); setShowConfirmModal(true) }}
        /> : <ImBlocked data-tooltip-id="my-tooltip" data-tooltip-content="Unblock user"
          className='alert-cell-button text-xl cursor-pointer  text-red-700'
          onClick={() => { setBlockUserDetails(rowData); setShowConfirmModal(true) }}
        />}
        {/* <AiFillDelete
          className='alert-cell-button text-2xl cursor-pointer text-danger-600'
          onClick={() => alert(`Row data: \r\n${JSON.stringify(rowData)}`)}
        /> */}
      </div>

    );
  };

  return (
    <>
      <Tooltip id="my-tooltip" place='top' noArrow offset={0} style={{ zIndex: '999999' }} />

      <div className="md:py-8 md:px-10 px-3 w-full md:max-w-8xl lg:max-w-9xl xl:max-w-8xl mx-auto">

        <input type='search' value={searchText} onChange={(event) => {
          setSearchText(event.currentTarget.value);
        }} className='top-element border-2 p-2 mb-2' placeholder='Search...' />
        <Table
          columns={[
            { key: 'serialNo', title: 'ID', dataType: DataType.String, width: 40 },
            { key: 'username', title: 'user Name', dataType: DataType.String, style: { wordBreak: 'break-all' } },
            { key: 'email', title: 'E-Mail', dataType: DataType.String, style: { wordBreak: 'break-all' }, },
            { key: 'createdTasksCount', title: 'Total Tasks Created', dataType: DataType.String, width: 120 },
            { key: 'completedTasksCount', title: 'Completed Tasks Count', dataType: DataType.String, width: 120 },
            { key: 'overdueTasksCount', title: 'Overdue Tasks Count', dataType: DataType.String, width: 120 },
            {
              key: 'view',
              title: 'Actions',
              style: { textAlign: 'center' },
              // width: 170
            },
          ]}
          data={appUsers}
          // editingMode={EditingMode.Cell}
          rowKeyField={'serialNo'}
          sortingMode={SortingMode.Single}
          paging={{
            enabled: true,
            pageIndex: 0,
            pageSize: 15,
            // pageSizes: [5, 10, 15],
            // position: PagingPosition.Bottom
          }}
          search={({ searchText: searchTextValue, rowData, column }) => {
            if (column.key === 'passed') {
              return (searchTextValue === 'false' && !rowData.passed) || (searchTextValue === 'true' && rowData.passed);
            }
          }}
          searchText={searchText}
          noData={{
            text: 'No Data Found'
          }}
          columnResizing={true}
          childComponents={{
            cellText: {
              content: (props) => {
                switch (props.column.key) {
                  case 'view': return <AlertCell {...props} />;
                }
              }
            },
            // dataRow: {
            //   elementAttributes: ({ rowData }) => ({
            //     style: {
            //       backgroundColor: rowData.overdueTasksCount > 0 ?  'rgba(255, 0, 0, 0.1)':'rgba(0, 255, 0, 0.1)' 
            //     },
            //     title: `${rowData.username} has ${rowData.overdueTasksCount} Overdue Tasks`
            //   })
            // }
          }}
        />
      </div>

      {showConfirmModal &&
        <div className="h-screen w-screen fixed top-0 left-0 z-50 pt-10 sm:p-10">
          <div
            className=" h-full w-full absolute top-0 left-0 bg-black/50 z-10 cursor-pointer"
          />
          <div
            className="modal-overlay-popper"
            onClick={(e) => setShowConfirmModal(false)}
          >
            <div className="modal-popper quickAddTaskModal rounded-md"
            >
              <div onClick={(e) => e.stopPropagation()}>
                <div className='confirmCancel-modal '>
                  <header className='p-1 flex justify-between'>
                    <div>
                      <button className='display-action-icons'>
                        <AiOutlineInfoCircle />
                      </button>
                    </div>
                    <div>
                      <button onClick={(e) => setShowConfirmModal(false)} className='display-action-icons'>
                        <AiOutlineClose />
                      </button>
                    </div>
                  </header>
                  <div className=' mb-10'>
                    <span className='text-black-500 text-left text-lg'>
                      Are you sure want to {blockUserDetails.blockUser === 0 ? "block" : "unblock"}
                    </span>
                    <span className=' text-danger-600 text-lg ml-2'>{blockUserDetails.username}</span>
                  </div>
                  <footer className=''>
                    <div className='flex justify-end gap-2'>
                      <button onClick={(e) => setShowConfirmModal(false)} className='cancel-button'>
                        cancel
                      </button>
                      <button onClick={(e) => blockUnBlockUser(blockUserDetails.id)} className='confirm-danger-button'>
                        Confirm {blockUserDetails.blockUser === 0 ? "block" : "unblock"}
                      </button>
                    </div>
                  </footer>
                  <div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>}
    </>
  )
}

export default AdminUsers