import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TfiDashboard } from 'react-icons/tfi'
import { FaUsers } from 'react-icons/fa'

export const AdminProjects = () => {
    const location = useLocation();


    return (
        <div className="sidebar-menu">
            {/* <div onClick={() => handleWorkSpaceOpen()} className="project-group">
         <div className="project-group__group">
           <div className="project-group__icon">
             <Icon icon="mingcute:search-3-line" color="#2c3f59" width="22" />
           </div>
           <div className="project-group__name">Search</div>
         </div>
       </div> */}
            {/* {handleSearchModal && <GlobalSearch open={handleWorkSpaceOpen} close={handleWorkSpaceClose} />} */}
            <NavLink to={"/admin/dashboard"} className={({ isActive }) => (isActive ? "active project-group" : "project-group")}>
                <div className="project-group__group">
                    <div className={({ isActive }) => (isActive ? "active project-group__icon" : "project-group__icon")}>
                        <TfiDashboard fontSize={24} isActive={location.pathname === "/app/dashboard"} />
                    </div>

                    <div className="project-group__name">Dashboard</div>
                </div>

            </NavLink>
            <NavLink to={"/admin/users"} className={({ isActive }) => (isActive ? "active project-group" : "project-group my-1")}>
                <div className="project-group__group">
                    <div className={({ isActive }) => (isActive ? "active project-group__icon" : "project-group__icon")}>
                        <FaUsers fontSize={24} isActive={location.pathname === "/app/users"} />
                    </div>
                    <div className="project-group__name">Users</div>
                </div>
            </NavLink>
        </div>
    );
};








