import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AiOutlineQuestionCircle, AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt, BiDotsHorizontalRounded as MenuIcon } from "react-icons/bi";
import { MdOutlineDoNotDisturbOn, MdMoreHoriz, MdNextWeek, } from "react-icons/md";
import { LiaCouchSolid, LiaSun } from "react-icons/lia";
import { BsFlagFill, BsFlag, BsList, BsArrowRightCircle } from "react-icons/bs";

// import "./MoreSubTaskActions.scss";
import { TodayIcon } from "DateHelpers/today-icon";
import { updateSubTaskForTask } from "ApiService";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import {  subTaskAction } from "store/TodayTasksDataSlice";
import { extractNumberFromString } from "utils/utils";
import { IoAlarmOutline, IoCopyOutline, IoExtensionPuzzleOutline, IoLinkOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { usePopper } from "react-popper";
//redux

import ConfirmSubTaskDeleteModal from "Models/ConfirmSubTaskDeleteModal/ConfirmSubTaskDeleteModal";
import QuickActionForCompleteTask from "ActionItems/QuickActionForCompleteTask/QuickActionForCompleteTask";
import { BsCalendar2Event } from 'react-icons/bs';
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const MoreSubTaskActions = (props) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();

  const { subTaskInfo, callBackAction, deleteCallbackAction } = props;
  const dispatch = useDispatch();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "left"
  });
  const popperStyles = {
    ...styles.popper,
    // display: isShown ? "block" : "none"
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [deleteModal, setDeleteModal] = useState(false);
  const handleViewOption = (event) => {
    const buttonRect = event.target.getBoundingClientRect();
    const top = buttonRect.bottom + window.scrollY - 50; // Position modal below the button
    const left = buttonRect.left - 300;
    setModalPosition({ top, left });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  //--------------------------_API CALL FOR SCHEDULE-----------------------//

  async function handleQuickSchedule(dateValue) {
    const subTaskpayload = {
      title: subTaskInfo.task,
      description: subTaskInfo.description,
      dueDate: dateValue.date,
      priority: `priority${extractNumberFromString(subTaskInfo.priority)}`,
      reminders: [],
      labels: [],
      category: "",
      isFavorite: false,
    };
    try {
      const updateOnlyDateRes = await updateSubTaskForTask(subTaskInfo.taskId, subTaskInfo.id, subTaskpayload);
      if (updateOnlyDateRes.data.status === 1) {
        enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "success" });
        //moving the update to store
        //----------------------------------SUB TASK EDIT-------------------------//
        dispatch(subTaskAction(updateOnlyDateRes.data.data)) //update to redux state
        // dispatch(UpdateTask(updateOnlyDateRes.data.data.task));
        setIsShown(false)
        //** Call this function in parent to update the local state */
        callBackAction(subTaskInfo.id, updateOnlyDateRes.data.data)
      } else {
        enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  }

  //--------------------------_API CALL FOR PRIORIT-----------------------//

  const handleQuickPrior = async (priorValue) => {
    console.log(priorValue, 'priorValue');
    const subTaskpayload = {
      title: subTaskInfo.task,
      description: subTaskInfo.description,
      dueDate: subTaskInfo.dueDate,
      priority: `priority${priorValue}`,
      reminders: [],
      labels: [],
      category: "",
      isFavorite: false,
    };
    const updateTaskAPIResponse = await updateSubTaskForTask(subTaskInfo.taskId, subTaskInfo.id, subTaskpayload);
    if (updateTaskAPIResponse.data.status === 1) {
      enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: "success" });
      //----------------------------------SUB TASK EDIT-------------------------//
      dispatch(subTaskAction(updateTaskAPIResponse.data.data)) //update to redux state
      setIsShown(false)
      //** Call this function in parent to update the local state */

      callBackAction(subTaskInfo.id, updateTaskAPIResponse.data.data)

    } else {
      enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: "error" });
    }
  };

  const RenderComponent = () => {
    return (
        <BsCalendar2Event className='mr-1' fontSize={'21px'} 
        // fill={"#5297ff"} 
        />
    )
  };
  return (
    <>
      <button ref={setReferenceElement} onClick={(e) => setIsShown((x) => !x)} type="button" className="text-2xl dark:text-white/50  text-black/70 h-full md:opacity-0 group-hover:opacity-100 transition-all hover:bg-black/10">
        <MenuIcon />
      </button>

      {isShown && (
        <>
          <div className="modal-overlay-popper" onClick={() => setIsShown(false)}>
            <div onClick={(e) => e.stopPropagation()} ref={setPopperElement} style={popperStyles} {...attributes.popper}>
              <div className="modal-popper taskMoreOptions rounded-md shadow-xl border" style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}>
                <ul className="more-task-options gap-1 flex-col">
                  {!subTaskInfo.completed && (
                    <>
                      <li className="hidden">
                        <div className="flex items-center py-2">
                          <BiEditAlt className="text-slate-800 text-lg mr-3  " />
                          <Link to={`/app/task/${subTaskInfo?.id}`}>Edit Task</Link>
                        </div>
                      </li>
                      {/* <li>
                        <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                          <BsList className="text-lg mr-3  " />
                          Go to Project
                        </div>
                      </li> */}
                      <hr />
                      <li>
                        <div className=" flex justify-between items-center">
                          <div className="">Due Date</div>
                          <div>
                            {/* <AiOutlineQuestionCircle className="" /> */}
                          </div>
                        </div>

                        <div className="scheduler_holder">
                          <div className="flex gap-1 p-1">
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Today" onClick={() => handleQuickSchedule({ day: "Today", date: moment().format("YYYY-MM-DD") })} className="display-action-icons">
                              <TodayIcon color={"#25b84c"} className="action-icon text-lg" />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Tomorrow" onClick={() => handleQuickSchedule({ day: "Tomorrow", date: moment().add(1, "day").format("YYYY-MM-DD") })} className="display-action-icons">
                              <LiaSun className="scheduler-icon" fill={"#ff9a14"} />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Weekend" onClick={() => handleQuickSchedule({ day: "Weekend", date: moment().day("Saturday").format("YYYY-MM-DD") })} className="display-action-icons">
                              <LiaCouchSolid className="scheduler-icon" fill={"#5297ff"} />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Next Week" onClick={() => handleQuickSchedule({ day: "Next week", date: moment().add(7, "days").format("YYYY-MM-DD") })} className=" display-action-icons">
                              <MdNextWeek className="scheduler-icon" fill={"#a970ff"} />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="No Due" onClick={() => handleQuickSchedule({ day: null, date: null })} className="display-action-icons">
                              <MdOutlineDoNotDisturbOn className="scheduler-icon" color={"grey"} />
                            </button>
                            {/* <button title="More Actions" className="display-action-icons">
                              <MdMoreHoriz className="scheduler-icon" color={"grey"} />
                            </button> */}
                            {/* <button className="flex items-center"> */}
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Open Calender">
                              <QuickActionForCompleteTask modalType={"quickCalender"} getTaskDateData={handleQuickSchedule} rescheduleViewContent={<RenderComponent />} />
                            </button>
                            {/* </button> */}
                          </div>
                        </div>
                      </li>
                      <hr />
                      <li>
                        <div className=" flex justify-between items-center">
                          <div className="mb-2">Priority</div>
                          {/* <div>
                            <AiOutlineQuestionCircle className="" />
                          </div> */}
                        </div>
                        <div className="scheduler_holder">
                          <div className="flex">
                            <span onClick={() => handleQuickPrior(1)} className={`flex items-center display-action-icons priorityListItem ${extractNumberFromString(subTaskInfo.priority) === 1 ? `${subTaskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlagFill className="mr-1 prior-1" />
                              </span>
                            </span>
                            <span onClick={() => handleQuickPrior(2)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${extractNumberFromString(subTaskInfo.priority) === 2 ? `${subTaskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlagFill className="mr-1 prior-2" />
                              </span>
                            </span>
                            <span onClick={() => handleQuickPrior(3)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${extractNumberFromString(subTaskInfo.priority) === 3 ? `${subTaskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlagFill className="mr-1 prior-3" />
                              </span>
                              {/* {selectedPriorValue === 3 && <span> <BsDot className='text-red-600' /></span>} */}

                              {/* ${selectedPriorValue === 3 ? "selectedPrior" : ""} */}
                            </span>
                            <span onClick={() => handleQuickPrior(4)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${extractNumberFromString(subTaskInfo.priority) === 4 ? `${subTaskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlag className="mr-1 prior-4" />
                              </span>
                              {/* {selectedPriorValue === 4 && <span> <BsDot className='text-red-600' /></span>} */}
                            </span>
                          </div>
                        </div>
                      </li>
                      <hr />
                      <li>
                        <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                          <IoAlarmOutline className="  text-lg mr-3  " /> Reminders
                        </div>
                      </li>
                      <hr />
                      <li>
                        <div className="flex items-center text-slate-300 cursor-not-allowed py-2">
                          <BsArrowRightCircle className="  text-lg mr-3  " />
                          Move to Project
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                          <IoCopyOutline className="  text-lg mr-3  " /> Duplicate
                        </div>
                      </li>
                      <li>
                        <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                          <IoLinkOutline className=" text-lg mr-3  " />
                          Copy link to task
                        </div>
                      </li>
                      <hr />
                      <li>
                        {" "}
                        <li>
                          <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                            <IoExtensionPuzzleOutline className=" text-lg mr-3  " />
                            Add Extension
                          </div>
                        </li>
                      </li>
                      <hr />
                    </>
                  )}
                  <li>
                    <div
                      className="menu-item-styles flex justify-start items-center text-red-500 cursor-pointer"
                      onClick={(e) => {
                        setIsShown(false);
                        setDeleteModal(true);
                      }}>
                      <div>
                        <AiOutlineDelete className="text-lg mr-3 " />
                      </div>
                      Delete task
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
      {deleteModal && <ConfirmSubTaskDeleteModal isOpen={deleteModal} setDeleteModal={setDeleteModal} subTaskInfo={subTaskInfo} deleteCallbackAction={deleteCallbackAction} />}
    </>
  );
};

export default MoreSubTaskActions;



