import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { menuAction } from "store/LeftMenuSlice";
import AppLogout from "./AppLogout";
import Settings from "Models/Settings/Settings";
import { useNavigate } from "react-router-dom";
import AddWorkSpace from "Models/WorkSpace/AddWorkSpace";
import AddQuickTask from "Models/AddQuickTask/AddQuickTask";

const NewHead = ({ openSidebar, setOpenSidebar }) => {
  const navigate = useNavigate();
  const userAuthData = useSelector((state) => state.AuthData);
  const dispatch = useDispatch();
  const [openSettings, setOpenSettings] = useState(false);
  const [openWorkSpace, setOpenWorkSpace] = useState(false);
  const [openQuickTask, setOpenQuickTask] = useState(false);

  const handleQuickTaskOpen = () => {
    setOpenQuickTask(true);
  };
  const handleQuickTaskClose = () => {
    setOpenQuickTask(false);
  };

  const [popups, setPopups] = useState({
    user: false,
    notification: false,
  });
  const handleParetoSideBar = () => {
    dispatch(menuAction());
  };

  const handleSettingsOpen = () => {
    setOpenSettings(true);
    setPopups({
      user: false,
      notification: false,
    });
  };
  const handleSettingsClose = () => {
    setOpenSettings(false);
  };

  const handleWorkSpaceOpen = () => {
    setOpenWorkSpace(true);
    setPopups({
      user: false,
      notification: false,
    });
  };
  const handleWorkSpaceClose = () => {
    setOpenWorkSpace(false);
  };

  return (
    <>
      <div className="App-header w-full py-1">
        <div className="flex-between">
          <div className="">
            <button
              type="button"
              className=""
              onClick={() => {
                setPopups({
                  user: !popups.user,
                  notification: false,
                });
              }}>
              <div className="flex items-center">
                {userAuthData?.loginData?.userDetails?.profileImage ? <img referrerPolicy="no-referrer" className=" rounded-[50%] w-12 h-12 object-cover" src={userAuthData?.loginData?.userDetails?.profileImage} alt={userAuthData?.loginData?.userDetails?.username?.charAt(0)} /> : <span className="profileImage">{userAuthData?.loginData?.userDetails?.username?.charAt(0)}</span>}{" "}
                <span className="welcome">
                  <span className="text-xs">Welcome,</span>
                  <span className="font-semibold"> {userAuthData?.loginData?.userDetails?.username}</span>
                </span>
                {/* {userAuthData?.loginData?.userDetails?.profileImage ? <img src={userAuthData?.loginData?.userDetails?.profileImage} alt={userAuthData?.loginData?.userDetails?.username} className="h-7 aspect-square rounded-full object-cover border border-black/20" /> : <span className="user-display-character py-[5px] px-[10px] text-red-500 uppercase border rounded-[50%] bg-white text-base font-medium">{userAuthData?.loginData?.userDetails?.username?.charAt(0)}</span>} */}
              </div>
            </button>
            {popups.user && (
              <div
                className="modal-overlay"
                onClick={() => {
                  setPopups({
                    user: !popups.user,
                    notification: false,
                  });
                }}>
                <ul onClick={(e) => e.stopPropagation(e)} className="menu-options">
                  <li className="menu-item" onClick={handleSettingsOpen}>
                    <Icon icon="mi:settings" width="25" className="svgIco" />
                    Settings
                  </li>
                  <li className="menu-item" onClick={handleWorkSpaceOpen}>
                    <Icon icon="pajamas:work" className="svgIco" />
                    Add WorkSpace
                  </li>

                  <hr />
                  <span className="hidden">
                    <li className="menu-item-disabled">
                      <Icon icon="ic:outline-color-lens" className="svgIco" />
                      Themes
                    </li>
                    <li className="menu-item-disabled">
                      <Icon icon="eva:activity-fill" className="svgIco" />
                      Activity Logs
                    </li>
                    <li className="menu-item-disabled">
                      <Icon icon="majesticons:printer-line" className="svgIco" />
                      Print
                    </li>
                    <li className="menu-item-disabled">
                      <Icon icon="material-symbols:library-add-outline" className="svgIco" />
                      Integration
                    </li>
                    <hr />
                    <li className="menu-item-disabled">
                      <Icon icon="akar-icons:star" color="gold" className="svgIco" />
                      Upgrade to Pro
                    </li>
                    <li className="menu-item-disabled">
                      <Icon icon="tdesign:user-business" className="svgIco" />
                      Upgrade to Business{" "}
                    </li>
                  </span>
                  <AppLogout />
                  <hr />
                  <li className="menu-item">
                     <span className="ml-2">V 1.4.0</span>
 
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div className="relative text-black/80 ">
              <button
                type="button"
                disabled
                className="text-xl aspect-square block p-2 rounded transition-all hover:bg-white/20 text-blue/80"
                onClick={() => {
                  navigate("/app/notification");
                }}>
                <Icon icon="akar-icons:bell" color="#2c3f59" />
              </button>

              {/* {popups.notification && <Notification />} */}
            </div>
            <button type="button" className="text-2xl rounded transition-all hover:bg-white/20 " onClick={() => handleParetoSideBar()}>
              <Icon icon="majesticons:menu-expand-left" color="#2c3f59" width="28" />{" "}
            </button>
          </div>
        </div>

        <div data-tooltip-id="my-tooltip" data-tooltip-content="Add Task">
          {" "}
          <button className="AddTaskBtn" onClick={handleQuickTaskOpen}>
            <Icon icon="akar-icons:plus" color="#fff" width="22" />
            <span className="ml-2">Add Task</span>
          </button>
        </div>

        {openSettings && <Settings open={handleSettingsOpen} close={handleSettingsClose} />}
        {openWorkSpace && <AddWorkSpace open={handleWorkSpaceOpen} close={handleWorkSpaceClose} />}
        {openQuickTask && <AddQuickTask open={handleQuickTaskOpen} close={handleQuickTaskClose} />}
      </div>
    </>
  );
};

export default NewHead;


































