import React,{useState} from 'react'
import { Icon } from "@iconify/react";
import { AiOutlineInfoCircle, AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from "react-redux";
import { AdminCaptureAuthData } from 'store/AdminAuthDetails';

const AdminAppLogout = () => {

  const dispatch = useDispatch();
  function handleAppLogout() {
    dispatch(AdminCaptureAuthData(['', false]));
    window.location.replace("/admin");
  }
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  return (
    <>
      <button onClick={()=>setShowConfirmModal(true)} className="px-3 py-3 flex w-full item-center gap-2 dark:text-gray-200/80   hover:bg-gray-100 dark:hover:bg-gray-100/10 rounded ">
        <Icon icon="heroicons-outline:logout" color="#2c3f59" width="25" />

        <div className="text-sm">Logout</div>
      </button>
      {showConfirmModal &&
          <div className="h-screen w-screen fixed top-0 left-0 z-50 pt-10 sm:p-10">
            <div
              className=" h-full w-full absolute top-0 left-0 bg-black/50 z-10 cursor-pointer"
            />
            <div
              className="modal-overlay-popper"
              onClick={(e) => setShowConfirmModal(false)}
            >
              <div className="modal-popper quickAddTaskModal rounded-md"
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <div className='confirmCancel-modal '>
                    <header className='p-1 flex justify-between'>
                      <div>
                        <button className='display-action-icons'>
                          {/* <AiOutlineInfoCircle /> */}
                        </button>
                      </div>
                      <div>
                        <button onClick={(e) => setShowConfirmModal(false)} className='display-action-icons'>
                          <AiOutlineClose />
                        </button>
                      </div>
                    </header>
                    <div className=' mb-10'>
                      <span className='text-black-500 text-left text-lg'>
                        Are you sure want to Sign out of Task Pareto
                      </span>
                    </div>
                    <footer className=''>
                      <div className='flex justify-end gap-2'>
                        <button onClick={(e) => setShowConfirmModal(false)} className='cancel-button'>
                          cancel
                        </button>
                        <button onClick={(e) => handleAppLogout()} className='confirm-danger-button'>
                          Sign Out
                        </button>
                      </div>
                    </footer>
                    <div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>}
    </>
  );
};
export default AdminAppLogout