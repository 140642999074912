import React from 'react'
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
import { Link,useNavigate } from 'react-router-dom';

const SearchTaskInfo = ({ task,closeSearch }) => {
const navigate = useNavigate()
    const handleSearchResultClick=()=>{
        closeSearch()
        navigate(`/app/task/${task?.id}`)
    }
    return (
        <>
            {/* <Link to={`/app/task/${task?.id}`} className=""> */}
            <div className='search__result__task' onClick={()=>handleSearchResultClick()}>
                <div className="py-1 w-full flex items-center gap-2">
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <div className='search_check_box'>
                        <FaRegCircle className={`mr-1 ${task?.priority}`} fontSize={'18px'} />
                        </div>
                        <div className={`task__details`}>
                            <p className="task__text">
                                {task?.title}
                            </p>
                            {/* Show the description if only if the task was nopt ocmpleted */}
                            {task?.description && <p className=" text-gray-400 text-xs">{task?.description}</p>}
                        </div>
                    </div>
                </div>
            </div>
            {/* </Link> */}
        </>
    )
}

export default SearchTaskInfo