import ScheduleIcon from "assets/svg/scheduler.svg";
import moment from "moment";
import { BsCalendar2Event } from 'react-icons/bs';

export const TaskDate = ({ date }) => {
  const reqDateFormat =moment(date).format('YYYY-MM-DD')
  moment.defaultFormat = "YYYY-MM-DD";
  /**
   * date -- 2023-10-02T00:00:00.000Z   
   */
  const isToday = moment(reqDateFormat).isSame(moment().format("YYYY-MM-DD"));
  const isYesterday = moment(reqDateFormat).isSame(moment().subtract(1, "day").format("YYYY-MM-DD"));
  const isTomorrow = moment(reqDateFormat).isSame(moment().add(1, "day").format("YYYY-MM-DD"));
  const isNextWeek = moment(reqDateFormat).isSame(moment().add(7, "day").format("YYYY-MM-DD"));
  const isPast = moment(reqDateFormat).isBefore(moment().format("YYYY-MM-DD"));
  const isWeekend = moment(reqDateFormat, moment.defaultFormat).format("dddd") == ("Saturday" || "Sunday");

  //todo: rename this function
  const getDateCustomClass = () => {
    if (isToday) {
      return "date__today";
    }
    if (isTomorrow) {
      return "date__tomorrow";
    }
    if (isPast || isYesterday) {
      return "date__overdue";
    }
    if (isNextWeek) {
      return "date__next-week";
    }
    if (isWeekend) {
      return "date__weekend";
    }
  };
  const getDayName = () => {
    if (isToday) {
      return "Today";
    }
    if (isTomorrow) {
      return "Tomorrow";
    }
    if (isYesterday) {
      return "Yesterday";
    }
    if (isWeekend) {
      return moment(date, moment.defaultFormat).format("dddd");
    }
    // return moment(date, moment.defaultFormat).format("MMM DD");
    return moment(date).format("MMM DD");
  };
  return (
    <span className={`task__date ${getDateCustomClass()}`}>
      <BsCalendar2Event width={"18px"} height={"18px"} className='mr-2' />
      {/* {moment(date, moment.defaultFormat).format("DD MMM")} */}
      {getDayName()}
    </span>
  );
};
