import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
export const ProtectedRoutes = () => {
    // const { isUserLogged } = useSelector((state)=>state.AuthData)

  const isUserLogged = localStorage.getItem('access_token');

//   const { currentUser } = useAuth();
  
  return isUserLogged ? <Outlet /> : <Navigate to={"/"} />;
};

export const AdminProtectedRoutes =()=>{
  
  const {isAdminLogged} = useSelector((state)=>state?.AdminAuthDetails)
  
  return isAdminLogged ? <Outlet /> : <Navigate to={"/admin"} />;

}