import React, { useEffect } from "react";
import image1 from "../../assets/svg/illustration1.png";
import image2 from "../../assets/svg/illustration2.png";
import image3 from "../../assets/svg/illustration3.png";
import image4 from "../../assets/svg/illustration4.png";
const ImageSlider = () => {
  useEffect(() => {
    const scrollContainer = document.querySelector(".scroll-content");
    let scrollValue = 0;

    const scrollImages = () => {
      scrollValue += 1;
      scrollContainer.style.transform = `translateX(-${scrollValue}%)`;

      if (scrollValue === 100) {
        scrollValue = 0;
        scrollContainer.style.transition = "none";
        scrollContainer.style.transform = `translateX(0%)`;

        setTimeout(() => {
          scrollContainer.style.transition = "transform 10s linear";
          requestAnimationFrame(scrollImages);
        }, 0);
      } else {
        requestAnimationFrame(scrollImages);
      }
    };

    const scrollInterval = setInterval(() => {
      requestAnimationFrame(scrollImages);
    }, 10000);

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
      <div className="grid  gap-8 lg:grid-cols-12 sm:mx-auto">
        <div className="lg:col-span-5 flex flex-col justify-center">
          <div className="my-10">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-blue-500 uppercase rounded-full bg-teal-accent-400">Prioritize • Organize • Focus</p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block"></span>
              Stay on Top of Your Tasks
            </h2>
            <p className="text-base text-gray-800 md:text-lg">Never miss a deadline again. Our app, TaskPareto, helps you to stay organized and achieve your goals with ease from daily chores to important projects.</p>
          </div>
        </div>
        <div className="lg:col-span-7 relative flex items-center justify-center w-full dark:text-gray-50">
          <div className="scroll-container">
            <div className="scroll-content">
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image1} alt="slider 1" />
              </div>
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image2} alt="slider 2" />
              </div>
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image3} alt="slider 3" />
              </div>
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image4} alt="slider 4" />
              </div>
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image1} alt="slider 1" />
              </div>
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image2} alt="slider 2" />
              </div>
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image3} alt="slider 3" />
              </div>
              <div className="relative flex flex-shrink-0 w-full sm:w-auto">
                <img loading="lazy" className="slidingImg" src={image4} alt="slider 4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;


