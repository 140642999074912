import { useEffect, useState } from 'react';
import ScroalableCalenderPicker from 'ActionItems/ScroalableCalenderPicker/ScroalableCalenderPicker';
import { usePopper } from 'react-popper';
import moment from 'moment';
import { getDayAndDateObjFromISO } from 'DateHelpers/Datehelpers';
import { AiFillCaretDown } from 'react-icons/ai'
import { BiSolidUpArrow } from 'react-icons/bi'
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs'

import { HiOutlinePlus as AddIcon } from "react-icons/hi";
import { useSelector } from 'react-redux';
import { TaskbarSkeleton } from 'layout/Skeleton';
import UpComeAddTaskForm from './UpComeAddTaskForm/UpComeAddTaskForm';
import UpComeTaskBar from './UpComeTaskBar/UpComeTaskBar';
import UpcomeTaskEditor from './UpcomeTaskEditor/UpcomeTaskEditor';
import './styles.scss'
import { Tooltip } from 'react-tooltip'
import { useParams, useNavigate } from 'react-router-dom';
const Upcoming = () => {
  const navigate = useNavigate()

  const { weekDate } = useParams();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "bottom"
  });
  const popperStyles = {
    ...styles.popper
    // display: isShown ? "block" : "none"
  };

  const [taskSchedule, setTaskSchedule] = useState({ day: "Today", date: weekDate });
  const dateToCheck = moment(taskSchedule.date, 'YYYY-MM-DD');
  const isPresentDay = dateToCheck.isSame(moment(), 'day');
  // const upComingTasks = useSelector((state) => state.UpcomingTasks.data)
  const upComingTasks = useSelector((state) => state.todayTask.todayTasks?.filter((tData) => !tData.completed));
  const currentDate = moment();
  const overDueTasks = useSelector((state) =>
    state.todayTask.todayTasks.filter((tasks) => {
      const taskDate = moment(tasks.date);
      return taskDate.isBefore(currentDate, 'day') && !tasks.completed
    })
  );

  const [showTaskInput, setShowTaskInput] = useState(false);

  /**
* 
* @param {*} date will pass in format 2023-10-02  , returns {day: 'Saturday', date: '2023-10-02'}
*/
  function handleDateSelected(date) {
    let dateObjectToSet = getDayAndDateObjFromISO(date)
    setTaskSchedule(dateObjectToSet)
    navigate(`/app/Upcoming/${dateObjectToSet.date}`)
  }

  function handlePrevious() {
    let ExactPrevDate = moment(taskSchedule.date).subtract(6, 'days').format('YYYY-MM-DD')

    let isPrevDateValid = moment(ExactPrevDate).isBefore(moment().format("YYYY-MM-DD"))

    if (isPrevDateValid) {
      setTaskSchedule({ day: "", date: moment().format('YYYY-MM-DD') })
      navigate(`/app/Upcoming/${moment().format('YYYY-MM-DD')}`)
    } else {
      setTaskSchedule({ day: "", date: ExactPrevDate })
      navigate(`/app/Upcoming/${ExactPrevDate}`)
    }

  }
  function handleNext() {
    setTaskSchedule({ day: "", date: moment(taskSchedule.date).add(6, 'days') })
    navigate(`/app/Upcoming/${moment(taskSchedule.date).add(6, 'days').format('YYYY-MM-DD')}`)

  }
  function handleToday() {
    setTaskSchedule({ day: "", date: moment().format('YYYY-MM-DD') })
    navigate(`/app/Upcoming/${moment().format('YYYY-MM-DD')}`)
  }

  const [check, setCheck] = useState('')

  const AddModalButton = (dateProp) => {
    return (
      <button
        type="button"
        className="flex gap-2 py-2 my-2 items-center group w-full"
        onClick={() => {
          setShowTaskInput(true); setCheck(dateProp)
        }}>
        <span className="text-primary transition-all group-hover:rounded-full group-hover:bg-primary group-hover:text-white p-1" aria-hidden="true">
          <AddIcon />
        </span>
        <span className="group-hover:text-primary dark:text-gray-400">Add task</span>
      </button>
    )
  }

  const [datesArray, setDatesArray] = useState([])

  function createDateArray() {
    let array = []
    for (let i = 0; i < 6; i++) {
      let obj = { "id": i, dateStr: moment(taskSchedule.date).add(i, "days").format('YYYY-MM-DD') }
      array.push(obj)
    }
    /** API CALL */
    // getDateRangeTasks(array[0], array[5])
    setDatesArray(array)
  }

  useEffect(() => {
    const dateFormat = 'YYYY-MM-DD';
    const isValid = moment(weekDate, dateFormat, true).isValid();
    if (!isValid) {
      navigate(`/app/Upcoming/${moment().format('YYYY-MM-DD')}`)
      setTaskSchedule({ day: "Today", date: moment().format('YYYY-MM-DD') })
    } else {
      createDateArray()
    }
  }, [weekDate])

  const [EditId, setEditId] = useState("");



  return (
    <>
      <Tooltip id="my-tooltip" place='top' noArrow offset={0} />
      <div className="md:py-5 md:px-5 px-3 w-full md:max-w-8xl lg:max-w-9xl xl:max-w-8xl mx-auto">
        {/* <Header /> */}
        <div className="flex items-center justify-between w-full gap-2 static top-0 left-0 pb-5">
          <div className="flex items-center gap-2">
            <span className="block text-xl font-bold">
              <div>
                <button data-tooltip-id="my-tooltip" data-tooltip-content="Go to a different Date" ref={setReferenceElement} onClick={(e) => setIsShown((x) => !x)} type="button" className='flex items-center align-middle'>
                  <h1 className=' text-xl'>{moment(taskSchedule.date).format('MMMM DD YYYY')} </h1> {!isShown ? <span><AiFillCaretDown /></span> : <span><BiSolidUpArrow /></span>}
                </button>
                {isShown && <div className="modal-overlay-popper" onClick={() => setIsShown(false)} >
                  <div onClick={(e) => e.stopPropagation()} ref={setPopperElement} style={popperStyles} {...attributes.popper}>
                    <div className="modal-popper taskMoreOptions rounded-md shadow-xl border"
                    // style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
                    >
                      <ScroalableCalenderPicker taskSchedule={taskSchedule} handleDateSelected={handleDateSelected} />
                    </div>
                  </div>
                </div>}
              </div>
            </span>
          </div>
          <div className=' flex justify-between align-middle'>
            <button data-tooltip-id="my-tooltip" data-tooltip-content="Previous Week" onClick={() => handlePrevious()} type="button" className={`upcomming-top-nav-btns`} disabled={isPresentDay}>
              <BsFillCaretLeftFill />
            </button>
            <button data-tooltip-id="my-tooltip" data-tooltip-content="Next Week" onClick={() => handleNext()} type="button" className="upcomming-top-nav-btns">
              <BsFillCaretRightFill />
            </button>
            <button data-tooltip-id="my-tooltip" data-tooltip-content="Go To Today" disabled={isPresentDay} onClick={() => handleToday()} type="button" className="upcomming-top-nav-btns">
              Today
            </button>
          </div>

        </div>
        <section className=''>
          <div>
            <ul className=' flex items-center justify-around w-100 top-calender-date'>
              <li className=''>
                <a href={`#${taskSchedule.date}`}>
                  <div className=' flex flex-col gap-0 align-middle justify-center items-center'>
                    <span className=' text-black'>
                      {moment(taskSchedule.date).format('dddd')}
                    </span>
                    <span className=' text-primary text-xl'>
                      {moment(taskSchedule.date).format('DD')}
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href={`#${moment(taskSchedule.date).add(1, 'days').format('YYYY-MM-DD')}`}>
                  <div className=' flex flex-col gap-0 align-middle justify-center items-center'>
                    <span className=' text-black'>
                      {moment(taskSchedule.date).add(1, 'days').format('dddd')}
                    </span>
                    <span className=' text-primary text-xl'>
                      {moment(taskSchedule.date).add(1, 'days').format('DD')}
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href={`#${moment(taskSchedule.date).add(2, 'days').format('YYYY-MM-DD')}`}>
                  <div className=' flex flex-col gap-0 align-middle justify-center items-center'>
                    <span className=' text-black'>
                      {moment(taskSchedule.date).add(2, 'days').format('dddd')}
                    </span>
                    <span className=' text-primary text-xl'>
                      {moment(taskSchedule.date).add(2, 'days').format('DD')}
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href={`#${moment(taskSchedule.date).add(3, 'days').format('YYYY-MM-DD')}`}>
                  <div className=' flex flex-col gap-0 align-middle justify-center items-center'>
                    <span className=' text-black'>
                      {moment(taskSchedule.date).add(3, 'days').format('dddd')}
                    </span>
                    <span className=' text-primary text-xl'>
                      {moment(taskSchedule.date).add(3, 'days').format('DD')}
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href={`#${moment(taskSchedule.date).add(4, 'days').format('YYYY-MM-DD')}`}>
                  <div className=' flex flex-col gap-0 align-middle justify-center items-center'>
                    <span className=' text-black'>
                      {moment(taskSchedule.date).add(4, 'days').format('dddd')}
                    </span>
                    <span className=' text-primary text-xl'>
                      {moment(taskSchedule.date).add(4, 'days').format('DD')}
                    </span>
                  </div>
                </a>
              </li>
              <li>
                <a href={`#${moment(taskSchedule.date).add(5, 'days').format('YYYY-MM-DD')}`}>
                  <div className=' flex flex-col gap-0 align-middle justify-center items-center'>
                    <span className=' text-black'>
                      {moment(taskSchedule.date).add(5, 'days').format('dddd')}
                    </span>
                    <span className=' text-primary text-xl'>
                      {moment(taskSchedule.date).add(5, 'days').format('DD')}
                    </span>
                  </div>
                </a>
              </li>
              {/* <li>
                <span>
                  {moment(taskSchedule.date).add(6, 'days').format('ddd DD')}
                </span>
              </li> */}

            </ul>
            <hr />

          </div>
          {/* /TASKS DETAILS */}
          <div className='py-4  h-[100vh] overflow-auto '>
            <div className='container flex items-center justify-between w-100'>
              <div className='row'>
                {isPresentDay && overDueTasks?.length > 0 && <section className='flex flex-col w-60'>
                  <div className='mr-3' >
                    <div className=' flex gap-1 align-middle items-center'>
                      <span className=' text-lg font-medium text-red-600'>
                        Overdue
                      </span>
                      <span className="badge__danger">
                        {/* {oveData.length > 0 ? oveData?.filter((filData) => moment(filData.date).format('YYYY-MM-DD') === oveData.dateStr).length : 0} */}
                        {overDueTasks?.length ? overDueTasks?.length : 0}
                      </span>

                    </div>

                    <div className='day-section-tasks pt-3 max-h-96 overflow-hidden hover:overflow-auto'>
                     
                        {overDueTasks && overDueTasks?.length > 0 ? (
                          overDueTasks?.map((dueTask, i) => (
                            <>
                              {EditId != dueTask.id && (
                                <UpComeTaskBar
                                  key={dueTask.id}
                                  task={dueTask}
                                  tasks={overDueTasks}
                                  // setTasks={setTasks}
                                  setEditId={setEditId}
                                />
                              )}
                              {EditId == dueTask.id && (
                                <UpcomeTaskEditor
                                  key={i}
                                  task={dueTask}
                                  tasks={overDueTasks}
                                  // setTasks={setTasks}
                                  setEditId={setEditId}
                                />
                              )}
                            </>
                          ))
                        ) : (
                          <>{false && <TaskbarSkeleton count={10} />}</>
                        )}
                    </div>
                  </div>
                </section>}
                {datesArray.map((data) => {
                  let tasksCount = upComingTasks.length > 0 ? upComingTasks?.filter((filData) => moment(filData.date).format('YYYY-MM-DD') === data.dateStr).length : 0
                  return (
                    <section id={data.dateStr} className='flex flex-col w-60'>
                      <div className='mr-3' key={data.id}>
                        <div className=' flex gap-1 align-middle items-center'>
                          <span className=' text-lg font-medium text-black'>
                            {moment(data.dateStr).format('DD MMM . dddd')}
                          </span>
                          <span className={`badge${tasksCount === 0 ? '__success' : '__danger'}`}>
                            {tasksCount}
                          </span>
                        </div>

                        <div className='day-section-tasks pt-3 max-h-96 overflow-hidden hover:overflow-auto'>
                          <div
                            className="flex flex-col gap-1"
                          >
                            {upComingTasks && upComingTasks?.length > 0 ? (
                              upComingTasks?.filter((filt) => moment(filt.date).format('YYYY-MM-DD') === data.dateStr)?.map((task, i) => (
                                <>
                                  {EditId != task.id && (
                                    <UpComeTaskBar
                                      key={task.id}
                                      task={task}
                                      tasks={upComingTasks}
                                      // setTasks={setTasks}
                                      setEditId={setEditId}
                                    />
                                  )}
                                  {EditId == task.id && (
                                    <UpcomeTaskEditor
                                      key={i}
                                      task={task}
                                      tasks={upComingTasks}
                                      // setTasks={setTasks}
                                      setEditId={setEditId}
                                    />
                                  )}
                                </>
                              ))
                            ) : (
                              <>{false && <TaskbarSkeleton count={10} />}</>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='bg-white add-task-section'>
                        {showTaskInput && check === data.dateStr ? <UpComeAddTaskForm formAbort={() => setShowTaskInput(false)} isQuick={false} taskDate={data.dateStr} />
                          :
                          <>
                            {AddModalButton(data.dateStr)}
                          </>}
                      </div>
                    </section>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default Upcoming;