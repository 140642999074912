import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AiOutlineQuestionCircle, AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt, BiDotsHorizontalRounded as MenuIcon } from "react-icons/bi";
import { MdOutlineDoNotDisturbOn, MdNextWeek, } from "react-icons/md";
import { LiaCouchSolid, LiaSun } from "react-icons/lia";
import { BsFlagFill, BsFlag, BsList, BsArrowRightCircle } from "react-icons/bs";
import { BsCalendar2Event } from 'react-icons/bs';

import "./MoreTaskOptions.scss";
import { TodayIcon } from "DateHelpers/today-icon";
import { DuplicateTaskApi, updatTaskForUser, updateOnlyTaskDate } from "ApiService";
import { enqueueSnackbar } from "notistack";
import ConfirmCancelModal from "Models/ConfirmCancelModal/ConfirmCancelModal";
import moment from "moment";
import { AddTask, UpdateTask } from "store/TodayTasksDataSlice";
import { extractNumberFromString } from "utils/utils";
import { IoAlarmOutline, IoCopyOutline, IoExtensionPuzzleOutline, IoLinkOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { usePopper } from "react-popper";
import QuickActionForCompleteTask from "ActionItems/QuickActionForCompleteTask/QuickActionForCompleteTask";
import { Tooltip } from 'react-tooltip'
import { handleAppLoading } from "store/LeftMenuSlice";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const MoreTaskOptions = (props) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const { taskInfo } = props;
  const dispatch = useDispatch();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "left",
  });
  const popperStyles = {
    ...styles.popper,
    // display: isShown ? "block" : "none"
  };
  const [deleteModal, setDeleteModal] = useState(false);

  //--------------------------_API CALL FOR SCHEDULE-----------------------//

  async function handleQuickSchedule(dateObj) {
   dispatch(handleAppLoading())
    let datePay = {
      taskId: taskInfo.id,
      newDate: dateObj.date,
    };
    const updateOnlyDateRes = await updateOnlyTaskDate(datePay).finally(() => {
      dispatch(handleAppLoading())
    });
    if (updateOnlyDateRes.data.status === 1) {
      enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "success" });
      //moving the update to store
      dispatch(UpdateTask(updateOnlyDateRes.data.data.task));
      setIsShown(false);
    } else {
      enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "error" });
    }
  }

  //--------------------------_API CALL FOR PRIORIT-----------------------//

  const handleQuickPrior = async (priorValue) => {
    dispatch(handleAppLoading())
    const editPayload = {
      title: taskInfo.title,
      description: taskInfo.description,
      date: taskInfo.date ? taskInfo.date : null,
      priority: `priority${priorValue}`,
      reminders: [],
      labels: taskInfo.labels,
      category: "",
      isFavorite: false,
      taskEditId: taskInfo?.id,
      projectId: taskInfo?.projectId
    };
    const updateTaskAPIResponse = await updatTaskForUser(editPayload).finally(() => {
      dispatch(handleAppLoading())
    });;
    if (updateTaskAPIResponse.data.status === 1) {
      enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: "success" });
      dispatch(UpdateTask(updateTaskAPIResponse.data.data));
      setIsShown(false);
    } else {
      enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: "error" });
    }
  };
  const handleDuplicateTask = async (e) => {
    dispatch(handleAppLoading())
    try {
      const DuplicateTaskApiResult = await DuplicateTaskApi(e).finally(() => {
        dispatch(handleAppLoading())
      });
      if (DuplicateTaskApiResult.data.status === 1) {
        /** move the return obj to store  */
        dispatch(AddTask(DuplicateTaskApiResult.data.data.task));
        setIsShown(false);
        enqueueSnackbarWithClose(DuplicateTaskApiResult.data.message, { variant: "success" });
      } else {
        enqueueSnackbarWithClose(DuplicateTaskApiResult.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message ||error?.message,{variant:'error'})
      console.log(error);
    }
  };

  const handleCopyLink = async (e) => {
    const taskLink = `https://taskpareto.com/app/task/${e}`;

    // Use navigator.clipboard.writeText to copy the text to the clipboard
    navigator.clipboard
      .writeText(taskLink)
      .then(() => {
        console.log("Link copied to clipboard!");
        setIsShown(false); // Close the popover after copying
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard:", error);
      });
  };

  const RenderComponent = () => {
    return (
      <BsCalendar2Event
        className="mr-1"
        fontSize={"21px"}
        // fill={"#5297ff"}
      />
    );
  };
  return (
    <>
      <button id={`moreQuickActionsFortask-${taskInfo.id}`} ref={setReferenceElement} onClick={(e) => setIsShown((x) => !x)} type="button" className="text-2xl dark:text-white/50  text-black/70 h-full md:opacity-0 group-hover:opacity-100 transition-all hover:bg-black/10">
        <MenuIcon />
      </button>

      {isShown && (
        <>
          <Tooltip id="my-tooltip" place="top" noArrow offset={0} />

          <div className="modal-overlay-popper" onClick={() => setIsShown(false)}>
            <div onClick={(e) => e.stopPropagation()} ref={setPopperElement} style={popperStyles} {...attributes.popper}>
              <div
                className="modal-popper taskMoreOptions rounded-md shadow-xl border"
                // style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
              >
                <ul className="more-task-options gap-0 flex-col">
                  {!taskInfo.completed && (
                    <>
                      <li className=" hover:bg-zinc-200 rounded">
                        <div className="flex items-center py-2">
                          <BiEditAlt className="text-slate-800 text-lg mr-3  " />
                          <Link to={`/app/task/${taskInfo?.id}`}>Edit Task</Link>
                        </div>
                      </li>
                      {/* <li>
                        <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                          <BsList className="text-lg mr-3  " />
                          Go to Project
                        </div>
                      </li>*/}  
                      <hr /> 
                      <li className=" pt-3">
                        <div className=" flex justify-between items-center">
                          <div className="">Due Date</div>
                          <div>
                            {/* <AiOutlineQuestionCircle className="" /> */}
                          </div>
                        </div>

                        <div className="scheduler_holder">
                          <div className="flex gap-1 p-1">
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Today" onClick={() => handleQuickSchedule({ day: "Today", date: moment().format("YYYY-MM-DD") })} className="display-action-icons">
                              <TodayIcon color={"#25b84c"} className="action-icon text-lg" />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Tomorrow" onClick={() => handleQuickSchedule({ day: "Tomorrow", date: moment().add(1, "day").format("YYYY-MM-DD") })} className="display-action-icons">
                              <LiaSun className="scheduler-icon" fill={"#ff9a14"} />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Weekend" onClick={() => handleQuickSchedule({ day: "Weekend", date: moment().day("Saturday").format("YYYY-MM-DD") })} className="display-action-icons">
                              <LiaCouchSolid className="scheduler-icon" fill={"#5297ff"} />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Next Week" onClick={() => handleQuickSchedule({ day: "Next week", date: moment().add(7, "days").format("YYYY-MM-DD") })} className=" display-action-icons">
                              <MdNextWeek className="scheduler-icon" fill={"#a970ff"} />
                            </button>
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="No Due" onClick={() => handleQuickSchedule({ day: null, date: null })} className="display-action-icons">
                              <MdOutlineDoNotDisturbOn className="scheduler-icon" color={"grey"} />
                            </button>
                            {/* <button title="More Actions" className="display-action-icons">
                              <MdMoreHoriz className="scheduler-icon" color={"grey"} />
                            </button> */}
                            {/* <button className="flex items-center"> */}
                            <button data-tooltip-id="my-tooltip" data-tooltip-content="Open Calender">
                              <QuickActionForCompleteTask modalType={"quickCalender"} getTaskDateData={handleQuickSchedule} rescheduleViewContent={<RenderComponent />} />
                            </button>
                            {/* </button> */}
                          </div>
                        </div>
                      </li>
                      <hr />
                      <li className=" pt-3">
                        <div className=" flex justify-between items-center">
                          <div className="mb-2">Priority</div>
                          <div>
                            {/* <AiOutlineQuestionCircle className="" /> */}
                          </div>
                        </div>
                        <div className="scheduler_holder">
                          <div className="flex">
                            <span data-tooltip-id="my-tooltip" data-tooltip-content="Priority 1" onClick={() => handleQuickPrior(1)} className={`flex items-center display-action-icons priorityListItem ${extractNumberFromString(taskInfo.priority) === 1 ? `${taskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlagFill className="mr-1 prior-1" />
                              </span>
                            </span>
                            <span data-tooltip-id="my-tooltip" data-tooltip-content="Priority 2" onClick={() => handleQuickPrior(2)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${extractNumberFromString(taskInfo.priority) === 2 ? `${taskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlagFill className="mr-1 prior-2" />
                              </span>
                            </span>
                            <span data-tooltip-id="my-tooltip" data-tooltip-content="Priority 3" onClick={() => handleQuickPrior(3)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${extractNumberFromString(taskInfo.priority) === 3 ? `${taskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlagFill className="mr-1 prior-3" />
                              </span>
                              {/* {selectedPriorValue === 3 && <span> <BsDot className='text-red-600' /></span>} */}

                              {/* ${selectedPriorValue === 3 ? "selectedPrior" : ""} */}
                            </span>
                            <span data-tooltip-id="my-tooltip" data-tooltip-content="Priority 4" onClick={() => handleQuickPrior(4)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${extractNumberFromString(taskInfo.priority) === 4 ? `${taskInfo.priority}_flag` : ""}`}>
                              <span>
                                <BsFlag className="mr-1 prior-4" />
                              </span>
                              {/* {selectedPriorValue === 4 && <span> <BsDot className='text-red-600' /></span>} */}
                            </span>
                          </div>
                        </div>
                      </li>
                      <hr />
                      {/* <li>
                        <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                          <IoAlarmOutline className="  text-lg mr-3  " /> Reminders
                        </div>
                      </li> 
                      <hr />
                      <li>
                        <div className="flex items-center text-slate-300 cursor-not-allowed py-2">
                          <BsArrowRightCircle className="  text-lg mr-3  " />
                          Move to Project
                        </div>
                      </li> */}
                      <li className=" hover:bg-zinc-200 rounded" onClick={(e) => handleDuplicateTask(taskInfo.id)}>
                        <button className="flex items-center py-2 text-slate-800">
                          <IoCopyOutline className="  text-lg mr-3  " /> Duplicate
                        </button>
                      </li>
                      {/* <li>
                        <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                          <IoLinkOutline className=" text-lg mr-3  " />
                          Copy link to task
                        </div>
                      </li> */}
                      <hr />
                      {/* <li>
                        <li>
                          <div className="flex items-center py-2 text-slate-300 cursor-not-allowed">
                            <IoExtensionPuzzleOutline className=" text-lg mr-3  " />
                            Add Extension
                          </div>
                        </li> 
                      </li>*/}
                      <hr /> 
                    </>
                  )}
                  <li className="hover:bg-zinc-200 rounded">
                    <div
                      className="flex justify-start items-center text-red-500 cursor-pointer p-1"
                      onClick={(e) => {
                        setIsShown(false);
                        setDeleteModal(true);
                      }}>
                      <div>
                        <AiOutlineDelete className="text-lg mr-3" />
                      </div>
                      Delete task
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
      {deleteModal && <ConfirmCancelModal isOpen={deleteModal} setDeleteModal={setDeleteModal} taskInfo={taskInfo} />}
    </>
  );
};

export default MoreTaskOptions;




















