import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
const WorkspaceProjects = ({ workspace, projectsOfWork }) => {
  return (
    <>
      {projectsOfWork && projectsOfWork.length > 0 ? (
        projectsOfWork.map((project) => (
          <ul key={project.id} className="px-4">
            <li className="flex items-center px-4 ">
              <Icon icon="ci:line-l" width="25" color={project ? project.colorCode || `#2c3F59` : "#2c3F59"} />

              <Link to={`workspace/${workspace.id}/project/${project.id}`} className="block rounded-lg text-sm font-medium text-gray-800 hover-bg-blue-300 hover-text-black">
                {project.name}
              </Link>
            </li>
          </ul>
        ))
      ) : (
        <div className="flex justify-center">No projects</div>
      )}
    </>
  );
};
export default WorkspaceProjects;

