import illustration from "assets/svg/illustrations_5.png";
import { Link } from "react-router-dom";
import "./main.scss";
import Footer from "./Footer";
import Feature from "./Features";
import About from "./About";
import Steps from "./Steps";
import ImageSlider from "./ImageSlider";
import CalltoAction from "./CalltoAction";
import Logo from "./Logo";

export const LandingPage = () => {
  return (
    <main className="landing-page">
      <header className="intro__header">
        <nav className="intro__nav">
          <div className="intro__nav--group">
            <div className="nav-logo">
              {/* <img src={Logo} className="logo__desktop" /> */}
              <Logo />
              {/* <img src={LogoMobile} className="logo__mobile" /> */}
            </div>
            {/* <div className="intro__nav--group__wrapper">
              <Link className="intro__nav--item intro__nav--link" to="/">
                Features
              </Link>

              <Link className="intro__nav--item intro__nav--link" to="/">
                Resources
              </Link>
              <Link className="intro__nav--item intro__nav--link" to="/">
                Pricing
              </Link>
            </div> */}
          </div>
          <div className="intro__nav--group">
            <div className="intro__nav--group__wrapper">
              <Link className="intro__nav--item intro__nav--link w-max" to="/signin">
                Log in
              </Link>
              <Link className="intro__nav--item intro__nav--link w-max" to="/signup">
                Sign up
              </Link>
            </div>
          </div>
        </nav>
      </header>

      <section className="section__intro">
        <div className="intro__hero">
          <h1 className="hero-text">
            Unleash the full potential, <br /> Balance work and life, Now.
          </h1>
          <h2 className="hero-text__sub">Boost your productivity, stay organized, and find your peace with Task Pareto, your ultimate task management and to-do list app.</h2>
          <Link to="/signup" className="intro__hero--cta">
            Start for free
          </Link>
        </div>
        <img loading="lazy" src={illustration} className="intro__hero--illustration-1" alt="" width={1256} />
      </section>
      <About />
      <Feature />
      <Steps />
      <ImageSlider />
      <CalltoAction />

      <Footer />
    </main>
  );
};










