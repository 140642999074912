import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "components/Settings/WorkSpace/WorkSpace.scss";
import { searchTaskApi } from "ApiService/index";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { CgDanger } from "react-icons/cg";
import LoadingSpinner from "components/Loading/LoadingSpinner";
import SearchTaskInfo from "./SearchTaskInfo";
import searchImage from 'assets/svg/searchForTasks.jpg'
import './SearchStyles.scss'

const MAX_SEARCH_LENGTH = 2
export default function GlobalSearch(props) {
  const Navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const open = props.open;
  const close = props.close;

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleOverlayClick = () => {
    close();
  };

  async function handleSearchQuery(e) {
    let searchStr = e.target.value
    setSearchQuery(searchStr)
    if (searchStr.length >= MAX_SEARCH_LENGTH) {
      setLoading(true)
      try {
        const searchResultApi = await searchTaskApi(searchStr)
        if (searchResultApi.data.data.length > 0 && searchResultApi.data.status === 1) {
          setSearchResult(searchResultApi.data.data)
          setLoading(false)
        } else {
          setSearchResult([])
          setLoading(false)
        }
      } catch (error) {
        console.log(error);
        setLoading(false)
      }
    } else {
      setSearchResult([])
      setLoading(false)
    }

  }

  return (
    <div className="AddWorkSpace">
      <AnimatePresence>
        {open && (
          <motion.div initial="hidden" animate="visible" exit="hidden"
            // variants={modalVariants}
            className="customOverlay modal-overlay" onClick={handleOverlayClick}>
            <div ref={modalRef} className="modal workModal rounded-md" onClick={handleModalClick} style={{ padding: '0' }}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}>
                <button className="hidden absolute top-2 right-2 p-1 rounded-full text-gray-600" onClick={close}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="min-h-[380px]">
                  <div className="border-b flex items-center align-middle gap-2 py-2">
                    <div className=" mx-2">
                      <BsSearch className=" text-xl" height={'28px'} width={'28px'} />
                    </div>
                    <div className=" flex flex-col">
                      <input autoFocus onChange={handleSearchQuery} className=" focus:outline-none text-lg" placeholder="Search for Task ?" />
                      {searchQuery.length > 0 && searchQuery.length < MAX_SEARCH_LENGTH ? (
                        <span className=" text-xs">Type Atleast {MAX_SEARCH_LENGTH} character to search for task</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <span className=" text-zinc-500 pl-2">Tasks</span> */}
                  <section className="search__result max-h-72">
                    {!loading && searchResult && searchResult.length > 0 && searchResult.map((SearchData) => {
                      return (
                        <SearchTaskInfo task={SearchData} closeSearch={handleOverlayClick} />
                      )
                    })}
                    {searchQuery.length >= MAX_SEARCH_LENGTH && searchResult.length === 0 &&
                      <div className="flex items-center pl-4">
                        <span>
                          <CgDanger className=" text-red-600" fontSize={'26px'} />
                        </span>
                        <span>
                          No result found for <strong>{searchQuery}</strong>
                        </span>
                      </div>
                    }
                  </section>
                  {loading && <LoadingSpinner />}
                  {searchQuery.length === 0 && !loading && <h3 className=" text-center">Start typing to  Search for Task...</h3>}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
