import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HiOutlinePlus as AddIcon } from "react-icons/hi";
import TaskBar from "components/TaskBar/TaskBar";
import AddTaskForm from "components/AddTaskForm/AddTaskForm";
import { useSelector, useDispatch } from "react-redux";
import { getAllTasksForProjectsAPI, getProjectDetailsAPI } from "ApiService";
import { ProjectDetails, setSelectedProjectId } from "store/UserProjectsSlice";
import { InsertTasks } from "store/TodayTasksDataSlice";
import ProjectOptions from "./ProjectOptions";
import NoTaskIco from "assets/NoTaskIco";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ImSpinner2 as SpinnerIcon } from "react-icons/im";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
import { useNavigate, useLocation } from "react-router-dom";
import TaskOptions from "Models/TaskOptions/TaskOptions";
import { handleShowCompletedTasks } from "store/LeftMenuSlice";
import CompletedTasks from "components/CompletedTasks/CompletedTasks";

const ProjectDetailView = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/");
  const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch();
  const params = useParams();

  const selectedProject = useSelector((state) => state.userProjects.selectedProject);

  const todayTasks = useSelector((state) => state.todayTask.todayTasks?.filter((projectTasks) => projectTasks.completed === false && projectTasks.projectId == selectedProject));

  const showCompletedTasks = useSelector((state) => state.left_menu_on.showCompletedTasks);

  const projectId = params.id;
  const workspaceId = params.workspacekey;
  const [showTaskInput, setShowTaskInput] = useState(false);
  const [EditId, setEditId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const GetProjectsTasks = async () => {
    try {
      const res = await getAllTasksForProjectsAPI(projectId);
      if (res.data.status === 1 && res.data.data.length > 0) {
        // dispatch(InsertTasks(res.data.data));
      } else {
        dispatch(InsertTasks([]));
        // enqueueSnackbarWithClose(res.data.message, { variant: "info" });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchInterval = setInterval(() => {
      GetProjectsTasks();
    }, 9000);
    return () => {
      clearInterval(fetchInterval);
    };
  }, [projectId]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        let projectDetails = await getProjectDetailsAPI(projectId).finally(() => {
          setIsLoading(false);
        });
        if (projectDetails && projectDetails.data && projectDetails?.data?.data) {
          dispatch(ProjectDetails(projectDetails.data.data));
        } else {
          enqueueSnackbarWithClose(projectDetails.data.message, { variant: "error" });
          navigate(`/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}`);
        }
      } catch (error) {
        enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: "error" });
        console.error("Error fetching project details:", error);
      }
    };
    fetchData();
    dispatch(setSelectedProjectId(projectId));
    GetProjectsTasks();
  }, [projectId]);

  const projectDetails = useSelector((state) => state.userProjects.projectDetails);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedTasks = [...todayTasks];
    const [movedTask] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, movedTask);

    dispatch(InsertTasks(reorderedTasks));
  };

  const handleCompletedTasksCallBack = (value) => {
    dispatch(handleShowCompletedTasks());
  };

  return (
    <>
      <>
        {isLoading && (
          <div className="w-full h-full flex items-center justify-center">
            <SpinnerIcon className="text-primary animate-spin text-4xl" />
          </div>
        )}
        <div className="md:py-8 md:px-10 px-3 w-full md:max-w-8xl lg:max-w-9xl xl:max-w-8xl mx-auto">
          {/* <Header /> */}

          <div className="flex flex-wrap items-center justify-between w-full gap-2 static top-0 left-0">
            <div className="flex items-center gap-2">
              <span className="block text-xl font-bold"># {projectDetails?.name}</span>
            </div>

            <div className="flex items-start gap-2  ">
              <TaskOptions showCompletedTasks={showCompletedTasks} setShowCompletedTasks={handleCompletedTasksCallBack} />
              <ProjectOptions workspaceId={workspaceId} projectId={projectId} projectData={projectDetails} />
            </div>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="task-list">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {todayTasks?.map((task, index) => (
                    <Draggable key={task?.id?.toString()} draggableId={task?.id?.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          // className='border p-2'
                        >
                          <TaskBar projectData={projectDetails} key={task.id} task={task} tasks={todayTasks} setEditId={setEditId} EditId={EditId} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {showTaskInput ? (
            <AddTaskForm formAbort={() => setShowTaskInput(false)} isQuick={false} projectData={projectDetails} />
          ) : (
            <>
              {!isLoading && (
                <button
                  type="button"
                  className="flex gap-2 py-2 my-2 items-center group w-full"
                  onClick={() => {
                    setShowTaskInput(true);
                  }}>
                  <span className="text-primary transition-all group-hover:rounded-full group-hover:bg-primary group-hover:text-white p-1" aria-hidden="true">
                    <AddIcon />
                  </span>
                  <span className="group-hover:text-primary dark:text-gray-400">Add task</span>
                </button>
              )}
            </>
          )}

          {!showCompletedTasks && todayTasks?.length <= 0 && !isLoading && (
            <>
              {!showTaskInput && (
                <div className="flex items-center flex-col gap-2">
                  <NoTaskIco />
                </div>
              )}
            </>
          )}
          {showCompletedTasks && <CompletedTasks />}
        </div>
      </>
    </>
  );
};
export default ProjectDetailView;





























