import React from "react";
import task from "../../assets/svg/Task Organization.svg";
import intuitive from "../../assets/svg/Intuitive Interface.svg";
import reminders from "../../assets/svg/Deadline Reminders.svg";
import work from "../../assets/svg/Collaborative Work.svg";

export default function Features() {
  return (
    <div className="xl:px-4 sm:py-8 xl:py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 xs:px-3 ">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-blue-500 uppercase rounded-full bg-teal-accent-400">Features</p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
              <defs>
                <pattern id="18302e52-9e2a-4c8e-9550-0cbb21b38e55" x="0" y="0" width=".135" height=".30">
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)" width="45" height="24" />
            </svg>
            <span className="relative">Super Charge</span>
          </span>{" "}
          Your Productivity with Task Pareto
        </h2>
        <p className="text-base text-gray-800 md:text-lg">Discover the powerful features that make Task Pareto your ultimate task management solution. From intuitive task input to seamless collaboration.</p>
      </div>
      <div className="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="  w-16 h-16 mb-8 m-auto">
              <img loading="lazy" src={task} alt="Task Organization" />
            </div>
            <h6 className="mb-2 font-semibold leading-5 text-center">Task Organization</h6>
            <p className="mb-3 text-sm text-gray-900 text-justify">Task Pareto helps you keep all your tasks in one place, making it easy to stay organized and prioritize what matters most.</p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="  w-16 h-16 mb-8 m-auto">
              <img loading="lazy" src={intuitive} alt="Intuitive Interface" />
            </div>
            <h6 className="mb-2 font-semibold leading-5 text-center">Intuitive Interface</h6>
            <p className="mb-3 text-sm text-gray-900 text-justify">Our user-friendly interface ensures that managing tasks is a breeze, whether you're a beginner or a seasoned task management pro.</p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className="m-auto  w-16 h-16 mb-8">
              <img loading="lazy" src={reminders} alt="Deadline Reminders" />
            </div>
            <h6 className="mb-2 font-semibold leading-5 text-center">Deadline Reminders</h6>
            <p className="mb-3 text-sm text-gray-900 text-justify">Never miss a deadline again. Task Pareto sends you reminders and notifications so you can stay on top of your tasks.</p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div className=" m-auto   w-16 h-16 mb-8">
              <img loading="lazy" src={work} alt="Collaborative Work" />
            </div>
            <h6 className="mb-2 font-semibold leading-5 text-center">Collaborative Work</h6>
            <p className="mb-3 text-sm text-gray-900 text-justify">Share tasks and projects with colleagues, friends, or family, making it easy to collaborate and delegate responsibilities.</p>
          </div>
          {/* <a href="/" aria-label="" className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
            Learn more
          </a> */}
        </div>
      </div>
    </div>
  );
}




