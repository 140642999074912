import React, { useState } from "react";
import api from "../../Services/api";
import EyeOpen from "assets/svg/eye-off.svg";
import EyeClosed from "assets/svg/eye-on.svg";
import { FeatherIcons } from "assets/svg/feather-icons";
import { Link } from "react-router-dom";
import GoogleButton from "./GoogleButton";
import Lottie from "lottie-react";
import Toast from "../../components/Toast/Toast";
import LogoSmall from "assets/svg/taskpareto.svg";
import selectImg from "../../assets/json/animation_ln5z5v6z.json";
import Loading from "components/Loading/Loading";
import VerifyEmail from "./VerifyEmail";
import { validationRegex } from "utils/Regex";
import { handleInitialSpaceForInput } from "utils/utils";
import { useForm } from "react-hook-form";
import registerImg from "assets/svg/register.svg";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
export const SignupForm = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    username: "",
    email: "",
    password: "",
    profileImage: null,
    type: 1,
  });
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [passwordHasError, setPasswordHasError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");

  const onChangeHandler = (event) => {
    event.preventDefault();
    setEmailIsValid(true);
    if (event.target.name === "password") {
      setPasswordHasError(false);
    }
    const name = event.target.name;
    let value = event.target.value;

    if (name === "profileImage") {
      value = event.target.files[0];
    }

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const onBlurEmailHandler = () => {
    if (!validateEmail(formState.email)) {
      setEmailIsValid(false);
    }
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    return passwordRegex.test(password);
  };

  const onBlurPasswordHandler = () => {
    if (!validatePassword(formState.password)) {
      setPasswordHasError(true);
    } else {
      setPasswordHasError(false);
    }
  };

  const handleSignupSubmit = async (event) => {
    const formData = new FormData();
    formData.append("username", event.userName);
    formData.append("email", event.email.toLowerCase());
    formData.append("password", event.password);

    if (formState.profileImage) {
      formData.append("profileImage", formState.profileImage);
    }

    formData.append("type", 1);
    setFormState({
      username: event.userName,
      email: event.email,
      password: event.password,
      profileImage: null,
      type: 1,
    });
    // if (!validateEmail(formState.email)) {
    //   setEmailIsValid(false);
    //   setErrorMessage("Invalid email address");
    //   return;
    // }
    // if (!validatePassword(formState.password)) {
    //   setPasswordHasError(true);
    //   return;
    // }
    setLoading(true);
    // for (const entry of formData.entries()) {
    //   console.log(entry);
    // }
    try {
      // for (const entry of formData.entries()) {
      //   console.log(entry);
      // }
      const response = await api.signUp(formData);

      if (response.status === 1) {
        setLoading(false);
        // enqueueSnackbarWithClose("Signup successful!",{variant:'success'});
        // setFormState({
        //   username: "",
        //   email: "",
        //   password: "",
        //   profileImage: null,
        //   type: 1,
        // });
        setToastType("success");
        enqueueSnackbarWithClose(response.message, { variant: "success" });
        setShowVerifyEmail(true); //make the verify otp show
      } else {
        setLoading(false);
        enqueueSnackbarWithClose(response.message, { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbarWithClose(error?.response?.data?.message||error.message, { variant: "error" });
    }
  };

  const closeToast = () => {
    setToastMessage("");
  };

  return (
    <>
      {loading && <Loading />}
      <section className="text-gray-800 body-font">
        <div className="container-fluid mx-auto">
          <div className="flex flex-wrap -mx-4 -mb-10 text-center">
            <div className="sm:w-full lg:w-1/2 position-fixed xs:hidden lg:block ">
              <div className="max-w-screen-xl m-auto">
                <div className="w-full relative select-none">
                  <div className="sideImg">
                    <img src={registerImg} alt="" />{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:w-full lg:w-1/2 fields">
              <div className="auth-page__logo">
                <Link to="/">
                  <img src={LogoSmall} width={"100px"} alt="dispaly small app logo" />
                </Link>
              </div>
              {!showVerifyEmail ? (
                <div className="signup">
                  <div className="signup__wrapper">
                    <div className="step_one">
                      <GoogleButton />
                      <div className="separator">
                        <div className="middle_separator">OR</div>
                      </div>
                      <form className="signup-form" onSubmit={handleSubmit(handleSignupSubmit)}>
                        <div className="field">
                          <label htmlFor="username" className="label">
                            What should we call you?{" "}
                          </label>
                          <input {...register("userName", { required: { value: true, message: "please enter Username" }, minLength: { value: 2, message: "Username should contain more than 2 characters" }, maxLength: { value: 26, message: "Username should not exceed more than 26 characters" } })} type="text" id="username" autoComplete="off" onKeyDown={(e) => handleInitialSpaceForInput(e)} />
                          <span className="error-message">
                            {errors?.userName?.message && <FeatherIcons id="alert-circle" width={20} height={20} fill="#db4c3f" stroke={"#fff"} strokeWidth={2} currentColor={"#fff"} />}
                            {errors?.userName?.message}
                          </span>
                        </div>
                        <div className="flex gap-4">
                          <div className="field flex-1">
                            <label htmlFor="email" className="label">
                              Email
                            </label>

                            <input {...register("email", { required: { value: true, message: "please enter email Id" }, pattern: { value: validationRegex.Email_valid_regex, message: "please enter valid email Id" } })} id="email" autoComplete="off" className={!emailIsValid ? "has-error" : ""} onBlur={onBlurEmailHandler} />
                            <span className="error-message">
                              {errors?.email?.message && <FeatherIcons id="alert-circle" width={20} height={20} fill="#db4c3f" stroke={"#fff"} strokeWidth={2} currentColor={"#fff"} />}
                              {errors?.email?.message}
                            </span>
                          </div>
                          <div className="field flex-1">
                            <label htmlFor="password" className="label">
                              Password
                            </label>

                            <div className="toggle_password">
                              <input {...register("password", { required: { value: true, message: "Please Enter Password" }, pattern: { value: validationRegex.password_valid_regex, message: "Password must be at least 8 characters long, contain at least one uppercase letter, and one symbol" } })} className={` form_field_control ${passwordHasError ? "has-error" : ""}`} type={showPassword ? "text" : "password"} name="password" id="password" onBlur={onBlurPasswordHandler} />
                              <span className="toggle" aria-checked role="checkbox" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <img src={EyeOpen} alt="Show Password" /> : <img src={EyeClosed} alt="Hide Password" />}
                              </span>
                            </div>
                            <span className="error-message">
                              {errors?.password?.message && <FeatherIcons id="alert-circle" width={20} height={20} fill="#db4c3f" stroke={"#fff"} strokeWidth={2} currentColor={"#fff"} />}
                              {errors?.password?.message}
                            </span>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 space-y-2">
                          <label className="text-sm font-bold mt-2 tracking-wide">Select your Profile Image</label>
                          <div className="flex items-center justify-center w-full">
                            <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-30 p-5 group text-center">
                              <div className="h-full w-full text-center flex flex-col items-center justify-center">
                                <div className="w-2/5 mx-auto xl:w-1/2 flex  justify-center">{formState.profileImage ? <img src={URL.createObjectURL(formState.profileImage)} alt="Profile Preview" className="h-36 object-center object-cover" /> : <Lottie animationData={selectImg} className="divsvg" />}</div>
                                <p className="pointer-none text-gray-800">
                                  {formState.profileImage ? (
                                    "File selected."
                                  ) : (
                                    <>
                                      <span className="text-sm">Drag and drop</span> files here <br /> or{" "}
                                      <label htmlFor="profileImage" className="text-blue-600 hover:underline cursor-pointer">
                                        select a file
                                      </label>{" "}
                                      from your computer
                                    </>
                                  )}
                                </p>
                              </div>

                              <input type="file" accept="image/*" name="profileImage" id="profileImage" className="hidden" onChange={onChangeHandler} />
                            </label>
                          </div>
                        </div>

                        <button className="auth-button submit-button mt-12" disabled={loading}>
                          Sign up
                        </button>
                        <hr />
                        <p>
                          Already signed up?{" "}
                          <Link className="cursor-pointer" to="/signin">
                            Go to login
                          </Link>
                        </p>
                        {toastMessage && <Toast message={toastMessage} type={toastType} onClose={closeToast} />}
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <VerifyEmail userData={formState} setShowVerifyEmail={setShowVerifyEmail} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};





























