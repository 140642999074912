import React, {  useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddNewLabelModal from "Models/AddNewLabelModal/AddNewLabelModal";
import Item from "./Items";
import { InsertLabels } from "store/LabelsDataSlice";
import NoTaskIco from "assets/NoTaskIco";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const LabelsComponent = () => {
  const dispatch = useDispatch();
  const [EditId, setEditId] = useState("");
  const labelItems = useSelector((state) => state.labels.labels);

  const onDragEnd = (result) => {
    if (!result.destination) return; // Task was not dropped in a valid location
    const reorderedTasks = [...labelItems];
    const [movedTask] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, movedTask);

    // setTasks(reorderedTasks);
    dispatch(InsertLabels(reorderedTasks));
  };
  return (
    <>
      <div className="md:py-8 md:px-10 px-3 w-full xl:max-w-5xl mx-auto">
        {/* <Header /> */}
        <div className="flex items-center justify-between w-full gap-1 static top-0 left-0">
          <div className="flex items-center gap-1">
            <span className="block text-xl font-bold">Labels</span>
          </div>
        </div>
        <section>
          <div className=" flex justify-between">
            <div></div>
            <div>
              <AddNewLabelModal />
            </div>
          </div>
          <hr />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="task-list">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {labelItems && labelItems?.length > 0 && labelItems.map((item, index) => (
                    <Draggable key={item?.id?.toString()} draggableId={item?.id?.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        // className='border p-2'
                        >
                          <Item key={item.id} item={item} setEditId={setEditId} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

        </section>

        {[10]?.length <= 0 && !false && (
          <>
            {!true && (
              <div className="flex items-center flex-col gap-2">
                <NoTaskIco />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LabelsComponent;










