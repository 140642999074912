import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoAdd } from "react-icons/io5";
import "components/Settings/WorkSpace/WorkSpace.scss";
import { createProjectAPI, getColorsAPI } from "ApiService/index";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select"; // Import react-select
import { TfiPlus } from "react-icons/tfi";
import { addFavorite, updateProjectsInWorkspace } from "store/UserProjectsSlice";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
export default function AddProjects(props) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const colors = useSelector((state) => state.labels.colorsInfo);
  const [projectName, setProjectName] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [colorName, setColorName] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [projectNameError, setProjectNameError] = useState("");
  const [selectedView, setSelectedView] = useState(true);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await getColorsAPI();
  //       setColors(response.data.data);
  //     } catch (error) {
  //       console.error("Error fetching colors:", error);
  //     }
  //   }
  //   fetchData();
  // }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setProjectName("");
    setProjectNameError("");
    setColorCode("");
    setIsFavorite(false);
  };

  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
      },
    },
  };
  const handleListSelect = () => {
    setSelectedView(true);
  };
  const handleBoardSelect = () => {
    setSelectedView(false);
  };
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const handleOverlayClick = () => {
    closeModal();
  };

  const workspaces = useSelector((state) => state.userProjects.workspaces);
  const handleAddProject = async () => {
    try {
      if (!projectName) {
        setProjectNameError("Project name is mandatory.");
        return;
      } else {
        setProjectNameError("");
      }
      const payload = {
        name: projectName,
        colorCode: colorCode,
        colorName: colorName,
        workspaceId: props.id,
        isFavorite: isFavorite,
        view: selectedView,
      };
      setLoading(true);
      const response = await createProjectAPI(payload).finally(() => {
        setLoading(false);
      });
      if (response.data.status === 1) {
        const newProject = {
          id: response.data.data.id,
          name: projectName,
          colorCode: colorCode,
          colorName: colorName,
          isFavorite: isFavorite,
          view: selectedView,
        };
        const updatedWorkspace = { ...workspaces?.find((workspace) => workspace?.id === props.id) };

        if (updatedWorkspace) {
          const updatedProjects = [...updatedWorkspace.projects, newProject];
          updatedWorkspace.projects = updatedProjects;
        }
        dispatch(
          updateProjectsInWorkspace({
            workspaceId: props.id,
            projects: updatedWorkspace.projects,
          })
        );

        if (newProject.isFavorite) {
          dispatch(addFavorite(newProject));
        }
        enqueueSnackbarWithClose(response.data.message, { variant: "success" });
        closeModal();
      } else {
        enqueueSnackbarWithClose(response.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message ||error?.message,{variant:'error'})
      console.error("An error occurred while creating the project:", error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setColorCode(selectedOption.colorCode);
      setColorName(selectedOption.colorName);
    }
  };
  return (
    <div className="AddWorkSpace">
      <div>
        <button onClick={openModal} type="button" className="w-full flex items-center justify-end ">
          <div className="p-1.5 flex">
            <TfiPlus className="text-gray-800 text-lg mr-1" /> {props.label ? props.label : ""}
          </div>
        </button>
      </div>
      <AnimatePresence>
        {isModalOpen && (
          <motion.div initial="hidden" animate="visible" exit="hidden" variants={modalVariants} className="modal-overlay" onClick={handleOverlayClick}>
            <div ref={modalRef} className="modal  rounded-md" onClick={handleModalClick}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}>
                <button className="absolute top-2 right-2 p-1 rounded-full text-gray-800" onClick={closeModal}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="flex">
                  <div className="w-full  p-2 ">
                    <h2 className="text-xl font-semibold">Add Project</h2>
                    <div className="mt-4">
                      <label>Name</label>
                      <input
                      autoFocus
                        type="text"
                        placeholder="Project Name"
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
                        value={projectName}
                        onChange={(e) => {
                          setProjectName(e.target.value);
                          setProjectNameError("");
                        }}
                      />
                      {projectNameError && <div className="text-red-500">{projectNameError}</div>}
                    </div>
                    <div className="mt-4">
                      <label>Color</label>
                      <Select
                        getOptionValue={(option) => option.colorCode}
                        getOptionLabel={(option) => (
                          <div className="flex items-center">
                            <span className="color-circle" style={{ backgroundColor: option.colorCode }}></span>
                            <span>{option.colorName}</span>
                          </div>
                        )}
                        options={colors}
                        onChange={handleSelectChange}
                      />
                    </div>
                    <div className="mt-4">
                      <label className="favorite-toggle">
                        <input type="checkbox" checked={isFavorite} onChange={() => setIsFavorite(!isFavorite)} />
                        <span className="slider round"></span>
                      </label>{" "}
                      <span className="ml-4">Add to Favorite</span>
                    </div>
                    {/* <div className="mt-4 bg-slate-100 p-2 rounded">
                      <input id="toggle-on" className="toggle toggle-left" name="toggle" value="false" type="radio" checked={selectedView === true} onChange={handleListSelect} />
                      <label htmlFor="toggle-on" className="btn">
                        <span className="flex flex-col items-center text-md font-light text-gray-800">
                          {" "}
                          <MdOutlineViewList className="text-2xl text-gray-800 font-thin" /> List
                        </span>
                      </label>
                      <input id="toggle-off" className="toggle toggle-right" name="toggle" value="true" type="radio" checked={selectedView === false} onChange={handleBoardSelect} />
                      <label htmlFor="toggle-off" className="btn">
                        <span className="flex flex-col items-center text-md  text-gray-800 font-light">
                          <MdOutlineViewKanban className="text-2xl  text-gray-800" /> Board
                        </span>
                      </label>
                    </div> */}
                    <button onClick={handleAddProject} disabled={loading} type="button" className="bg-blue-500 font-bold text-white py-2 w-full flex items-center justify-center mt-4">
                      <IoAdd />
                      Add Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}













































