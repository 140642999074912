import React, { useState, useRef, useEffect } from "react";
import { ImSpinner8 as SpinnerIcon } from "react-icons/im";
import TaskDatePicker from "ActionItems/TaskDatePicker/TaskDatePicker";
import PriorityPicker from "ActionItems/PriorityPicker/PriorityPicker";
import { createTaskAPI } from "ApiService";
import { AddTask } from "store/TodayTasksDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import moment from "moment";
import * as chrono from "chrono-node";
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
import SelectProjectForTask from "ActionItems/SelectProjectForTask/SelectProjectForTask";
import { Editor, EditorState, CompositeDecorator, getDefaultKeyBinding } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { checkForTaskPriority } from "utils/utils";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
// const dateTerms = ["today", "tomorrow", "daily", "next monday", "next week"];

const MAX_LENGTH = 200; // Adjust the character limit as needed

const AddTaskForm = ({ formAbort, isQuick, projectData }) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  //**this state holds the key word to highlet in the editoe */
  const [extractedDateTerm, setExtractedDateTerm] = useState("");
  const editorRef = useRef(null);
  //** this is the editor draft state initially empty */
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const location = useLocation();
  const dispatch = useDispatch();
  const userProjects = useSelector((state) => state.userProjects.workspaces);
  const [formLocation, setFormLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [taskData, setTaskData] = useState({
    task: "",
    taskDescription: "",
  });
  const [taskSchedule, setTaskSchedule] = useState({ day: "", date: null });
  const [isDatePromotReq, setIsDatePromotReq] = useState(true);
  const [reccurring, setReccuring] = useState({ is_recurring: false, string: "everyday" });
  const [taskPriority, setTaskPriority] = useState(4);
  const [taskProjectInfo, setTaskProjectInfo] = useState(projectData ? projectData : "");
  // Create a decorator to highlight specific words
  const keywordDecorator = new CompositeDecorator([
    {
      strategy: findKeywordStrategy(extractedDateTerm),
      component: KeywordSpan,
    },
  ]);
  //** functions for keyword and hightltter START */
  useEffect(() => {
    // setEditorState(EditorState.createEmpty(keywordDecorator));
    const contentState = editorState.getCurrentContent();
    const newEditorState = EditorState.createWithContent(contentState, keywordDecorator);
    const currentSelection = editorState.getSelection();
    const stateWithContentAndSelection = EditorState.forceSelection(newEditorState, currentSelection);
    setEditorState(stateWithContentAndSelection);
  }, [extractedDateTerm]);

  const handleEditorChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    setTaskData({ ...taskData, task: plainText }); //updating this to state variable
    setEditorState(newEditorState);
    if (isDatePromotReq) {
      let parsedDate = dateparse(plainText);
      let reqDate = getDayAndDateObjFromISO(parsedDate);
      if (parsedDate) {
        setTaskSchedule(reqDate);
      } else {
        checkIsTaskingAddingForToday();
      }
    }
    if (plainText) {
      const priorityOfTask = checkForTaskPriority(plainText);
      setTaskPriority(priorityOfTask);
    }
  };
  const handleKeyCommand = (command) => {
    if (command === "submit-form") {
      // Implement the logic to submit the form here
      AddTaskToClient();
      return "handled";
    }
    return "not-handled";
  };

  const customKeyBinding = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      return "submit-form";
    }
    return getDefaultKeyBinding(e);
  };
  //** functions for keyword and hightltter END */

  //** if editor value is reuired call this function */
  const getEditorValue = () => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText(); // Get the plain text from ContentState
    return plainText;
  };

  const AddTaskToClient = async (e) => {
    // e.preventDefault();
    if (taskData.task && taskData.task.trim().length > 0) {
      setTaskData({
        task: "",
        taskDescription: "",
      });
      setFormLoading(true);
      setIsLoading(true);
      let payload = {
        title: taskData.task,
        description: taskData.taskDescription,
        date: taskSchedule.date ? taskSchedule.date : null,
        priority: `priority${taskPriority}`,
        projectId: taskProjectInfo.id ? taskProjectInfo.id : "",
        reminders: [],
        labels: [],
        category: "",
        isFavorite: false,
        due: {
          date: taskSchedule.date ? taskSchedule.date : null,
          is_recurring: reccurring.is_recurring,
          string: reccurring.string,
        },
      };
      try {
        const responseDate = await createTaskAPI(payload).finally(() => {
          setFormLoading(false);
          setIsLoading(false);
        });
        if (responseDate.data.status === 1) {
          enqueueSnackbarWithClose(responseDate.data.message, { variant: "success" });

          dispatch(AddTask(responseDate.data.data));
          //----------clearing the input fields
          setTaskData({
            task: "",
            taskDescription: "",
          });
          setTaskSchedule({ day: "", date: null });
          setEditorState(EditorState.createEmpty()); //clear the editor when submit
          setTaskPriority(4);
          //** set the task schedule date as today as the route is in today  */
          if (formLocation === "today") {
            setTaskSchedule({ day: "Today", date: moment().format("YYYY-MM-DD") });
          }
          /**
           * close the modal if it is opened from quick modal
           */
          if (isQuick) {
            formAbort();
          }
        } else {
          enqueueSnackbarWithClose(responseDate.data.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: "error" });
      }

    } else {
      enqueueSnackbarWithClose('Task Title is required', { variant: 'error' })
    }
  };

  useEffect(() => {
    checkIsTaskingAddingForToday();
    // editorRef.current.focus()
  }, [location]);

  const checkIsTaskingAddingForToday = () => {
    const locationpathName = location.pathname.split("/")[2].toLowerCase();
    if (locationpathName === "today") {
      setTaskSchedule({ day: "Today", date: moment().format("YYYY-MM-DD") });
      setFormLocation(locationpathName);
    } else {
      setTaskSchedule({ day: "", date: null });
    }
  };
  const dateparse = (dateString) => {
    const currentDate = new Date();
    const parsedDate = chrono.parseDate(dateString, currentDate, { forwardDate: true });
    let res = chrono.parse(dateString)[0];
    if (res) {
      setExtractedDateTerm(res?.text);
    } else {
      setExtractedDateTerm("");
    }
    if (parsedDate) {
      const parsedDateTime = parsedDate.getTime(); // Get the timestamp of the parsed date
      const currentDateTime = currentDate.getTime(); // Get the timestamp of the current date
      if (parsedDateTime >= currentDateTime) {
        // Date is either current or in the future
        return parsedDate;
      } else {
        setExtractedDateTerm("");
        // Date is in the past
        return null; // Or handle it as needed
      }
    } else {
      setExtractedDateTerm("");
      return null; // No valid date parsed
    }
    // if (!parsedDate) return null;
    // return parsedDate;
  };

  //**Listen this on text field change */
  const handleTaskTitleChange = (e) => {
    let taskTitle = e.target.value;
    setTaskData({ ...taskData, task: e.target.value });

    let parsedDate = dateparse(taskTitle);
    let reqDate = getDayAndDateObjFromISO(parsedDate);

    if (parsedDate) {
      setTaskSchedule(reqDate);
    } else {
      checkIsTaskingAddingForToday();
    }
    if (taskTitle) {
      const priorityOfTask = checkForTaskPriority(taskTitle);
      setTaskPriority(priorityOfTask);
    }
    const recurringWords = matchRecurringWords(taskTitle);
  };
  //** custom function with recurring keyword */

  function matchRecurringWords(sentence) {
    // Define a regex pattern for recurring phrases
    const regexPattern = /(\bmonthly\b|\bevery\sweek\b|\bevery\s(?:sunday|monday|tuesday|wednesday|thursday|friday|saturday)\b)/gi;
    // Use the regex pattern to find all recurring phrases in the sentence
    const matches = sentence.match(regexPattern);
    return matches || []; // Return an array of matched recurring phrases or an empty array
  }
  const handleCallBackDueDate = (dateData) => {
    setTaskSchedule(dateData);
    setIsDatePromotReq(false);
  };
  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return "handled";
    }
  };

  const _handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };
  return (
    <div className="border-2 ">
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}>
        <form
          //  onSubmit={(event) => AddTaskToClient(event)}
          className="rounded-lg mt-1">
          <div className="flex flex-col gap-2 sm:p-1 lg:p-3">
            <Editor 
            readOnly={false} 
            editorState={editorState}
             onChange={handleEditorChange} 
            handleKeyCommand={handleKeyCommand}
             keyBindingFn={customKeyBinding} 
            placeholder="Type your text here..." 
            ref={editorRef} 
            handleBeforeInput={_handleBeforeInput} handlePastedText={_handlePastedText} 
            />
            {/* <input
              // type="text"
              ref={taskTitleRef}
              wrap="soft"
              className="font-bold outline-none disabled:cursor-not-allowed disabled:opacity-40 bg-transparent  "
              placeholder="Task Name"
              value={taskData.task}
              // disabled={formLoading}
              autoFocus
              onChange={(e) => handleTaskTitleChange(e)}
            /> */}
            <textarea
              placeholder="Description"
              className="text-xs outline-none h-auto  dark:border-dark-100  focus:rounded transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-40 bg-transparent text-black"
              // disabled={formLoading}
              value={taskData.taskDescription}
              onChange={({ target }) =>
                setTaskData({
                  ...taskData,
                  taskDescription: target.value,
                })
              }
            />
            <div className="flex flex-wrap w-full items-center">
              <TaskDatePicker getTaskDateData={handleCallBackDueDate} taskSchedule={taskSchedule} />
              <PriorityPicker setTaskPriority={setTaskPriority} taskPriority={taskPriority} />
              {/* <Example getTaskDateData={setTaskSchedule} taskSchedule={taskSchedule}/> */}
            </div>
          </div>
          <hr />
          <div className="flex flex-wrap w-full p-2 items-center justify-between gap-2">
            <div>
              <SelectProjectForTask setTaskProjectInfo={setTaskProjectInfo} taskProjectInfo={taskProjectInfo} userProjects={userProjects} projectData={projectData} />
            </div>
            <div>
              <button
                type="button"
                className="bg-gray-100 mr-2 dark:bg-dark-100 dark:text-white dark:hover:bg-white/20 py-2 px-4 text-sm font-bold text-black/70 rounded hover:bg-gray-200"
                onClick={() => {
                  formAbort();
                }}>
                Cancel
              </button>
              <button type="button" onClick={(event) => AddTaskToClient(event)} className="bg-primary p-2 text-sm font-bold text-white/90 dark:text-gray-300 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40" disabled={!taskData.task?.trim() || formLoading}>
                {isLoading ? (
                  <span className="flex items-center gap-2">
                    <SpinnerIcon className="animate-spin" />
                    <span>Loading...</span>
                  </span>
                ) : (
                  "Add task"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
// Define a function to find the keyword's strategy
function findKeywordStrategy(keyword) {
  return function (contentBlock, callback) {
    const text = contentBlock.getText();
    const startIndex = text.indexOf(keyword);
    if (startIndex !== -1) {
      callback(startIndex, startIndex + keyword.length);
    }
  };
}

// Custom span component for highlighting the keyword
const KeywordSpan = (props) => {
  return <span style={{ backgroundColor: "#d5d5d5", padding: "0 2px" }}>{props.children}</span>;
};


export default AddTaskForm;