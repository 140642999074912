import React, { useState } from "react";
import { TodayIcon } from "assets/today-icon";
import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import { ProjectTasksCounts } from "./project-tasks-count";
import { useLocation } from "react-router-dom";
 
 import GlobalSearch from "Models/GlobalSearch/GlobalSearch";
import moment from "moment";
 export const DefaultProjects = () => {
   const location = useLocation();

   const [handleSearchModal, setHandleSearchModal] = useState(false);

   const handleWorkSpaceOpen = () => {
     setHandleSearchModal(true);
   };
   const handleWorkSpaceClose = () => {
     setHandleSearchModal(false);
   };

   return (
     <div className="sidebar-menu">
       <div onClick={() => handleWorkSpaceOpen()} className="project-group">
         <div className="project-group__group">
           <div className="project-group__icon">
             <Icon icon="mingcute:search-3-line" color="#2c3f59" width="22" />
           </div>

           <div className="project-group__name">Search</div>
         </div>
       </div>
       {handleSearchModal && <GlobalSearch open={handleWorkSpaceOpen} close={handleWorkSpaceClose} />}
       <NavLink to={"/app/Inbox"} className={({ isActive }) => (isActive ? "active project-group" : "project-group")}>
         <div className="project-group__group">
           <div className={({ isActive }) => (isActive ? "active project-group__icon" : "project-group__icon")}>
             <Icon icon="akar-icons:inbox" width="22" />
           </div>

           <div className="project-group__name">Inbox</div>
         </div>

         <ProjectTasksCounts isActive={location.pathname === "/app/Inbox"} isDefaultGroup name={"Inbox"} />
       </NavLink>
       <NavLink to={"/app/Today"} className={({ isActive }) => (isActive ? "active project-group" : "project-group my-1")}>
         <div className="project-group__group">
           <div className={({ isActive }) => (isActive ? "active project-group__icon" : "project-group__icon")}>
             <TodayIcon isActive={location.pathname === "/app/Today"} />
           </div>

           <div className="project-group__name">Today</div>
         </div>

         <ProjectTasksCounts isActive={location.pathname === "/app/Today"} isDefaultGroup name={"Today"} />
       </NavLink>
       <NavLink to={`/app/Upcoming/${moment().format("YYYY-MM-DD")}`} className={({ isActive }) => (isActive ? "active project-group" : "project-group my-1 mx-1")}>
         <div className="project-group__group">
           <div className={({ isActive }) => (isActive ? "active project-group__icon" : "project-group__icon")}>
             <Icon icon="akar-icons:calendar" width="22" />
           </div>

           <div className="project-group__name">Upcoming</div>
         </div>
         {/* <ProjectTasksCounts isDefaultGroup name={"Upcoming"} /> */}
       </NavLink>
       <NavLink to={"/app/labels"} className={({ isActive }) => (isActive ? "active project-group" : "project-group my-1 mx-1")}>
         <div className="project-group__group">
           <div className={({ isActive }) => (isActive ? "active project-group__icon" : "project-group__icon")}>
             <Icon icon="material-symbols:label-important-outline-rounded" width="23" />
           </div>

           <div className="project-group__name">Labels</div>
         </div>
       </NavLink>
     </div>
   );
 };








