import { createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: true, isDarkMode: false,isAppLoading:false,showCompletedTasks:false };

const LeftMenuSlice = createSlice({
  name: "leftMenu",
  initialState,
  reducers: {
    menuAction: (state) => {
      state.isOpen = !state.isOpen;
    },
    handleAppModeAction: (state) => {
      state.isDarkMode = !state.isDarkMode;
    },
    handleAppLoading:(state)=>{
      state.isAppLoading = !state.isAppLoading;
    },
    handleShowCompletedTasks:(state)=>{
      state.showCompletedTasks = !state.showCompletedTasks;
    }
  },
});

export const { menuAction, handleAppModeAction,handleAppLoading,handleShowCompletedTasks } = LeftMenuSlice.actions;
export default LeftMenuSlice.reducer;

