import React, { useEffect, useState } from "react";
import { deleteWorkSpaceAPI, getAllWorkspaceUsersAPI, getFavoritesAPI, removeCollaboratorsAPI, updateWorkspaceAPI } from "ApiService/index";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
 import { deleteWorkspace, editWorkspaceName, updateFavoriteProjects } from "store/UserProjectsSlice";
 import SnackbarWithClose from "ActionItems/SnackbarWithClose/SnackbarWithClose";
 export default function WorkSpace(props) {
   const { enqueueSnackbarWithClose } = SnackbarWithClose();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const loggedUserData = useSelector((state) => state.AuthData.loginData.userDetails);
   console.log(loggedUserData.id, "currentId");
   const workSpace = useSelector((state) => state?.userProjects?.workspaces?.find((workspace) => workspace?.id === props?.workSpaceData?.id));
   const [workspaceName, setWorkspaceName] = useState(workSpace?.workspaceName ? workSpace.workspaceName : "");
   const [collaborators, setCollaborators] = useState();
   const [workspaceadmin, setWorkspaceadmin] = useState();
   const [isDirty, setIsDirty] = useState(false);
   useEffect(() => {
     getWorkspaceUsers();
   }, []);
   const getWorkspaceUsers = async () => {
     const res = await getAllWorkspaceUsersAPI(workSpace.id);
     console.log(res, "response");
     if (res.status === 200) {
       setCollaborators(res.data.data.collaborators_data);
       setWorkspaceadmin(res.data.data.admin_data);
     }
   };
   const handleDeleteWorkspace = () => {
     deleteWorkSpaceAPI(workSpace.id)
       .then((response) => {
         if (response.status === 200) {
           const favoritesData = getFavoritesAPI().then((response) => {
             if (response.status === 200) {
               dispatch(updateFavoriteProjects(response.data.data));
             }
           });
           enqueueSnackbar("Workspace deleted successfully", { variant: "success" });
           dispatch(deleteWorkspace(workSpace.id));
           setTimeout(() => {
             navigate(`/app/Today`);
           }, 500);
         } else {
           console.error("Error deleting workspace:", response);
           enqueueSnackbarWithClose("You do not have permission to perform this action", { variant: "error" });
         }
       })
       .catch((error) => {
         console.error("Error deleting workspace:", error);
         enqueueSnackbarWithClose("Error deleting workspace", { variant: "error" });
       });
   };

   const handleKeyDown = (e) => {
     if (e.key === "Enter") {
       e.preventDefault();
       handleUpdate();
     }
   };

   const handleUpdate = async () => {
     try {
       const payload = {
         workspaceName: workspaceName,
       };
       const updateWorkSpace = await updateWorkspaceAPI(workSpace.id, payload);
       const editPayload = {
         workspaceId: workSpace.id,
         newName: updateWorkSpace.data.data.workspaceName,
       };
       setIsDirty(false);
       enqueueSnackbarWithClose("Workspace updated successfully", { variant: "success" });
       dispatch(editWorkspaceName(editPayload));
     } catch (error) {
       console.error("Error updating workspace:", error);
       enqueueSnackbarWithClose("Error updating workspace", { variant: "error" });
     }
   };
   const handleNameChange = (e) => {
     setWorkspaceName(e.target.value);
     setIsDirty(true);
   };
   const handleCancel = () => {
     setWorkspaceName(workSpace.workspaceName || "");
     setIsDirty(false);
   };
   const handleRemoveCollaborator = async (id) => {
     try {
       const payload = {
         collaboratorIds: [id],
       };
       const removeCollaborator = await removeCollaboratorsAPI(workSpace.id, payload);
       enqueueSnackbarWithClose(removeCollaborator.data.message, { variant: "success" });
     } catch (error) {
       enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: "error" });
     }
   };
   return (
     <div>
       <h2 className="text-xl text-black font-semibold ">
         <span className="user-display-character px-4 py-2 text-xl text-white uppercase border rounded bg-blue-500 font-semibold  ">{workSpace?.workspaceName.charAt(0)}</span>&nbsp;
         {workSpace?.workspaceName}
       </h2>
       <hr />
       <form className="my-8" onKeyDown={handleKeyDown}>
         <div>
           <label htmlFor="workspacename" className="text-black font-bold label mt-2 ml-0 text-md">
             Name
           </label>
           {console.log(workspaceadmin, "worssss")}
           {loggedUserData.id === workspaceadmin?.userid ? <input className="border border-slate-400 rounded p-2" type="text" name="workspacename" id="workspacename" autoComplete="off" value={workspaceName} onChange={handleNameChange} /> : <input className="cursor-not-allowed border border-slate-200 bg-slate-50 rounded p-2" type="text" name="workspacename" id="workspacename" autoComplete="off" value={workspaceName} disabled />}
         </div>
         <span className="text-xs text-gray-800" id="Help">
           Keep it something simple your team will recognize.
         </span>
       </form>
       {collaborators?.length > 0 && (
         <div className="mt-2">
           <label htmlFor="email" className="label ml-0 font-bold text-black text-lg">
             Collaborators
           </label>
           <hr />
           <div className="pl-2 py-2 flex items-center justify-between text-gray-500 cursor-not-allowed">
             <div className="flex-col	">
               {workspaceadmin && workspaceadmin.username} <br />
               <span className="text-xs">({workspaceadmin.email})</span>
             </div>
             <div>Admin</div>
           </div>
           {collaborators?.map((x) => (
             <div key={x.userid} className="text-gray-500  pl-2 py-2 flex justify-between items-center">
               <div>
                 {x.username} <small>({x.email})</small>
               </div>
               {loggedUserData.id === workspaceadmin?.userid ? (
                 <button onClick={() => handleRemoveCollaborator(x.userid)} className="text-red-500 cursor-pointer">
                   Remove
                 </button>
               ) : (
                 <div className="text-gray-500 cursor-default">Member</div>
               )}
             </div>
           ))}
         </div>
       )}
       {loggedUserData.id === workspaceadmin?.userid && (
         <div className="mt-5 ">
           <h2 className="font-bold text-black text-lg">Danger zone</h2>
           <hr />
           {/* <div className="mt-4">
          <label className="ml-0 font-bold text-black text-sm">Leave Workspace</label>
          <p className="text-xs mb-4">Leaving the workspace means you'll no longer have access to it.</p>
          <button type="button" className="bg-red-500 text-white px-4 py-2 rounded" onClick={handleLeaveWorkspace}>
            Leave Workspace
          </button>
        </div> */}
           <div className="mt-4">
             <label className="ml-0 font-bold text-black text-sm">Delete Workspace</label>
             <p className="text-xs mb-4">Deleting the workspace will permanently remove it, including all projects and tasks.</p>
             <button type="button" className="bg-red-500 text-white px-4 py-2 rounded" onClick={handleDeleteWorkspace}>
               Delete Workspace
             </button>
           </div>
         </div>
       )}
       <div className="flex justify-end">
         {isDirty && (
           <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={handleCancel}>
             Cancel
           </button>
         )}
         {isDirty && (
           <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={handleUpdate}>
             Update
           </button>
         )}
       </div>
     </div>
   );
 }





















