import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { CaptureAuthData } from "store/AuthDataSlice";
import { useSelector, useDispatch } from "react-redux";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
import { EditProfile } from "../../../ApiService/index";

export default function ChangePassword({ onBack }) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch();
  const userAuthData = useSelector((state) => state.AuthData);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleUpdate = async () => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      if (newPassword !== confirmPassword) {
        setPasswordMatchError("Passwords do not match");
        return;
      }

      if (!validatePassword(newPassword)) {
        setPasswordMatchError("Password should be atleast least 8 characters, 1 uppercase, 1 lowercase, 1 special character, and 1 number");

        return;
      }

      setPasswordMatchError("");

      const payload = {
        password: newPassword,
      };
      const UpdateProfileRes = await EditProfile(payload).finally(() => {
        onBack()
      });

      let loginData = {
        refreshToken: userAuthData.loginData.refreshToken,
        accessToken: userAuthData.loginData.accessToken,
        userDetails: {
          ...userAuthData.loginData.userDetails,
          password: payload.password,
        },
      };
      enqueueSnackbarWithClose(UpdateProfileRes.data.message);

      dispatch(CaptureAuthData(["password", loginData, true]));
    } catch (error) {
      console.error("Error updating user password:", error);
      enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: "success" });

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-3">
        <button className="flex items-center text-xl text-black font-semibold " onClick={onBack}>
          <BiArrowBack className="mr-3" />
          Change Password
        </button>
      </div>
      <div>
        <hr />

        <form className="mt-4">
          <div className="mb-5">
            <label htmlFor="newPassword" className="text-black font-bold label ml-0 text-md">
              New Password
            </label>
            <div className="relative">
              <input className="border border-slate-400 rounded p-2 w-[50%] pr-10" type={showNewPassword ? "text" : "password"} name="newPassword" id="newPassword" autoComplete="off" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
              <span className="absolute w-[50%] right-5 top-2 cursor-pointer" onClick={() => setShowNewPassword(!showNewPassword)}>
                {showNewPassword ? <BsEyeSlash /> : <BsEye />} {/* Toggle eye icon */}
              </span>
            </div>
          </div>
          <div>
            <label htmlFor="confirmPassword" className="text-black font-bold label ml-0 text-md">
              Confirm New Password
            </label>
            <div className="relative">
              <input
                className="border border-slate-400 rounded p-2 w-[50%] pr-10" // Add extra padding for the eye icon
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                autoComplete="off"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <span className="absolute w-[50%] right-5 top-2 cursor-pointer" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <BsEyeSlash /> : <BsEye />} {/* Toggle eye icon */}
              </span>
            </div>
            {passwordMatchError && <p className="text-red-500">{passwordMatchError}</p>}
          </div>
        </form>

        <button className={`bg-slate-100 px-2 py-1 submit-button mt-4 ${isLoading ? "disabled cursor-wait	" : ""}`} onClick={handleUpdate} disabled={isLoading}>
          {isLoading ? "Updating..." : "Update Password"}
        </button>
      </div>
    </div>
  );
}











