// import { useTasksCount } from "hooks";
import moment from "moment";
import { useSelector } from "react-redux";
export const ProjectTasksCounts = ({ name, isActive }) => {
  const tasksDataFromStore = useSelector((state) => state?.todayTask?.todayTasks?.filter((filData) => !filData?.completed));
  const todayTasksCount = tasksDataFromStore?.filter((tFil) => moment(tFil?.date)?.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"));
  const textColor = isActive ? "#fff" : "";
  const isRed = todayTasksCount?.length > 0 && name === "Today";

  return (
    <div className={`task-count ${isRed ? "task-count__red" : ""}`} style={{ color: textColor }}>
      {tasksDataFromStore?.length > 0 && name === "Today" ? todayTasksCount?.length : tasksDataFromStore?.length}
    </div>
  );










};