import React, { useEffect, useState } from "react";

import { usePopper } from "react-popper";
import { Tooltip } from "react-tooltip";
import { BsPersonFillAdd } from "react-icons/bs";
import { IoPersonAddOutline } from "react-icons/io5";
import { getAllWorkspaceUsersAPI, updatTaskForUser } from "ApiService";
import { useSelector, useDispatch } from "react-redux";
import { UpdateTask } from "store/TodayTasksDataSlice";
import { GoDotFill } from "react-icons/go";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
import { useNavigate,useLocation } from "react-router-dom";
function TaskAssigneeDropDown({ workspacekey, taskInfo }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathnames = pathname.split('/')
  const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch()
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [collaborators, setCollaborators] = useState();
  const assigneeDetailsById = collaborators?.find((item) => item.userid === taskInfo.task_assigned_by)

  const [duplicateCollaborators, setDuplicateCollaborators] = useState();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "top",
  });

  const loggedUserData = useSelector((state) => state.AuthData.loginData.userDetails)

  const data = useSelector((state) => state.userProjects.workspaces)

  const popperStyles = {
    ...styles.popper,
    // display: isShown ? "block" : "none"
  };
  /**
   * priority variable
   */
  // const workSpace = useSelector((state) => state.userProjects.workspaces.find((workspace) => workspace.id === parseInt(props.workSpaceData.id, 10)));

  useEffect(() => {
    getWorkspaceUsers();
  }, []);

  const getWorkspaceUsers = async () => {
    try {
      const res = await getAllWorkspaceUsersAPI(workspacekey);
      if (res.status === 200 && res.data.status == 1) {
        let collabUsers = res?.data?.data?.collaborators_data
        collabUsers.push(res.data.data.admin_data)
        setCollaborators(res.data.data.collaborators_data);
        setDuplicateCollaborators(res.data.data.collaborators_data);
      } else {
        setCollaborators([])
        setDuplicateCollaborators([]);
        enqueueSnackbarWithClose(res.data.message, { variant: "error" });
    
        //NAVIGATE TO WORKSPACE VIE IF PROJECT IS NOT FOUND
        navigate(`/${pathnames[1]}/${pathnames[2]}/${pathnames[3]}`)
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: "error" });
      console.log(error);
    }
  };


  const handleAssigneeClick = async (userid) => {
    const editPayload = {
      "assignedUserId": userid,
      "taskEditId": taskInfo?.id,
      projectId: taskInfo?.projectId
    }
    try {
      const updateTaskAPIResponse = await updatTaskForUser(editPayload).finally(() =>
        setIsShown(false)
      )
      if (updateTaskAPIResponse.data.status === 1) {
        enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: 'success' })
        dispatch(UpdateTask(updateTaskAPIResponse.data.data))
      } else {
        enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: 'error' })
    }

  }

  const [searchQuery, setSearchQuery] = useState("");
  const handleAssigneeSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      // Filter the projects based on the search query
      const filtered = collaborators.filter((item) => item.username.toLowerCase().includes(query.toLowerCase()))
      setCollaborators(filtered)
    } else {
      setCollaborators(duplicateCollaborators);
    }
  };
  return (
    <div>
      <Tooltip id="my-tooltip" place="top" noArrow offset={0} />
      <div data-tooltip-id="my-tooltip" data-tooltip-content={taskInfo?.task_assigned_by ? `Assigned to ${assigneeDetailsById?.username}` : `Assign a person`} ref={setReferenceElement} className={`cursor-pointer p-1 rounded-md flex items-center gap-1 text-zinc-500 w-fit`}>
        <div onClick={(e) => setIsShown((x) => !x)} className="flex items-center gap-1" >
          {taskInfo?.task_assigned_by ?
            <>{assigneeDetailsById?.profileImage ? <div className="projectWorkspace-assignee-pic"><img referrerPolicy="no-referrer" className="" alt={assigneeDetailsById?.name} src={assigneeDetailsById?.profileImage} /></div> : <span className="userpick__letter">{assigneeDetailsById?.username?.charAt(0)}</span>}
            </> : <BsPersonFillAdd className="text-gray-500 text-xl" />}
        </div>
      </div>

      {isShown && (
        <div className="modal-overlay-popper" onClick={() => setIsShown(false)}>
          <div
            ref={setPopperElement}
            style={popperStyles}
            {...attributes.popper}
          // onClick={(e)=>e.stopPropagation()}
          >
            <div
              className="modal-popper priorityModal rounded-md"
              onClick={(e) => e.stopPropagation(e)}
            // style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
            >
              <input className=" focus:outline-slate-400 border rounded-md p-1 text-sm w-webkit-fill-available" placeholder="Search for assignee" type="text"
                onChange={(e) => handleAssigneeSearch(e)}
              />
              <ul >
                <li onClick={() => handleAssigneeClick(null)} className={`flex gap-2 items-center p-1 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem className=" mt-2 mb-2"`}>
                  <span>
                    <IoPersonAddOutline className="text-gray-500 text-2xl" />
                  </span>
                  <span className=" text-[18px]">Unassigned</span>
                </li>
                <div className="workspace-projects mt-1 cursor-pointer hover:rounded-lg priorityListItem">
                  {collaborators?.length > 0 &&
                    collaborators.map((prD) => {
                      return (

                        <>

                          <div onClick={() => handleAssigneeClick(prD.userid)} key={prD.name} className="hover:bg-zinc-200 rounded workspace-details flex gap-1 align-middle items-center py-2 px-1">
                            {prD.profileImage ? <img referrerPolicy="no-referrer" className=" w-6 h-6 rounded-[50%]" alt={prD.name} src={prD.profileImage} /> : <span className="userpick__letter">{prD?.username?.charAt(0)}</span>}
                            {/* <span className="userpick__username">{prD?.username}</span> */}
                            {loggedUserData.id === prD.userid ? <span className="userpick__username">Me({prD?.username})</span> : <span className="userpick__username">{prD?.username}</span>}
                            {prD.userid === taskInfo.task_assigned_by && <span><GoDotFill fill={'#5297ff'} /></span>}
                          </div>
                        </>
                      );
                    })}
                </div>

              </ul>
              <hr />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskAssigneeDropDown;