import React from "react";
import { Link } from "react-router-dom";

export default function CalltoAction() {
  return (
    <div className="px-20 py-20 bg-teal-50 max-xs:p-0">
      <div className="grid gap-12 row-gap-6  text-center ">
        <h2 className=" font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          With TaskPareto, you can finally say goodbye to <br /> missed deadlines and overflowing to-do lists.
        </h2>
        <Link to="/signup" className="py-3 rounded bg-blue-500 text-white w-40 font-bold text-center m-auto">
          Start for free
        </Link>
      </div>
    </div>
  );
}



