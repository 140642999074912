import React, { useState } from "react";
import EyeOpen from "assets/svg/eye-off.svg";
import EyeClosed from "assets/svg/eye-on.svg";
import { FeatherIcons } from "assets/svg/feather-icons";
import { Link } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import login from "assets/svg/AdminLogin.jpg";
import LogoSmall from "assets/svg/taskpareto.svg";
import { useForm } from "react-hook-form"
import { validationRegex } from "utils/Regex";
import { AdminCaptureAuthData } from "store/AdminAuthDetails";
import { axiosInstance } from "Services/api";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
export const AdminLogin = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  /**
   * type 1 is regular login
   * type 2 is social login
   */
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [formState, setFormState] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleFormSubmit = async (event) => {
    setAuthenticating(true);
    const { email, password } = event;

    setFormState({
      email: email,
      password: password
    });
    setLoading(true);

    try {
      const response = await axiosInstance.post('/adminlogin',{ email, password});
      if (response.data.status === 1) {
        enqueueSnackbarWithClose(response?.data?.message, { variant: "success" });
        setLoading(false);

        let loginData = { userDetails: response.data.data };

        dispatch(AdminCaptureAuthData([loginData.userDetails, true]));

        navigate("/admin/dashboard");
      } else {
        enqueueSnackbarWithClose(response?.data?.message, { variant: "error" });
        setLoading(false);
      }

      setAuthenticating(false);
    } catch (error) {
      if (error?.response?.data.status === 0 && error?.response?.data?.type === 0) {
        setShowVerifyEmail(true);
      }
      enqueueSnackbarWithClose(error?.response?.data?.message, { variant: "error" });
      console.error("Login Error:", error);
      setAuthenticating(false);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <main className="auth-page">
        <div className="auth-page__container">
          {/* <img src={LogoSmall} /> */}

          <div className="auth-page__frame">
            <div className="auth-page__wrapper">
              <div className="auth-page__content">
                <section className="text-gray-800 body-font">
                  <div className="container-fluid mx-auto ">
                    <div className="flex flex-wrap -mx-4 -mb-10 text-center">
                      <div className="sm:w-1/2 border-r xs:hidden lg:block">
                        <div className="max-w-screen-xl m-auto">
                          <div className="w-full relative select-none">
                            <div className="sideImg">
                              <img src={login} style={{ height: "100vh", margin: "0 auto" }} alt="" />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:w-1/2">
                        <div className="error-block"></div>
                        <div className="auth-page__logo">
                          <Link to="/">
                            <img src={LogoSmall} width={"100px"} alt='appSmallLogo'/>
                          </Link>
                        </div>
                        {!showVerifyEmail && (
                          <div className="signup">
                            <div className="signup__wrapper">
                              <div className="step_one">
                                <h1>ADMIN LOGIN</h1>
                                <form className="login-form" onSubmit={handleSubmit(handleFormSubmit)}>
                                  <div className="field">
                                    <label htmlFor="email" className="label">
                                      Email
                                    </label>
                                    <input {...register("email", { required: { value: true, message: "please enter email Id" }, pattern: { value: validationRegex.Email_valid_regex, message: "please enter valid email Id" } })} placeholder="Enter Email" id="email" />

                                    <span className="error-message">
                                      {errors?.email?.message && <FeatherIcons id="alert-circle" width={20} height={20} fill="#db4c3f" stroke={"#fff"} strokeWidth={2} currentColor={"#fff"} />}
                                      {errors?.email?.message}
                                    </span>
                                  </div>
                                  <div className="field">
                                    <label className="label" htmlFor="password">
                                      Password
                                    </label>
                                    <div className="toggle_password">
                                      <input {...register('password', { required: { value: true, message: 'please enter password' } })} className="form_field_control" type={showPassword ? "text" : "password"} id="password" placeholder="Enter password" />
                                      <span className="toggle" role="checkbox" tabIndex="0" aria-checked="false" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <img src={EyeOpen} alt="Show Password" /> : <img src={EyeClosed} alt="Hide Password" />}
                                      </span>
                                    </div>
                                    <div className="error-message">
                                      {errors?.password?.message && <FeatherIcons id="alert-circle" width={20} height={20} fill="#db4c3f" stroke={"#fff"} strokeWidth={2} currentColor={"#fff"} />}
                                      {errors?.password?.message}
                                    </div>
                                  </div>
                                  <button className="auth-button submit-button" disabled={authenticating} type="submit">
                                    Log in
                                    {authenticating && <Loading />}
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );

};