import React, { useEffect, useState } from "react";
import { useParams, } from "react-router-dom";
import { Icon } from "@iconify/react";
import AddProjects from "Models/Projects/AddProjects";
import Settings from "Models/Settings/Settings";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProjectOptions from "./Projects/ProjectOptions";
import InviteMembers from "Models/WorkSpace/InviteMembers";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const WorkSpacesView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const { id } = useParams();
  const [openInviteMembers, setOpenInviteMembers] = useState(false);
  const workSpaceData = useSelector((state) => state?.userProjects?.workspaces.find((workspace) => workspace?.id === id));
  const [settingPopup, setSettingPopup] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [popoverIndex, setPopoverIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (!workSpaceData) {
      navigate("/app/today");
      enqueueSnackbarWithClose("Workspace not found", { variant: "error" });
    }
  }, []);

  useEffect(() => {
    function handleDocumentClick(event) {
      if (!event.target.closest(".popover")) {
        closePopover();
      }
    }
    if (popoverIndex !== null) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [popoverIndex]);
  const closePopover = () => {
    setPopoverIndex(null);
  };

  const filteredProjects = workSpaceData?.projects?.filter((project) => project.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const sortedProjects =
    filteredProjects &&
    [...filteredProjects].sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (sortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

  const openSettings = () => {
    setSettingPopup(true);
  };
  const closeSettings = () => {
    setSettingPopup(false);
  };
  const handleInviteMembersOpen = () => {
    setOpenInviteMembers(true);
  };
  const handleInviteMembersClose = () => {
    setOpenInviteMembers(false);
  };
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };
  return (
    <>
      <div layout className="md:py-8 md:px-10 px-3 w-full xl:max-w-5xl mx-auto">
        <div className="flex flex-wrap items-center justify-between w-full gap-2">
          <div className="block text-lg font-bold">{workSpaceData?.workspaceName}</div>
          <div className="flex flex-wrap items-center">
            <span className="text-sm font-bold flex mr-3 text-gray-800 bg-gray-100 p-2">
              <AddProjects label="Add Project" id={id} />
            </span>
            <span className="text-sm font-bold flex m-3 text-gray-800 cursor-pointer" onClick={handleInviteMembersOpen}>
              <Icon icon="akar-icons:people-group" width="20" className="mr-1  text-gray-800" />
              Invite Members
            </span>
            <span className="text-sm font-bold flex  m-3 text-gray-800 cursor-pointer" onClick={openSettings}>
              <Icon icon="mi:settings" width="20" className="mr-1 text-gray-800" />
              Settings
            </span>
          </div>
        </div>
        <div className="relative w-full mt-4">
          <div className="relative">
            <Icon icon="lucide:search" width="20" className="absolute top-2 left-3 text-gray-800" />
            <input type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-full pl-10 pr-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200" />
          </div>
        </div>
        <div className="my-4">
          <div className="flex flex-wrap items-center justify-between my-6">
            <div className="text-md font-bold">Projects ({sortedProjects ? sortedProjects.length : "0"})</div>
            <div className="md:mt-3 lg:ml-4">
              <label htmlFor="sortSelect" className="text-sm font-bold text-gray-800">
                Sort:
              </label>
              <select id="sortSelect" onChange={handleSortOrderChange} value={sortOrder} className="ml-2 border border-gray-300 rounded py-1 px-2 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200">
                <option value="asc">A-Z</option>
                <option value="desc">Z-A</option>
              </select>
            </div>
          </div>
          <hr />
          {sortedProjects?.map((favorites, index) => (
            <ul key={favorites.id} className="cursor-pointer dark:bg-gray-700">
              <li className="rounded mt-3 relative group hover:bg-white active-bg-blue-200">
                <div className="flex items-center justify-between w-full mt-5">
                  <Link to={`/app/workspace/${workSpaceData.id}/project/${favorites.id}`} className="block rounded-lg px-4 text-sm font-medium text-gray-800 hover-bg-blue-300 hover-text-black">
                    {favorites.name}
                  </Link>
                  <ProjectOptions workspaceId={workSpaceData.id} projectId={favorites.id} projectData={favorites} />
                </div>
              </li>
            </ul>
          ))}
        </div>
      </div>
      {openInviteMembers && <InviteMembers open={handleInviteMembersOpen} close={handleInviteMembersClose} workspaceId={workSpaceData?.id} />}
      {settingPopup && <Settings open={openSettings} close={closeSettings} activeTab="WorkSpace" workSpaceData={workSpaceData} />}
    </>
  );
};
export default WorkSpacesView;




























