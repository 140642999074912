import { createSlice } from "@reduxjs/toolkit";

const initialState = { labels: [],colorsInfo:[] }

const TodayLabelsDataSlice = createSlice({
    name: 'labels',
    initialState,
    reducers: {
        AddLabel: (state, action) => {
            state.labels.push(action.payload)
        },
        InsertLabels: (state, action) => {
            state.labels = action.payload
        },
        UpdateLabel: (state, action) => {
            const findObj = state.labels.find(data => data.id === action.payload.id)
            if (findObj) {
                const index = state.labels.findIndex(dataa => dataa.id === action.payload.id);
                if (index !== -1) {
                    state.labels[index] = { ...state.labels[index], ...action.payload };
                }
              
            }
        },
        DeleteLabel: (state, action) => {
            state.labels = state.labels.filter((delData) => delData.id !== Number(action.payload))
        },
        InsertColorsToStore: (state, action) => {
            state.colorsInfo = action.payload
        },
    }
})

export const { AddLabel, InsertLabels, UpdateLabel, DeleteLabel,InsertColorsToStore } = TodayLabelsDataSlice.actions

export default TodayLabelsDataSlice.reducer