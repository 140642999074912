import React, { useState } from "react";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { AiFillTag, AiFillDelete } from 'react-icons/ai'
import AddNewLabelModal from "Models/AddNewLabelModal/AddNewLabelModal";
import { DeleteLabel } from "store/LabelsDataSlice";
import { deleteLabel } from "ApiService";
import { useDispatch } from "react-redux";
import { AiOutlineInfoCircle, AiOutlineClose } from 'react-icons/ai';
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
function Item({ item, setEditId }) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch()
  const [editDetails, setEditDetails] = useState('')
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  async function deletelabel(e) {
    try {
      const response = await deleteLabel(editDetails.id)
      if (response.data.status === 1) {
        enqueueSnackbarWithClose(response.data.message, { variant: 'success' })
      } else {
        enqueueSnackbarWithClose(response.data.message, { variant: 'error' })
      }
    } catch (error) {
      console.log(error);
    }
    //console.log(response.data,'data')
    dispatch(DeleteLabel(editDetails.id))
    setEditDetails('')
    setShowConfirmModal(false)
  }
  return (
    <div
      className="py-2 border-b w-full flex items-center gap-2 group bg-white dark:bg-dark-500 dark:border-dark-100"
    >
      <>
        <button className="text-xl text-black/60 dark:text-white/50 md:opacity-0 group-hover:opacity-100 transition-all cursor-grab active:cursor-grabbing" >
          <DragIcon />
        </button>
        <div className="flex items-center gap-1 flex-grow w-full">
          <div className={`task__details flex gap-2 items-center`}>
            <span className=" text-lg " style={{ color: `${item?.colorCode}` }}><AiFillTag /></span>
            <p className="task__text">
              {item?.labelName}
            </p>
          </div>
        </div>
        <div className="flex flex-col h-full">
          <div className="flex">
            <button className="mr-3 md:opacity-0 group-hover:opacity-100 transition-all" onClick={() => setEditId(item.id)}>

              <AddNewLabelModal id={item.id} item={item} type="edit" />
            </button>
            <div>

            </div>
            <button className="mr-3 md:opacity-0 group-hover:opacity-100 transition-all" id={`b-${item.id}`} onClick={(e) => { setShowConfirmModal(true); setEditId(item.id); setEditDetails(item) }}>
              <AiFillDelete className="text-xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" />

            </button>

          </div>
        </div>
        {showConfirmModal &&
          <div className="h-screen w-screen fixed top-0 left-0 z-50 pt-10 sm:p-10">
            <div
              className=" h-full w-full absolute top-0 left-0 bg-black/50 z-10 cursor-pointer"
            />
            <div
              className="modal-overlay-popper"
              onClick={(e) => setShowConfirmModal(false)}
            >
              <div className="modal-popper quickAddTaskModal rounded-md"
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <div className='confirmCancel-modal'>
                    <header className='p-1 flex justify-between'>
                      <div>
                        <button className='display-action-icons'>
                          {/* <AiOutlineInfoCircle /> */}
                        </button>
                      </div>
                      <div>
                        <button onClick={(e) => setShowConfirmModal(false)} className='display-action-icons'>
                          <AiOutlineClose />
                        </button>
                      </div>
                    </header>
                    <div className=' py-8 px-1'>
                      <span className='text-red-500 text-left text-lg'>
                        Are you sure want to delete
                      </span>
                      <span className="  pl-1 text-base">{editDetails?.labelName}</span>
                    </div>
                    <footer>
                      <div className='flex justify-end gap-2'>
                        <button onClick={(e) => setShowConfirmModal(false)} className='cancel-button'>
                          cancel
                        </button>
                        <button onClick={(e) => deletelabel()} className='confirm-danger-button'>
                          Delete
                        </button>
                      </div>
                    </footer>
                    <div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>}
      </>
    </div>
    // 


  );
}

export default Item;