import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";

export const TodayIcon = ({ isActive }) => {
  const location = useLocation();
  const isTodayPage = location.pathname === "/app/today";

  const [activeState, setActiveState] = useState(isTodayPage);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveState(isTodayPage);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isTodayPage]);

  let date = moment()?.date()?.toString();

  return (
    <div className={activeState || isActive ? "active-date-icon" : "date-icon"}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
        <g fill="none" stroke={activeState || isActive ? "#fff" : "#2c3f59"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <rect width="20" height="18" x="2" y="4" rx="4" />
          <path d="M8 2v4m8-4v4M2 10h20" />
        </g>
        <text fontFamily="poppins" fontSize="8" fill={activeState || isActive ? "#fff" : "#2c3f59"} transform="translate(4 2)" fontWeight="600">
          <tspan x="8" y="17" textAnchor="middle">
            {("0" + date).slice(-2)}
          </tspan>
        </text>
      </svg>
    </div>
  );
};


