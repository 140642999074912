import React from "react";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { menuAction } from "store/LeftMenuSlice";
import {CgArrowRightR} from 'react-icons/cg'
export default function ToggleMenu() {
  const dispatch = useDispatch();

  const handleParetoSideBar = () => {
    dispatch(menuAction());
  };

  return (
    <>
      <div onClick={() => handleParetoSideBar()} className="text-2xl sm:px-6 sm:px-6py-10 cursor-pointer ">
        <Icon icon="majesticons:menu-expand-right" color="#579DFF" width="30" />{" "}
      </div>
    </>
  );
}















