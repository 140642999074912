import { Link, useLocation } from "react-router-dom";
import { TaskDate } from "DateHelpers/task-date";
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";

const AdminSubTasksInfo = ({ subTask }) => {
    //**this state holds the key word to highlet in the editoe */

    const { pathname } = useLocation();
    return (
        <div className="py-1 border-b w-full flex items-center gap-2 group bg-white ">
            <>
                <div className="flex items-center gap-2 flex-grow w-full">
                    {subTask?.completed || subTask?.completed ? <FaRegCheckCircle className={`mr-1 ${subTask?.priority}`} /> : <FaRegCircle className={`mr-1 ${subTask?.priority} task_complete_checkbox`} />}
                    <div className={`task__details ${subTask.completed ? "Task-completed" : "task-inprogress"}`}>
                        {pathname.toLowerCase() === "/app/today" || pathname.toLowerCase() === "/app/inbox" ? (
                            <p className="task__text">
                                <Link to={`/app/task/${subTask?.taskId}`} className="flex-grow">
                                    {subTask?.title}
                                </Link>
                            </p>
                        ) : (
                            <p className="task__text">{subTask?.title}</p>
                        )}
                        {/* Show the description if only if the task was nopt ocmpleted */}
                        {!subTask?.completed && <p className=" text-gray-400 text-xs">{subTask?.description}</p>}
                        <div className="task__info mt-2">
                            <div>{subTask?.dueDate && <TaskDate date={subTask?.dueDate} />} </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default AdminSubTasksInfo;
