import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoAdd } from "react-icons/io5";
import "components/Settings/WorkSpace/WorkSpace.scss";
import { createWorkSpaceAPI } from "ApiService/index";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import workspace from "assets/svg/team workspace.svg";
import { addNewWorkspace } from "store/UserProjectsSlice";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
export default function AddWorkSpace(props) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const Navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const [workspaceName, setWorkspaceName] = useState("");
  const [inviteMembers, setInviteMembers] = useState("");
  const [workspaceNameError, setWorkspaceNameError] = useState("");
  const [inviteMembersError, setInviteMembersError] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const dispatch = useDispatch();
  const open = props.open;
  const close = props.close;
  const openModal = () => {
    open();
  };
  const closeModal = () => {
    close();
    setWorkspaceName("");
    setInviteMembers("");
    setWorkspaceNameError("");
    setInviteMembersError("");
  };
  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
      },
    },
  };
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const handleOverlayClick = () => {
    // close();
  };
  const handleAddWorkspace = async () => {
    setFormLoading(true);
    try {
      if (!workspaceName) {
        setWorkspaceNameError("Workspace name is mandatory.");
        return;
      } else {
        setWorkspaceNameError("");
      }
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}(?:,[\s]*[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})*$/;
      if (inviteMembers && !emailPattern.test(inviteMembers)) {
        setInviteMembersError("Invalid email format in invite members.");
        return;
      } else {
        setInviteMembersError("");
      }
      const payload = {
        workspaceName: workspaceName,
        inviteMembers: inviteMembers,
      };

      const response = await createWorkSpaceAPI(payload).finally(() => {
        setFormLoading(false);
      });
      if (response.data.status === 1) {
        const workspaceId = response.data.data.id;
        enqueueSnackbarWithClose(response.data.message, { variant: "success" });
        setTimeout(() => {
          dispatch(
            addNewWorkspace({
              id: workspaceId,
              workspaceName: workspaceName,
              inviteMembers: inviteMembers,
            })
          );

          // dispatch(addWorkspace({ id: workspaceId, workspaceName, inviteMembers }));
        }, 500);
        closeModal();
        if (props.onWorkspaceAdded) {
          props.onWorkspaceAdded();
        }
      } else {
        enqueueSnackbarWithClose(response.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: "error" });
      console.error("An error occurred while creating the workspace:", error);
    }
  };
  return (
    <div className="AddWorkSpace">
      <AnimatePresence>
        {open && (
          <motion.div initial="hidden" animate="visible" exit="hidden" variants={modalVariants} className="customOverlay modal-overlay" onClick={handleOverlayClick}>
            <div ref={modalRef} className="modal workModal rounded-md" onClick={handleModalClick}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}>
                <button className="absolute top-2 right-2 p-1 rounded-full text-gray-800" onClick={close}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="lg:flex md:block m-auto">
                  <div className="md:w-full lg:w-6/12 p-4 bg-blue-50">
                    <img src={workspace} alt="" className="w-3/4 m-auto object-cover my-6" />
                  </div>
                  <div className="md:w-full lg:w-6/12  p-2 ">
                    <h2 className="text-xl font-semibold mb-4">Add a team workspace</h2>
                    <p className="leading-6 mb-5 ">The home for your team’s work, Collaborate with your team in one shared place and Filter your tasks by workspace.</p>
                    <label>Workspace name</label>
                    <input
                      type="text"
                      placeholder="The name of your team or company"
                      className="w-full px-3 py-2 mt-2 border rounded focus:outline-none focus:border-blue-500"
                      value={workspaceName}
                      onChange={(e) => {
                        setWorkspaceName(e.target.value);
                        setWorkspaceNameError("");
                      }}
                    />
                    <span className="text-xs text-gray-800" id="Help">
                      Keep it something simple your team will recognize.
                    </span>
                    {workspaceNameError && <div className="text-red-500 text-xs">{workspaceNameError}</div>}
                    {/* <div className="mt-4">
                      <label>Invite members (comma-separated emails)</label>
                      <textarea
                        type="text"
                        placeholder="Enter email addresses separated by commas"
                        className="w-full px-3 py-2 mt-2 border rounded focus:outline-none focus:border-blue-500"
                        value={inviteMembers}
                        onChange={(e) => {
                          setInviteMembers(e.target.value);
                          setInviteMembersError("");
                        }}
                      />
                      {inviteMembersError && <div className="text-red-500 text-sm">{inviteMembersError}</div>}
                    </div> */}
                    <button disabled={formLoading} onClick={handleAddWorkspace} type="button" className="bg-blue-500 font-bold text-white py-2 w-full flex items-center justify-center mt-5">
                      <IoAdd />
                     {formLoading?"Loading...":"Add Workspace"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}


























