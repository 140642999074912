import React from "react";
 import simple from "assets/svg/simple.svg";
 import task from "assets/svg/task management.svg";
 import organize from "assets/svg/organise and delegate.svg";
 import customize from "assets/svg/customize.svg";

 export default function Steps() {
   return (
     <>
       <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-8 lg:py-20">
         <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
           <h2 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
             <span className="relative inline-block">
               <svg viewBox="0 0 52 24" fill="currentColor" className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                 <defs>
                   <pattern id="3071a3ad-db4a-4cbe-ac9a-47850b69e4b7" x="0" y="0" width=".135" height=".30">
                     <circle cx="1" cy="1" r=".7" />
                   </pattern>
                 </defs>
                 <rect fill="url(#3071a3ad-db4a-4cbe-ac9a-47850b69e4b7)" width="45" height="24" />
               </svg>
             </span>{" "}
             Simplicity or Complexity, Your Choice. Tailor Your Task Management Experience to Your Needs with Ease
           </h2>
         </div>

         <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
           <div className="grid gap-8 lg:grid-cols-12 sm:mx-auto">
             <div className="lg:col-span-6 relative flex items-center justify-center w-full dark:text-gray-50">
               {" "}
               <div className="flex flex-col   justify-center">
                 <div className="flex">
                   <div className="mr-4">
                     <div className="flex items-center justify-center w-10 h-10 mb-3   ">
                       <img loading="lazy" src={simple} alt="sample " />
                     </div>
                   </div>
                   <div>
                     <h6 className="mb-2 font-semibold leading-5">Simple</h6>
                     <ul className="space-y-3">
                       <li className="flex">
                         <span className="mr-1">
                           <svg className="w-5 h-5 mt-px text-amber-500" stroke="currentColor" viewBox="0 0 52 52">
                             <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                           </svg>
                         </span>
                         <span>
                           {" "}
                           <b>Add Task:</b>&nbsp;Easily add tasks to your list.
                         </span>
                       </li>
                       <li className="flex">
                         <span className="mr-1">
                           <svg className="w-5 h-5 mt-px text-amber-500" stroke="currentColor" viewBox="0 0 52 52">
                             <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                           </svg>
                         </span>
                         <span>
                           {" "}
                           <b> Complete It: </b>&nbsp;Check tasks off when they're done.
                         </span>
                       </li>
                     </ul>
                     <hr className="w-full my-6 border-gray-300" />
                   </div>
                 </div>
                 <div className="flex">
                   <div className="mr-4">
                     <div className="flex items-center justify-center w-10 h-10 mb-3  ">
                       <img loading="lazy" src={organize} alt="organize " />
                     </div>
                   </div>
                   <div>
                     <h6 className="mb-2 font-semibold leading-5">Organize & Delegate</h6>
                     <ul className="space-y-3">
                       <li className="flex">
                         <span className="mr-1">
                           <svg className="w-5 h-5 mt-px text-amber-500" stroke="currentColor" viewBox="0 0 52 52">
                             <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                           </svg>
                         </span>
                         <span>
                           {" "}
                           <b>Due Reminders:</b>&nbsp; Track your deadlines and Schedule reminders.
                         </span>
                       </li>
                       <li className="flex">
                         <span className="mr-1">
                           <svg className="w-5 h-5 mt-px text-amber-500" stroke="currentColor" viewBox="0 0 52 52">
                             <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                           </svg>
                         </span>
                         <span>
                           {" "}
                           <b>Organize Collaboratively: </b>&nbsp;Group your tasks with projects and share with your team.
                         </span>
                       </li>
                     </ul>
                     <hr className="w-full my-6 border-gray-300" />
                   </div>
                 </div>
                 <div className="flex">
                   <div className="mr-4">
                     <div className="flex items-center justify-center w-10 h-10 mb-3  ">
                       <img src={customize} alt="" />
                     </div>
                   </div>
                   <div>
                     <h6 className="mb-2 font-semibold leading-5">Customize (Advanced)</h6>
                     <ul className="space-y-3">
                       <li className="flex">
                         <span className="mr-1">
                           <svg className="w-5 h-5 mt-px text-amber-500" stroke="currentColor" viewBox="0 0 52 52">
                             <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                           </svg>
                         </span>
                         <span>
                           {" "}
                           <b>Extensions:</b>&nbsp; Enhance functionality with task management extensions.
                         </span>
                       </li>
                       <li className="flex">
                         <span className="mr-1">
                           <svg className="w-5 h-5 mt-px text-amber-500" stroke="currentColor" viewBox="0 0 52 52">
                             <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
                           </svg>
                         </span>
                         <span>
                           {" "}
                           <b>Filter Views & Prorities: </b>&nbsp;Customize views with filters and Assign priority levels for focused attention.
                         </span>
                       </li>
                     </ul>
                   </div>
                 </div>
               </div>
             </div>
             <div className="lg:col-span-6 flex flex-col justify-center">
               {" "}
               <div className="grid   gap-5">
                 <img src={task} alt="" />
               </div>
             </div>
           </div>
         </div>
       </div>
     </>
   );
 }


















