import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoAdd } from "react-icons/io5";
import "components/Settings/WorkSpace/WorkSpace.scss";
import { InviteMembersAPI } from "ApiService/index";
import { useDispatch } from "react-redux";
import workspace from "assets/svg/invitemembers.svg";
import { addMembersToWorkspace } from "store/UserProjectsSlice";
import { MdCancel } from "react-icons/md";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
import { validationRegex } from "utils/Regex";
export default function InviteMembers(props) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const modalRef = useRef(null);
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceNameError, setWorkspaceNameError] = useState("");
  const [emailArray, setEmailArray] = useState([])
  const [emailValue, setEmailValue] = useState('')
  const [emailError, setEmailError] = useState('')
  const dispatch = useDispatch();
  const open = props.open;
  const close = props.close;
  const [formLoading, setFormLoading] = useState(false);
  const openModal = () => {
    open();
  };
  const closeModal = () => {
    close();
    setWorkspaceName("");
    setWorkspaceNameError("");
  };
  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
      },
    },
  };
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const handleOverlayClick = () => {
    close();
  };

  const handleSubmitShare = async (e) => {
    e.preventDefault();
    if (emailArray.length > 0) {
      try {
        setFormLoading(true);

        const payload = {
          email: emailArray,
        };
        const response = await InviteMembersAPI(payload, props.workspaceId).finally(() => {
          setFormLoading(false);
        });
        if (response.data.status === 1) {
          dispatch(addMembersToWorkspace(props.workspaceId, payload));
          enqueueSnackbarWithClose(response.data.message, { variant: "success" });
          closeModal();
        } else {
          enqueueSnackbarWithClose(response.data.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbarWithClose(error?.response?.data?.message || error?.message, { variant: 'error' })
        console.error(error);
      }
    } else if (emailValue.length > 0) {
      isValid(emailValue)
    }
    else {
      setEmailError(`please Enter email address and press Enter.`)
    }
  };



  //Tab
  const handleKeyDownEmail = evt => {
    if (
      ["Enter", ",", " "].includes(evt.key) ||
      evt.keyCode === 13 ||
      // evt.keyCode === 9 ||
      evt.which === 13
      //|| evt.which === 9
    ) {
      evt.preventDefault();
      var value = evt.target.value.trim();
      if (value && isValid(value)) {
        setEmailArray([...emailArray, evt.target.value])
        setEmailValue('')
      }
    } else {
    }
  };

  function isValid(email) {
    let error = null;
    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (email === "") {
      error = `${email} please Enter email address and press Enter.`;
    }
    if (error) {
      setEmailError(error)
      return false;
    }
    return true;
  }

  function isInList(email) {
    return emailArray.includes(email);
  }

  function isEmail(email) {
    return validationRegex.Email_valid_regex.test(email);

  }

  const handleChangeEmail = evt => {
    setEmailValue(evt.target.value)
    setEmailError('')
  };


  const handlePasteEmail = evt => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(validationRegex.Email_valid_regex);
    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));
      setEmailArray([...emailArray, ...toBeAdded])
    }
  };

  const handleDeleteEmail = item => {
    setEmailArray(emailArray.filter(i => i !== item))
  };
  return (
    <div className="AddWorkSpace">
      <AnimatePresence>
        {open && (
          <motion.div initial="hidden" animate="visible" exit="hidden" variants={modalVariants} className="customOverlay modal-overlay" onClick={handleOverlayClick}>
            <div ref={modalRef} className="modal workModal rounded-md" onClick={handleModalClick}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}>
                <button className="absolute top-2 right-2 p-1 rounded-full text-gray-800" onClick={close}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="flex">
                  <div className="w-6/12 p-4 bg-blue-50">
                    <img src={workspace} alt="" className="w-3/4 m-auto object-cover my-6" />
                  </div>
                  <div className="w-6/12  p-2 ">
                    <h2 className="text-xl font-semibold mb-4">Invite team members</h2>
                    <p className="leading-6 mb-5 "> Collaborate with your team in one shared place and Filter your tasks by workspace.</p>

                    {workspaceNameError && <div className="text-red-500 text-xs">{workspaceNameError}</div>}
                    {/* <div className="mt-4">
                      <label className="font-semibold">Invite members</label>
                      <textarea
                        type="text"
                        placeholder="Enter email addresses"
                        className="w-full px-3 py-2 mt-2 border rounded focus:outline-none focus:border-blue-500"
                        value={inviteMembers}
                        onChange={(e) => {
                          setInviteMembers(e.target.value);
                          setInviteMembersError("");
                        }}
                      />
                      <span className="text-xs text-gray-800" id="Help">
                        Enter email addresses separated by commas{" "}
                      </span>
                      {inviteMembersError && <div className="text-red-500 text-sm">{inviteMembersError}</div>}
                    </div> */}

                    <div className=" flex justify-evenly flex-col">
                      {emailArray.map(item => (
                        <div className="email-tag-item border rounded w-auto flex items-center mb-2 p-1" key={item}>
                          <span>
                            {item}
                          </span>
                          <button sx={{ padding: '0 0 0 3px' }} onClick={() => handleDeleteEmail(item)}>
                            <MdCancel style={{ fontSize: '18px', color: 'red', marginLeft: '5px' }} />
                          </button>

                        </div>
                      ))}
                    </div>
                    <div>
                      <input
                        className="w-full px-3 py-4 mt-2 border rounded focus:outline-none focus:border-blue-500"
                        // {...register('emails', { required: !emailArray.length })}
                        variant='outlined'
                        fullWidth
                        placeholder="Enter email address"
                        value={emailValue}
                        onKeyDown={handleKeyDownEmail}
                        onChange={handleChangeEmail}
                        onPaste={handlePasteEmail}
                        onBlur={handleKeyDownEmail}
                      // multiline
                      />
                      <b className="fntInput">
                        Hit enter after each email address.
                      </b>

                      <br />
                      {emailError && <span className="error_messages text-red-600">
                        {emailError}
                      </span>}
                    </div>
                    <button onClick={(e) => handleSubmitShare(e)}
                      disabled={!emailArray.length || formLoading}
                      className="bg-blue-500 font-bold text-white py-2 w-full flex items-center justify-center mt-5">
                      <IoAdd />
                      {formLoading ? "Loading..." : "Invite Members"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}



