import React from "react";

//redux
import { DeleteSubTaskFromStore } from "store/TodayTasksDataSlice";

import { AiOutlineInfoCircle, AiOutlineClose } from 'react-icons/ai';

import { deleteSubTaskForTask } from "ApiService";
import { useDispatch } from "react-redux";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const ConfirmSubTaskDeleteModal = (props) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
    const dispatch = useDispatch();

    const { subTaskInfo, setDeleteModal, deleteCallbackAction } = props
    /**
      * handleDeleteTask 
      */
    const handleDeleteSubTask = async (e) => {
        const deleteTaskByIDApiRes = await deleteSubTaskForTask(subTaskInfo.taskId, subTaskInfo.id)
        if (deleteTaskByIDApiRes.data.status === 1) {
            enqueueSnackbarWithClose(deleteTaskByIDApiRes.data.message, { variant: 'success' })
            // dispatch(DeleteTaskStore(subTaskInfo?.id))
            //moving the update to store
            //----------------------------------SUB TASK Delete-------------------------//
            dispatch(DeleteSubTaskFromStore({ taskId: subTaskInfo.taskId, subTaskId: subTaskInfo.id }))

            /**
             * this method updated the local state to parent for complete task view actions
             */
            deleteCallbackAction(subTaskInfo.id)

            setDeleteModal(false)
        } else {
            enqueueSnackbarWithClose(deleteTaskByIDApiRes.data.message, { variant: 'error' })

        }
    }

    return (
        <div className="h-screen w-screen fixed top-0 left-0 z-50 pt-10 sm:p-10">
            <div
                className=" h-full w-full absolute top-0 left-0 bg-black/50 z-10 cursor-pointer"
            />
            <div
                className="modal-overlay-popper"
                onClick={(e) => setDeleteModal(false)}
            >
                <div className="modal-popper quickAddTaskModal rounded-md"
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <div className='confirmCancel-modal'>
                            <header className='p-1 flex justify-between'>
                                <div>
                                    <button className='display-action-icons'>
                                        {/* <AiOutlineInfoCircle /> */}
                                    </button>
                                </div>
                                <div>
                                    <button onClick={(e) => setDeleteModal(false)} className='display-action-icons'>
                                        <AiOutlineClose />
                                    </button>
                                </div>
                            </header>
                            <div className=' py-8 px-1'>
                                <span className='text-red-500 text-left text-lg'>
                                    Are you sure want to delete
                                </span>
                                <span className="  pl-1 text-base">{subTaskInfo?.title}</span>
                            </div>
                            <footer>
                                <div className='flex justify-end gap-2'>
                                    <button onClick={(e) => setDeleteModal(false)} className='cancel-button'>
                                        cancel
                                    </button>
                                    <button onClick={(e) => handleDeleteSubTask(e)} className='confirm-danger-button'>
                                        Delete
                                    </button>
                                </div>
                            </footer>
                            <div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ConfirmSubTaskDeleteModal;
