import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { menuAction } from "store/LeftMenuSlice";
import AdminAppLogout from "./AdminAppLogout";

const AdminSideBarHead = () => {
  const userAuthData = useSelector((state) => state.AdminAuthDetails);
  const dispatch = useDispatch();

  const [popups, setPopups] = useState({
    user: false,
    notification: false,
  });
  const handleParetoSideBar = () => {
    dispatch(menuAction());
  };


  return (
    <>
      <div className="App-header w-full py-1">
        <div className="flex-between">
          <div className="">
            <button
              type="button"
              className=""
              onClick={() => {
                setPopups({
                  user: !popups.user,
                  notification: false,
                });
              }}>
              <div className="flex items-center">
                {<span className="profileImage">{userAuthData?.loginData?.username?.charAt(0)}</span>}{" "}
                <span className="welcome">
                  <span className="text-xs">Welcome,</span>
                  <span className="font-semibold"> {userAuthData?.loginData?.username}</span>
                </span>
              </div>
            </button>
            {popups.user && (
              <div
                className="modal-overlay"
                onClick={() => {
                  setPopups({
                    user: !popups.user,
                    notification: false,
                  });
                }}>
                <ul onClick={(e) => e.stopPropagation(e)} className="menu-options">
                 
                  <AdminAppLogout />
                  <hr />
                  <li className="menu-item">
                    <span className="ml-2">V 1.0.0</span>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div className="relative text-black/80 ">
              {/* {popups.notification && <Notification />} */}
            </div>
            <button type="button" className="text-2xl rounded transition-all hover:bg-white/20 " onClick={() => handleParetoSideBar()}>
              <Icon icon="majesticons:menu-expand-left" color="#2c3f59" width="28" />{" "}
            </button>
          </div>
        </div>

      </div>
    </>
  );
};

export default AdminSideBarHead;
