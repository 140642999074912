import React from "react";
import { notifications } from "constants/notificatoins";
import NotificationItem from "../../layout/AppHeader/NotificationItem";
import { motion, Reorder } from "framer-motion";

import { BiPieChart as ChartIcon, BiListCheck as MarkAsReadIcon } from "react-icons/bi";
export default function Notification() {
  return (
    <>
      <motion.div className="md:py-8 md:px-10 px-3 w-full xl:max-w-5xl mx-auto">
        <div className="flex items-center justify-between w-full gap-2 static top-0 left-0">
          <div className="flex items-center gap-2">
            <span className="block text-xl font-bold">Today</span>
            <span className="block text-sm text-gray-800 dark:text-gray-400">
              {new Date().toLocaleDateString("en-US", {
                month: "short",
                weekday: "short",
                day: "numeric",
              })}
            </span>
          </div>
        </div>

        <div>
          <div className="  ">
            {/* <div className="flex w-full items-center justify-between py-3 px-4">
              <div className="bg-gray-100 p-1 rounded-full">
                <button className="px-3 py-1 font-bold bg-white rounded-full">Notification</button>
                <button className="px-3 py-1 font-bold bg-transparent text-black/50 rounded-full">Unread</button>
              </div>
              <button className="text-3xl aspect-square block p-2 rounded transition-all flex-shrink-0 text-black/50">
                <MarkAsReadIcon />
              </button>
            </div> */}
            {/* <div className="flex w-full flex-col divide-y  h-[300px] overflow-y-scroll">
              {notifications.map((notification, index) => (
                <NotificationItem key={index} notification={notification} />
              ))}
            </div> */}
            <div className="flex w-full flex-col divide-y mt-8  h-[300px] overflow-y-scroll">Coming Soon</div>
          </div>
        </div>
      </motion.div>
    </>
  );
}










