import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Settings.scss";
import Accounts from "components/Settings/Accounts/Accounts";
import General from "components/Settings/General/General";
import WorkSpace from "components/Settings/WorkSpace/WorkSpaceSettings";
import { getAllWorkspacesAPI } from "ApiService/index";
import { useSelector } from "react-redux";
const Settings = (props) => {
  const open = props.open;
  const close = props.close;
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const modalRef = useRef(null);
  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
      },
    },
  };
  const workSpaceData = useSelector((state) => {
    if (props.workSpaceData) {
      return state?.userProjects?.workspaces?.find((workspace) => workspace?.id === props.workSpaceData.id);
    } else {
      return state.userProjects.workspaces;
    }
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const workspacesResponse = await getAllWorkspacesAPI();
        if (workspacesResponse.status == 200) {
          // setWorkspaces(workspacesResponse.data.data);
        }
      } catch (error) {
        console.error("Error fetching workspaces:", error);
      }
    }
    fetchData();
  }, []);
  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const handleOverlayClick = () => {
    close();
  };
  const [activeTab, setActiveTab] = useState(props.activeTab ? props.activeTab : "Account");

  const handleWorkspaceClick = (workspace) => {
    setSelectedWorkspace((prevSelectedWorkspace) => {
      if (prevSelectedWorkspace && prevSelectedWorkspace.id === workspace.id) {
        return null;
      }
      return workspace;
    });

    setActiveTab("WorkSpace");
  };

  return (
    <>
      <AnimatePresence>
        {open && (
          <motion.div initial="hidden" animate="visible" exit="hidden" variants={modalVariants} className="customOverlay modal-overlay" onClick={handleOverlayClick}>
            <div ref={modalRef} className="modal  settingsModal rounded-md" onClick={handleModalClick}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}>
                <div className="p-5">
                  <button className="absolute top-2 right-2 p-1 rounded-full text-gray-800" onClick={close}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                  {activeTab === "Account" && <Accounts />}
                  {activeTab === "General" && <General />}
                  {activeTab === "WorkSpace" && <WorkSpace successAction={props.successAction} workSpaceData={selectedWorkspace || workSpaceData} />}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default Settings;
