import { getusersbyidAdminApi } from 'ApiService';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import './adminUserDetails.scss'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import AdminTaskInfo from '../AdminTaskInfo/AdminTaskInfo';

ChartJS.register(ArcElement, Tooltip, Legend);

const AdminUserDetails = () => {

  const { userId } = useParams();
  const [adminUserDetails, setAdminUserDetails] = useState()
  console.log(adminUserDetails, 'datadata');

  const getusersbyidAdminApiCall = async () => {
    const useData = await getusersbyidAdminApi(userId)
    // console.log(useData, 'useData');
    setAdminUserDetails(useData?.data?.data)
  }
  useEffect(() => {
    getusersbyidAdminApiCall()
  }, [userId])

  // CHARTS

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Set to false to allow manual adjustment of height
    height: 200, // Adjust the height according to your preference
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tasks based  on priority',
      },
    },
  };

  const chartsData = {
    labels: ['Priority 1', 'Priority 2', 'Priority 3', 'Priority 4'],
    datasets: [
      {
        label: '# of Tasks',
        data: adminUserDetails?.userDetails?.priorityCounts,
        backgroundColor: [
          '#ffc9c5',
          '#ffdbad',
          '#c5dcff',
          '#eee',
        ],
        borderColor: [
          '#d1453b',
          '#eb8909',
          '#246fe0',
          '#666',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="md:py-8 md:px-10 px-3 w-full md:max-w-8xl lg:max-w-9xl xl:max-w-8xl mx-auto">
        <div>

          <section className=' flex'>
            <div className=' w-1/2'>
              <div class="container">
                <span className=''>
                  {adminUserDetails?.userDetails?.profileImage ? <img referrerPolicy="no-referrer" className=" border rounded-[50%] w-15 h-15 object-cover admin-user-profileimage" src={adminUserDetails?.userDetails?.profileImage} alt={adminUserDetails?.userDetails?.username?.charAt(0)} /> : <span className="profileImage">{adminUserDetails?.userDetails?.username?.charAt(0)}</span>}{" "}
                </span>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xs-offset-0 col-sm-offset-0 col-md-offset-3 col-lg-offset-3 toppad">
                    <div class="panel panel-primary">
                      <div class="panel-body">
                        <div class="row">
                          <div class=" col-md-9 col-lg-9 ">
                            <table class="table table-user-information">
                              <tbody>
                                <tr>
                                  <td className='first-child'>UserName:</td>
                                  <td className='second-child'>{adminUserDetails?.userDetails?.username}</td>
                                </tr>
                                <tr>
                                  <td className='first-child'>E-Mail:</td>
                                  <td className='second-child'>{adminUserDetails?.userDetails?.email}</td>
                                </tr>
                                <tr>
                                  <td className='first-child'>Total Tasks created</td>
                                  <td className='second-child'>{adminUserDetails?.userDetails?.createdTasksCount}</td>
                                </tr>
                                <tr>
                                  <td className='first-child'>Total Tasks Completed</td>
                                  <td className='second-child'>{adminUserDetails?.userDetails?.completedTasksCount}</td>
                                </tr>
                                <tr>
                                  <td className='first-child'>Total Overdue Tasks</td>
                                  <td className={`second-child `} style={{ color: `${adminUserDetails?.userDetails?.overdueTasksCount > 0 ? "red" : ''}` }}>{adminUserDetails?.userDetails?.overdueTasksCount}</td>
                                </tr>
                                <tr>
                                  <td className='first-child'>Total workspaces created</td>
                                  <td className='second-child'>{adminUserDetails?.userDetails?.workspacesCount}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' w-1/2'>
              <Doughnut data={chartsData} options={options} />
            </div>
          </section>
          <hr />
          <section className=' flex gap-3 mt-3'>
            <div className=' w-1/2'>
              <div>
                {adminUserDetails?.userDetails?.tasks.map((taskData) => {
                  return (
                    <AdminTaskInfo adminTaskInfo={taskData} />
                  )
                })}
              </div>
            </div>
            <div className=' w-1/2'>
              
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default AdminUserDetails