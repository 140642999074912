import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import login from "assets/svg/forgotPassword.jpg";
import LogoSmall from "assets/svg/taskpareto.svg";
import "./main.scss";
import { useForm } from "react-hook-form"
import { validationRegex } from "utils/Regex";
import { requestPasswordResetApi } from "ApiService";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
export const ForgotPassword = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
    const navigate = useNavigate();
    const [authenticating, setAuthenticating] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    /**
     * type 1 is regular login
     * type 2 is social login
     */
    const handleFormSubmit = async (event) => {
        setLoading(true);

        try {
            const response = await requestPasswordResetApi(event.resetEmail);
            if (response.data.status === 1) {
                enqueueSnackbarWithClose(response.data.message, { variant: "success" });
                setLoading(false);
                navigate("/");
            } else {
                enqueueSnackbarWithClose(response.data.message, { variant: "error" });
                setLoading(false);
            }

            setAuthenticating(false);
        } catch (error) {
            console.log(error, "response");
            enqueueSnackbarWithClose(error?.response?.data?.message, { variant: "error" });
            setLoading(false);
        }
    };

    return (
      <>
        {/* {loading && <Loading />} */}
        <main className="auth-page">
          <div className="auth-page__container">
            {/* <img src={LogoSmall} /> */}
            <div className="auth-page__frame">
              <div className="auth-page__wrapper">
                <div className="auth-page__content">
                  <section className="text-gray-800 body-font">
                    <div className="container-fluid mx-auto">
                      <div className="flex flex-wrap -mx-4 -mb-10 text-center">
                        <div className="sm:w-1/2 xs:hidden lg:block">
                          <div className="max-w-screen-xl m-auto">
                            <div className="w-full relative select-none">
                              <div className="sideImg">
                                <img src={login} style={{ height: "100vh", margin: "0 auto" }} alt="app logo" />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sm:w-1/2">
                          <div className="auth-page__logo">
                            <Link to="/">
                              <img src={LogoSmall} width={"100px"} alt="app small logo" />
                            </Link>
                          </div>
                          <div className="signup">
                            <div className="signup__wrapper">
                              <div className="step_one">
                                <h1>Forgot your password?</h1>
                                <p>To reset your password, please enter the email address of your Task Pareto account.</p>
                                <form className="login-form" onSubmit={handleSubmit(handleFormSubmit)}>
                                  <div className="field">
                                    <label htmlFor="email" className="label">
                                      Email
                                    </label>
                                    <input {...register("resetEmail", { required: { value: true, message: "Please Enter Email" }, pattern: { value: validationRegex.Email_valid_regex, message: "Enter Valid E-mail address" } })} placeholder="Enter Email" id="email" />
                                  </div>
                                  <span className="error-message">{errors?.resetEmail?.message}</span>
                                  <button className="auth-button submit-button" disabled={authenticating ||loading} type="submit">
                                    {loading ? "...Loading" : "Submit"}
                                  </button>
                                  <hr />
                                  <p>
                                    Already signed up?{" "}
                                    <Link className="cursor-pointer" to="/signin">
                                      Go to login
                                    </Link>
                                  </p>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
};


