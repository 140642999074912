import LabelInfo from 'ActionItems/LabelInfo/LabelInfo';
import { TaskDate } from 'DateHelpers/task-date';
import React, { useState } from 'react'
import { FaRegCircle } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import { TbSubtask } from 'react-icons/tb'
import { Icon } from "@iconify/react";
import AdminSubTasksInfo from './AdminSubTasksInfo';

const AdminTaskInfo = ({ adminTaskInfo }) => {
  const [showSubTasks, setShowSubTasks] = useState("");
  return (
    <>
      <div className=" flex items-center gap-1 flex-grow w-full" >
      {adminTaskInfo?.subtasks?.length > 0 && (
                <button onClick={() => setShowSubTasks((pre) => (pre ? "" : adminTaskInfo.id))} className={`text-xl  group-hover:opacity-100 transition-all cursor-pointer active:cursor-grabbing hover:bg-zinc-300 ${adminTaskInfo?.subtasks?.length>0?" -ml-5":""}`}>
                  {showSubTasks ? <FiChevronRight /> : <FiChevronDown />}
                </button>
              )}
        {<FaRegCircle className={`mr-1 ${adminTaskInfo?.priority} task_complete_checkbox`} />}
        <div className={`task__details ${adminTaskInfo.completed ? "Task-completed" : "task-inprogress"}`}>
          <p className="task__text">
            {adminTaskInfo?.title}
          </p>
          {/* Show the description if only if the task was nopt ocmpleted */}
          {!adminTaskInfo?.completed && <p className=" task__description text-gray-400 text-xs">{adminTaskInfo?.description}</p>}
          <div className="task__info mt-2 flex gap-2">
            {adminTaskInfo?.subtasks?.length > 0 && (
              <div className="task__sub__info flex">
                <TbSubtask />
                <span className=" text-xs ">
                  <span className="sub_numerator">{adminTaskInfo?.subtasks?.filter((unCom) => unCom.completed)?.length}</span>
                  <span className="">/</span>
                  <span className="sub_denominator">{adminTaskInfo?.subtasks?.length}</span>
                </span>
              </div>
            )}
            <div>{adminTaskInfo.date && <TaskDate date={adminTaskInfo?.date} />} </div>
            <div className="task_label_info gap-1">
              {adminTaskInfo?.labels?.map((data) => (
                <LabelInfo taskLabels={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full">

        <div className="flex items-center gap-1 justify-end">
          <span className="text-xs text-black/60 dark:text-white/50 ">{adminTaskInfo?.project?.id ? adminTaskInfo?.project?.name : "Inbox"}</span>
          {adminTaskInfo?.project?.id ? (
            "#"
          ) : (
            <span>
              <Icon icon="akar-icons:inbox" color="#579dff" />
            </span>
          )}
        </div>
      </div>
      <hr />
      {showSubTasks === adminTaskInfo.id && (
        <div className=" ml-9">
          <div className="flex flex-col ">
            {adminTaskInfo?.subtasks?.length > 0 && adminTaskInfo?.subtasks.map((subTask, i) => <AdminSubTasksInfo key={subTask.id} subTask={subTask} subTasks={adminTaskInfo?.subtasks} setSubTasks={adminTaskInfo} />)}
          </div>
        </div>
      )}
    </>
  )
}

export default AdminTaskInfo