import React, { useState } from "react";
import { UpdateTask } from "store/TodayTasksDataSlice";
import { useDispatch } from "react-redux";
import "./taskBarStyles.scss";
import { useDragControls, } from "framer-motion";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { HiOutlinePencil as EditIcon } from "react-icons/hi";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { TaskDate } from "DateHelpers/task-date";
import CompleteTaskCheckBox from "ActionItems/CompleteTaskCheckBox/CompleteTaskCheckBox";
import MoreTaskOptions from "ActionItems/MoreTaskActions/MoreTaskActions";
import { TaskbarSkeleton } from "layout/Skeleton";
import SubTaskBar from "components/SubTaskBar/SubTaskBar";
import { BsCalendar2Event } from "react-icons/bs";
import QuickActionForCompleteTask from "ActionItems/QuickActionForCompleteTask/QuickActionForCompleteTask";
import { updateOnlyTaskDate } from "ApiService";
import { Tooltip } from "react-tooltip";
import { FaRotate } from "react-icons/fa6";
import { TbSubtask } from "react-icons/tb";
import { FiChevronDown } from "react-icons/fi";
import LabelInfo from "ActionItems/LabelInfo/LabelInfo";
import TaskEditor from "components/TaskEditor/TaskEditor";
import { FiChevronRight } from "react-icons/fi";
import TaskAssigneeDropDown from "ActionItems/TaskAssigneeDropDown/TaskAssigneeDropDown";
import { handleAppLoading } from "store/LeftMenuSlice";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const TaskBar = ({ projectData, task, tasks, setTasks, setEditId, EditId }) => {
  const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const [subTasks, setSubTasks] = useState(task?.subtasks);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [editorId, setEditorId] = useState("");
  const [showSubTasks, setShowSubTasks] = useState("");
  const dragControls = useDragControls();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { workspacekey } = useParams();
  const isThisPathIsProject = pathname.includes("project");
  // console.log(isThisPathIsProject,'location');
  //--------------------------_API CALL FOR SCHEDULE-----------------------//
  async function handleQuickSchedule(dateObj) {
    dispatch(handleAppLoading());
    let datePay = {
      taskId: task.id,
      newDate: dateObj.date,
    };
    try {
      const updateOnlyDateRes = await updateOnlyTaskDate(datePay).finally(() => {
        dispatch(handleAppLoading());
      });
      if (updateOnlyDateRes.data.status === 1) {
        enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "success" });
        //moving the update to store
        dispatch(UpdateTask(updateOnlyDateRes.data.data.task));
      } else {
        enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const RenderComponent = () => {
    return (
      <BsCalendar2Event
        className="mr-1"
        fontSize={"18px"}
        // fill={"#5297ff"}
      />
    );
  };
  const handleEditor = (taskId) => {
    setEditId(taskId);
    setEditorId(taskId);
    // setShowEditor(Boolean(taskId)?true:false)
  };
  const handleRight = (e, id) => {
    e.preventDefault(); // Prevent the default context menu from appearing
    document.getElementById(`moreQuickActionsFortask-${id}`).click();
  };

  return (
    <>
      <Tooltip id="my-tooltip" place="top" noArrow offset={0} />
      <div className={`wraping py-1 border-b w-full flex items-center justify-between gap-1 group bg-white ${task?.subtasks?.length > 0 ? " relative left-[-21px]" : ""}`}>
        {task.id === EditId ? (
          <TaskEditor
            key={task.id}
            task={task}
            // tasks={todayTasks}
            // setTasks={setTasks}
            setEditId={handleEditor}
          />
        ) : (
          <>
            <div className="flex items-baseline">
              <button className="text-xl dark:text-white/50 md:opacity-0 group-hover:opacity-100 transition-all cursor-grab active:cursor-grabbing" onPointerDown={(event) => dragControls.start(event)}>
                <DragIcon />
              </button>
              {task?.subtasks?.length > 0 && (
                <button onClick={() => setShowSubTasks((pre) => (pre ? "" : task.id))} className="text-xl  group-hover:opacity-100 transition-all cursor-pointer active:cursor-grabbing hover:bg-zinc-300">
                  {!showSubTasks ? <FiChevronRight /> : <FiChevronDown />}
                </button>
              )}

              <div className="flex items-start gap-1 flex-grow w-full" onContextMenu={(e) => handleRight(e, task.id)}>
                <CompleteTaskCheckBox taskInfo={task} />
                <div onClick={() => navigate(`/app/task/${task?.id}`)} className={`task__details cursor-pointer ${task.completed ? "Task-completed" : "task-inprogress"}`}>
                  <p className="task__text">
                    {/* <Link to={`/app/task/${task?.id}`} className="flex-grow"> */}
                    {task?.title}
                    {/* </Link> */}
                  </p>
                  {/* Show the description if only if the task was nopt ocmpleted */}
                  {!task?.completed && <p className=" task__description text-gray-400 text-xs">{task?.description}</p>}
                  <div className="task__info mt-2 gap-2">
                    {task?.subtasks?.length > 0 && (
                      <Link to={`/app/task/${task?.id}`} className="hover:text-black">
                        <div className="task__sub__info flex">
                          <TbSubtask />
                          <span className=" text-xs ">
                            <span className="sub_numerator">{task?.subtasks?.filter((unCom) => unCom.completed)?.length}</span>
                            <span className="">/</span>
                            <span className="sub_denominator">{task?.subtasks?.length}</span>
                          </span>
                        </div>
                      </Link>
                    )}
                    <div>{task.date && <TaskDate date={task?.date} />} </div>
                    {task?.due?.is_recurring && (
                      <div className="task__recurring__info  ">
                        <span className="text-xs">
                          <FaRotate fill={"#579dff"} />
                        </span>
                      </div>
                    )}
                    <div className="task_label_info gap-1">
                      {task?.labels?.map((data) => (
                        <LabelInfo taskLabels={data} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-full">
              {
                <div className="flex">
                  {!task.completed && (
                    <>
                      <button onClick={() => handleEditor(task.id)} data-tooltip-id="my-tooltip" data-tooltip-content="Edit Task" className="mr-3 md:opacity-0 group-hover:opacity-100 transition-all display-action-icons">
                        <EditIcon className=" text-xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" />
                      </button>
                      <button className="mr-2 md:opacity-0 group-hover:opacity-100 transition-all display-action-icons">
                        {/* <EditIcon className=" text-2xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" /> */}
                        <QuickActionForCompleteTask modalType={"quickCalender"} getTaskDateData={handleQuickSchedule} rescheduleViewContent={<RenderComponent />} />
                      </button>
                      {isThisPathIsProject && (
                        <button className={`mr-2 md:opacity-${task?.task_assigned_by ? 1 : 0} group-hover:opacity-100 transition-all display-action-icons`}>
                          <TaskAssigneeDropDown workspacekey={workspacekey} taskInfo={task} />
                        </button>
                      )}
                    </>
                  )}
                  <button data-tooltip-id="my-tooltip" data-tooltip-content="More Task Actions" className="mr-2 md:opacity-0 group-hover:opacity-100 transition-all display-action-icons" id={`moreactions${task.id}`}>
                    {/* <EditIcon className=" text-2xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" /> */}
                    <MoreTaskOptions taskInfo={task} />
                  </button>
                </div>
              }
              <div className="mobile flex items-center gap-1 justify-end">
                <span className="text-xs text-black/60 dark:text-white/50 ">{task?.projectName ? task.projectName : "Inbox"}</span>
                {task?.projectName ? (
                  "#"
                ) : (
                  <span>
                    <Icon icon="akar-icons:inbox" color="#579dff" />
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {showSubTasks === task.id && (
        <div className=" ml-9">
          {/* dragElastic={0.8} variants={variants.container} initial="hidden" animate="show" */}
          <div className="flex flex-col ">{task?.subtasks?.length > 0 ? task?.subtasks.map((subTask, i) => <SubTaskBar key={subTask.id} subTask={subTask} subTasks={task?.subtasks} setSubTasks={setSubTasks} />) : <>{isLoading && <TaskbarSkeleton count={10} />}</>}</div>
        </div>
      )}
    </>
  );
};
export default TaskBar;

 









