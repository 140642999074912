import { createSlice } from "@reduxjs/toolkit";

const initialState = {loginData: "", isAdminLogged: false };

const AdminAuthDataSlice = createSlice({
  name: "Admin auth Data",
  initialState,
  reducers: {
    AdminCaptureAuthData: (state, action) => {
      state.loginData = action.payload[0];
      state.isAdminLogged = action.payload[1];
    },
  },
});

export const { AdminCaptureAuthData } = AdminAuthDataSlice.actions;

export default AdminAuthDataSlice.reducer;

