import moment from "moment";

export const getRequiredDateFormat =(dateParam)=>{

    return moment(dateParam).format('YYYY-MM-DD')
}

/**
 * 
 * @param {*} dateStr is ISO String 2023-10-02T00:00:00.000Z  
 * 
 * either 2023-10-02 , 2023-10-02T00:00:00.000Z  returns  {day: 'Saturday', date: '2023-10-02'}
 * @returns 
 */
export const getDayAndDateObjFromISO =(dateStr)=>{

  const dateFormat = getRequiredDateFormat(dateStr)

    const inputDate = new Date(dateFormat);
    const currentDate = new Date();
  
    // Check if the input date is the same as the current date
    function checkIfDateIsToday(inputDate, currentDate) {
      return (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear()
      );
    }
  function checkIfDateIsTomorrow(inputDate, currentDate) {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const tomorrow = new Date(currentDate.getTime() + oneDayInMilliseconds);
    return (
      inputDate.getDate() === tomorrow.getDate() &&
      inputDate.getMonth() === tomorrow.getMonth() &&
      inputDate.getFullYear() === tomorrow.getFullYear()
    );
  }
    // Check if the input date is within the current week
    if (checkIfDateIsToday(inputDate, currentDate)) {
      return { day: 'Today', date: dateFormat };
    } else if (checkIfDateIsTomorrow(inputDate, currentDate)) {
      return { day: 'Tomorrow', date: dateFormat };
    } else if (
      inputDate >= currentDate &&
      inputDate <= new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 6)
    ) {
      // If it's within the current week, display the day of the week
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayOfWeek = daysOfWeek[inputDate.getDay()];
      return { day: dayOfWeek, date: dateFormat };
    } else {
      // If it's not today or within the current week, display the date format
      return { day: moment(dateStr).format("MMM DD yyyy"), date: moment(dateStr).format("YYYY-MM-DD") }//moment(dateStr).format("MMM DD");
    }
}
