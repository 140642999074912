import React, { useState, useRef, useEffect } from "react";
import { ImSpinner8 as SpinnerIcon } from "react-icons/im";

import TaskDatePicker from "ActionItems/TaskDatePicker/TaskDatePicker";
import PriorityPicker from "ActionItems/PriorityPicker/PriorityPicker";
import { createTaskAPI } from "ApiService";
import { AddTask } from "store/TodayTasksDataSlice";

import { useDispatch } from "react-redux";
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
import * as chrono from "chrono-node";
import { Editor, EditorState, CompositeDecorator, getDefaultKeyBinding } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { checkForTaskPriority } from "utils/utils";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const UpComeAddTaskForm = ({ formAbort, isQuick, taskDate }) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  //**this state holds the key word to highlet in the editoe */
  const [extractedDateTerm, setExtractedDateTerm] = useState("");
  const editorRef = useRef(null);
  //** this is the editor draft state initially empty */
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);
  const [taskData, setTaskData] = useState({
    task: "",
    taskDescription: "",
  });
  const [taskSchedule, setTaskSchedule] = useState({ day: "", date: null });
  const [taskPriority, setTaskPriority] = useState(4);
  const [isDatePromotReq, setIsDatePromotReq] = useState(true);


  const AddTaskToClient = async (e) => {
    if(taskData.task && taskData.task.trim().length > 0){
      setTaskData({
        task: "",
        taskDescription: "",
      });
    setFormLoading(true)
    let payload = {
      title: taskData.task?.trim(),
      description: taskData.taskDescription?.trim(),
      date: taskSchedule.date ? taskSchedule.date : null,
      priority: `priority${taskPriority}`,
      reminders: [],
      labels: [],
      category: "",
      isFavorite: false,
    };

    const responseDate = await createTaskAPI(payload).finally(() => {
      setFormLoading(false)
    });

    if (responseDate.data.status === 1) {
      enqueueSnackbarWithClose(responseDate.data.message, { variant: "success" });

      // if(responseDate.data.data.date){
      dispatch(AddTask(responseDate.data.data));//{}
      // }

      //----------clearing the input fields
      setTaskData({
        task: "",
        taskDescription: "",
      });
      // setTaskSchedule({ day: "", date: null });
      checkIsTaskingAddingForToday()
      setTaskPriority(4);
      setEditorState(EditorState.createEmpty());
      //** set the task schedule date as today as the route is in today  */

      // if (formLocation === "today") {
      //   setTaskSchedule({ day: "Today", date: moment().format("YYYY-MM-DD") });
      // }

      /**
       * close the modal if it is opened from quick modal
       */
      if (isQuick) {
        formAbort()
      }
    } else {
      enqueueSnackbarWithClose(responseDate.data.message, { variant: "error" });
    }
  }else{
    enqueueSnackbarWithClose("Please enter task title", { variant: "error" });
  }
  };

  useEffect(() => {
    checkIsTaskingAddingForToday()
  }, [taskDate]);



  const checkIsTaskingAddingForToday = () => {
    let taskDateObj = getDayAndDateObjFromISO(taskDate)
    setTaskSchedule(taskDateObj);
  }

  const dateparse = (dateString) => {
    const currentDate = new Date();
    const parsedDate = chrono.parseDate(dateString, currentDate, { forwardDate: true });
    let res = chrono.parse(dateString)[0];
    if (res) {
      setExtractedDateTerm(res?.text);
    } else {
      setExtractedDateTerm("");
    }
    if (parsedDate) {
      const parsedDateTime = parsedDate.getTime(); // Get the timestamp of the parsed date
      const currentDateTime = currentDate.getTime(); // Get the timestamp of the current date
      if (parsedDateTime >= currentDateTime) {
        // Date is either current or in the future
        return parsedDate;
      } else {
        setExtractedDateTerm("");
        // Date is in the past
        return null; // Or handle it as needed
      }
    } else {
      setExtractedDateTerm("");
      return null; // No valid date parsed
    }
  };

  // Example usage:
 
  // Create a decorator to highlight specific words
  const keywordDecorator = new CompositeDecorator([
    {
      strategy: findKeywordStrategy(extractedDateTerm),
      component: KeywordSpan,
    },
  ]);

  //** functions for keyword and hightltter START */
  useEffect(() => {
    // setEditorState(EditorState.createEmpty(keywordDecorator));
    const contentState = editorState.getCurrentContent();
    const newEditorState = EditorState.createWithContent(contentState, keywordDecorator);
    const currentSelection = editorState.getSelection();
    const stateWithContentAndSelection = EditorState.forceSelection(newEditorState, currentSelection);
    setEditorState(stateWithContentAndSelection);
  }, [extractedDateTerm]);

  const handleEditorChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    setTaskData({ ...taskData, task: plainText }); //updating this to state variable
    setEditorState(newEditorState);
    
    if (isDatePromotReq) {
      let parsedDate = dateparse(plainText);
      let reqDate = getDayAndDateObjFromISO(parsedDate);
      if (parsedDate) {
        setTaskSchedule(reqDate);
      } else {
        checkIsTaskingAddingForToday();
      }
    }
    if (plainText) {
      const priorityOfTask = checkForTaskPriority(plainText);
      setTaskPriority(priorityOfTask)
    }
  };
  const handleKeyCommand = (command) => {
    if (command === "submit-form") {
      // Implement the logic to submit the form here
      AddTaskToClient();
      return "handled";
    }
    return "not-handled";
  };

  const customKeyBinding = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      return "submit-form";
    }
    return getDefaultKeyBinding(e);
  };
  const handleCallBackDueDate = (dateData) => {
    setTaskSchedule(dateData)
    setIsDatePromotReq(false)
  }
  return (
    <>
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}>
        <form onSubmit={(event) => AddTaskToClient(event)} className="border-2 rounded-lg mt-1 bg-white">
          <div className="flex flex-col gap-2 p-3">
            {/* <input
              // type="text"
              ref={taskTitleRef}
              wrap="soft"
              className="font-bold text-base outline-none disabled:cursor-not-allowed disabled:opacity-40 bg-transparent text-black"
              placeholder="Task Name"
              value={taskData.task}
              // disabled={formLoading}
              autoFocus
              onChange={(e) => handleTaskTitleChange(e)}
            /> */}
            <Editor editorState={editorState} onChange={handleEditorChange} handleKeyCommand={handleKeyCommand} keyBindingFn={customKeyBinding} placeholder="Type your text here..." ref={editorRef} />

            <textarea
              placeholder="Description"
              className="text-sm outline-none h-auto  dark:border-dark-100  focus:rounded transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-40 bg-transparent text-black"
              // disabled={formLoading}
              value={taskData.taskDescription}
              onChange={({ target }) =>
                setTaskData({
                  ...taskData,
                  taskDescription: target.value,
                })
              }
            />
            <div className="flex w-full items-center justify-between">
              <div className="flex w-full items-center gap-2">
                <TaskDatePicker getTaskDateData={handleCallBackDueDate} taskSchedule={taskSchedule} />
                <PriorityPicker setTaskPriority={setTaskPriority} taskPriority={taskPriority} />
                {/* <Example getTaskDateData={setTaskSchedule} taskSchedule={taskSchedule}/> */}
              </div>
              {/* <div className="flex items-center gap-2">
                            <button
                                type="button"
                                className="text-xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <TagIcon />
                            </button>
                            <button
                                type="button"
                                className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <FlagIcon />
                            </button>
                            <button
                                type="button"
                                className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <ClockIcon />
                            </button>
                        </div> */}
            </div>
          </div>
          <div className="flex w-full p-2 items-center justify-end gap-2">
            <button
              type="button"
              className="bg-gray-100 dark:bg-dark-100 dark:text-white dark:hover:bg-white/20 py-2 px-4 text-sm font-bold text-black/70 rounded hover:bg-gray-200"
              onClick={() => {
                formAbort();
              }}>
              Cancel
            </button>
            <button onClick={(e) => AddTaskToClient()} type="button" className="bg-primary py-2 px-4 text-sm font-bold text-white/90 dark:text-gray-300 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40" disabled={!taskData.task.trim() || formLoading}>
              {formLoading ? (
                <span className="flex items-center gap-2">
                  <SpinnerIcon className="animate-spin" />
                  <span>Loading...</span>
                </span>
              ) : (
                "Add task"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpComeAddTaskForm;

// Define a function to find the keyword's strategy
function findKeywordStrategy(keyword) {
  return function (contentBlock, callback) {
    const text = contentBlock.getText();
    const startIndex = text.indexOf(keyword);
    if (startIndex !== -1) {
      callback(startIndex, startIndex + keyword.length);
    }
  };
}

// Custom span component for highlighting the keyword
const KeywordSpan = (props) => {
  return <span style={{ backgroundColor: "#d5d5d5", padding: "0 2px" }}>{props.children}</span>;
};