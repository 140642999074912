import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import { usePopper } from "react-popper";
import { Tooltip } from "react-tooltip";
import { GoInbox } from "react-icons/go";
import { BiCaretDown } from "react-icons/bi";

function SelectProjectForTask({ setTaskProjectInfo, taskProjectInfo, userProjects, projectData }) {
  const [projects, setProjects] = useState(userProjects);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "top",
  });
  const popperStyles = {
    ...styles.popper,
    // display: isShown ? "block" : "none"
  };
  /**
   * priority variable
   */
  const [selectedProjectValue, setSelectedProjectValue] = useState(4);

  const handleProjectForTaskClick = (e) => {
    setSelectedProjectValue(e);
    setTaskProjectInfo(e);
    setIsShown(false);
  };

  useEffect(() => {
    setSelectedProjectValue(taskProjectInfo);
  }, [taskProjectInfo]);

  const [searchQuery, setSearchQuery] = useState("");
  const handleProjectsSearch = (event) => {
    // setTextFieldValue(e.target.value)
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      // Filter the projects based on the search query
      const filtered = projects.map((item) => {
        return {
          ...item,
          projects: item.projects.filter((project) => project.name.toLowerCase().includes(query.toLowerCase())),
        };
      });
      setProjects(filtered);
    } else {
      setProjects(userProjects);
    }
  };
  return (
    <div>
      <Tooltip id="my-tooltip" place="top" noArrow offset={0} />

      <div data-tooltip-id="my-tooltip" data-tooltip-content="Select a Project" ref={setReferenceElement} className={`border cursor-pointer p-1 rounded-md flex items-center gap-1 text-zinc-500 w-fit`}>
        <button onClick={(e) => setIsShown((x) => !x)} className="flex items-center gap-1" type="button">
          <span>{taskProjectInfo ? <Icon icon="ci:line-l" width="25" color={taskProjectInfo.colorCode || `#2c3F59`} /> : <GoInbox className="text-blue-500 text-xs" />}</span>

          <span className={`prior text-black`}>{taskProjectInfo ? taskProjectInfo.name : "Inbox"}</span>

          <span>
            <BiCaretDown />
          </span>
        </button>
        {/*  */}
      </div>

      {isShown && (
        <div className="modal-overlay-popper" onClick={() => setIsShown(false)}>
          <div
            ref={setPopperElement}
            style={popperStyles}
            {...attributes.popper}
            // onClick={(e)=>e.stopPropagation()}
          >
            <div
              className="modal-popper priorityModal rounded-md"
              onClick={(e) => e.stopPropagation(e)}
              // style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
            >
              <input className=" focus:outline-slate-400 border rounded-md p-1 " placeholder="Type a project" type="text" onChange={(e) => handleProjectsSearch(e)} />
              <ul>
                <li onClick={() => handleProjectForTaskClick("")} className={`flex gap-2 items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem`}>
                  <span>
                    <GoInbox className="text-blue-500 text-xs" />
                  </span>
                  <span>Inbox</span>
                </li>
                {projects &&
                  projects.length > 0 &&
                  projects.map((data) => {
                    return (
                      <li
                        key={data.id}
                        // onClick={() => handleProjectForTaskClick(1)}
                        className={`flex gap-2 items-center p-2 `}>
                        <div className="workspace-div">
                          <div className="workspace-details flex gap-1 align-middle items-center">
                            <span className="px-2 py-1 bg-primary rounded-md text-white font-medium">{data?.workspaceName?.charAt(0)?.toUpperCase()}</span>
                            {/* <GoInbox className="text-blue-500 text-xs" /> */}
                            <span className=" font-bold text-black">{data.workspaceName}</span>
                          </div>
                          <div className="workspace-projects ml-4 mt-1 cursor-pointer hover:rounded-lg priorityListItem">
                            {data?.projects?.length > 0 &&
                              data?.projects.map((prD) => {
                                return (
                                  <div key={prD.name} onClick={() => setTaskProjectInfo(prD)} className="hover:bg-zinc-200 rounded workspace-details flex gap-1 align-middle items-center py-2 px-1">
                                    <span className=" text-lg">
                                      <Icon icon="ci:line-l" width="25" color={prD ? prD.colorCode || `#2c3F59` : "#2c3F59"} />
                                    </span>
                                    {/* <GoInbox className="text-blue-500 text-xs" /> */}
                                    <span className=" font-bol">{prD.name}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <hr />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectProjectForTask;


























