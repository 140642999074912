import { Routes, Route, Navigate } from "react-router-dom";
import { LandingPage } from "pages/landing-page";
import { AuthenticationPage } from "pages/authentication";
import Layout from "layout";
// import { ProtectedRoutes } from 'components/AuthenticatedRoutes'
import PageNotFound from "layout/PageNotFound";
import TodayTasks from "components/TodayTasks/TodayTasks";
import InboxTasks from "components/InboxTasks/InboxTasks";
import CompleteTaskView from "Models/CompleteTaskVIew/CompleteTaskView";
import Notification from "components/Notification/Notification";
import { AdminProtectedRoutes, ProtectedRoutes } from "./ProtectedRoutes";

import WorkSpacesView from "components/WorkSpaces/WorkSpaceView";
import Upcoming from "components/Upcoming/Upcoming";
import LabelsComponent from "components/LabelsComponent/LabelsComponent";
import ProjectDetailView from "components/WorkSpaces/Projects/ProjectDetailView";
import { ForgotPassword } from "pages/authentication/ForgotPassword";
import ResetPassword from "pages/authentication/ResetPassword";
import { useSelector } from "react-redux";

import { AdminLogin } from "components/AdminComponents/AdminLogin/AdminLogin";
import AdminLayout from "layout/AdminLayout";
import AdminDashboard from "components/AdminComponents/AdminDashboard/AdminDashboard";
import AdminUsers from "components/AdminComponents/AdminUsers/AdminUsers";
import AdminUserDetails from "components/AdminComponents/AdminUserDetails/AdminUserDetails";
const AppRoutes = () => {
  // const { isUserLogged } = useSelector((state) => state?.AuthData)

  const { isAdminLogged } = useSelector((state) => state?.AdminAuthDetails)

  const isUserLogged = localStorage.getItem('access_token');
  return (
    <>
      <Routes>
        {/* <Route element={<UnauthenticatedRoutes />}> */}
        <Route exact path="/" element={isUserLogged ? <Navigate to={"/app/today"} /> : <LandingPage />} />
        <Route exact path="/signin" element={isUserLogged ? <Navigate to={"/app/today"} /> : <AuthenticationPage />} />
        <Route exact path="/signup" element={isUserLogged ? <Navigate to={"/app/today"} /> : <AuthenticationPage />} />
        {/* </Route> */}

        <Route element={<ProtectedRoutes />}>
          <Route exact path={"/app/*"} element={<Layout />}>
            <Route path="inbox" element={<InboxTasks />} />
            <Route path="today" element={<TodayTasks />} />
            <Route path="notification" element={<Notification />} />
            <Route path="task/:taskID" element={<CompleteTaskView />} />

            {/* <Route path="project/:id" element={<EditProject />} /> */}
            <Route path="workspace/:id" element={<WorkSpacesView />} />
            <Route path="workspace/:workspacekey/project/:id" element={<ProjectDetailView />} />
            <Route path="labels" element={<LabelsComponent />} />
            <Route path="upcoming/:weekDate" element={<Upcoming />} />
          </Route>
        </Route>
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />

        {/* ADMIN ROUTES */}
        <Route path="/admin" element={isAdminLogged ? <Navigate to={"/admin/dashboard"} /> : <AdminLogin />} />
        <Route element={<AdminProtectedRoutes />}>
          <Route path={"/admin/*"} element={<AdminLayout />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="userDetails/:userId" element={<AdminUserDetails />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />

      </Routes>
    </>
  );
};

export default AppRoutes;









