import { axiosInstance } from 'Services/api'
import React, { useState } from 'react'
import { ImSpinner8 as SpinnerIcon } from "react-icons/im";
import { handleNumInput } from 'utils/utils'
import { useNavigate } from "react-router-dom";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const VerifyEmail = (props) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const { userData } = props;
  const navigate = useNavigate();

  const [emailOtp, setEmailOtp] = useState("");

  const [loading, setLoading] = useState(false);

  const verifyUserMail = async (event) => {
    event.preventDefault();
    setLoading(true);
    let payload = {
      email: userData?.email,
      otp: emailOtp,
    };
    try {
      const response = await axiosInstance.post("/verifyEmail", payload);
      if (response.data.status === 1) {
        setLoading(false);
        enqueueSnackbarWithClose(response?.data?.message, { variant: "success" });
        setLoading(false);
        navigate("/signin");
        props.setShowVerifyEmail(false);
      } else {
        setLoading(false);
        enqueueSnackbarWithClose(response?.data?.message, { variant: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbarWithClose(error?.response?.data?.message, { variant: "error" });
      setLoading(false);
    }
  };
  return (
    <>
      <div className="signup OtpScreen">
        <div className="signup__wrapper">
          <div className="step_one">
            <h1>
              <span>
                Hello <span className=" text-blue-500">{userData?.email}</span>
              </span>
            </h1>
            <form className="signup-form" onSubmit={verifyUserMail}>
              <div className="field mb-10">
                <label htmlFor="username" className="label">
                  Enter OTP & verify{" "}
                </label>
                <input onKeyDown={(e) => handleNumInput(e)} type="text" value={emailOtp} name="username" id="username" autoComplete="off" onChange={(e) => setEmailOtp(e.target.value)} maxLength={6} />
              </div>
              <button type="submit" className="bg-primary py-2 px-4 mb-5 text-sm font-bold text-white/90 dark:text-gray-300 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40" disabled={!emailOtp || emailOtp.length != 6}>
                {loading ? (
                  <span className="flex items-center gap-2">
                    <SpinnerIcon className="animate-spin" />
                    <span>Loading...</span>
                  </span>
                ) : (
                  "Verify"
                )}
              </button>
              <hr />
              <p>
                Already signed up?{" "}
                <span onClick={() => props.setShowVerifyEmail(false)} className="cursor-pointer text-red-400">
                  Go to login
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}






export default VerifyEmail