import TaskBar from 'components/TaskBar/TaskBar'
import React, {  useState } from 'react'
import { MdOutlineTaskAlt } from "react-icons/md";
import { useSelector } from 'react-redux';

const CompletedTasks = () => {
    const completedTasks = useSelector((state) => state.todayTask.todayTasks?.filter((filData) => filData.completed));

    const [displayCount, setDisplayCount] = useState(10);

    const handleShowMore = () => {
        setDisplayCount(prevCount => prevCount + 10);
    };

    return (
        <>
            <div className=' opacity-80'>
                {/* <span className=" text-lg">Completed tasks</span> */}
                <hr />
                {completedTasks && completedTasks?.length > 0 ? (
                    completedTasks.slice(0, displayCount).map((item, index) => (
                        <>
                            <TaskBar
                                key={item.id}
                                task={item}
                                tasks={completedTasks}
                            />
                        </>
                    ))
                ):<span className='text-base'>No completed tasks found</span>}
                {displayCount < completedTasks.length && (
                    <button onClick={handleShowMore} className=' mt-3 flex hover:bg-blue-50 p-1'>
                        <MdOutlineTaskAlt className=' text-xl  mr-2' /> <span> Show More ({Math.min(displayCount + 10, completedTasks.length)} / {completedTasks.length})</span>
                    </button>
                )}
            </div>
        </>
    )
}

export default CompletedTasks