import React, {  useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoAdd } from "react-icons/io5";
import "../../components/Settings/WorkSpace/WorkSpace.scss";
import {  createLabelAPI,  updateLabelById, } from "../../ApiService/index";
import { useDispatch } from "react-redux";
import Select from "react-select"; // Import react-select
import { TfiPlus } from "react-icons/tfi";
import { AddLabel,  UpdateLabel } from "store/LabelsDataSlice";
import { HiOutlinePencil as EditIcon } from "react-icons/hi";
import { handleInitialSpaceForInput } from "utils/utils";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
import { useSelector } from "react-redux";
export default function AddNewLabelModal(props) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const {id,item} = props
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const colors = useSelector((state) => state.labels.colorsInfo);
  const [projectName, setProjectName] = useState(item?item.labelName:"");
  const [colorCode, setColorCode] = useState(item?item.colorCode:"");
  const [colorName, setColorName] = useState(item?item.colorName:"");
  const [isFavorite, setIsFavorite] = useState(false);
  const [projectNameError, setProjectNameError] = useState("");
  const [formLoading,setFormLoading] = useState(false)
  const dispatch = useDispatch();

  async function updateLabel(data){
    const response = await updateLabelById(props.id, data);
    dispatch(UpdateLabel(response.data.data));
    closeModal();
  }
  // async function fetchData() {
  //   try {
  //     const response = await getColorsAPI();
  //     setColors(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching colors:", error);
  //   }
  // }
  const openModal = () => {
    setIsModalOpen(true);
    // fetchData()
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setProjectName(item?item.labelName:'');
    setProjectNameError("");
    setColorCode("");
    setIsFavorite(false); // Reset favorite toggle
  };

  const modalVariants = {
    hidden: {
      scale: 1,
    },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
      },
    },
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleOverlayClick = () => {
    closeModal();
  };

  const handleAddProject = async () => {
    setFormLoading(true)
    try {
      if (!projectName) {
        setProjectNameError("Project name is mandatory.");
        return;
      } else {
        setProjectNameError("");
      }
      
      const data = {
        labelName: projectName,
        colorCode: colorCode,
        colorName: colorName
      }
      const response = await createLabelAPI(data).finally(() => {
        setFormLoading(false)
      })
      console.log(response,'response');
      if (response.data.status === 1) {
        dispatch(AddLabel(response.data.data))
        enqueueSnackbarWithClose(response.data.message, { variant: "success" });
        closeModal();
      } else {
        enqueueSnackbarWithClose(response.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message ||error?.message,{variant:'error'})
      console.error("An error occurred while creating the project:", error);
    }
  };
  const handleEditProject=()=>{
    const data ={
      "labelName":projectName,
      "colorName":colorName,
      "colorCode":colorCode
    }
    updateLabel(data)
    
    //console.log(colorCode,colorName,projectName)
  }

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setColorCode(selectedOption.colorCode);
      setColorName(selectedOption.colorName);
    }
  };
  return (
    <div className="AddWorkSpace">
      <div>
        <button onClick={openModal} type="button" className="w-full flex items-center justify-center hover:bg-zinc-200">
          <div className="  p-1.5"> {!props.id ? <TfiPlus className="text-gray-800 text-lg" /> : <EditIcon className="text-xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" />}</div>
        </button>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div initial="hidden" animate="visible" exit="hidden" variants={modalVariants} className="modal-overlay" onClick={handleOverlayClick}>
            <div ref={modalRef} className="modal  rounded-md" onClick={handleModalClick}>
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}>
                <button className="absolute top-2 right-2 p-1 rounded-full text-gray-800 hover:bg-zinc-200" onClick={closeModal}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="flex">
                  <div className="w-full  p-2 ">
                    <h2 className="text-xl font-semibold">{!props?.id ? "Add Label" : "Edit Label"}</h2>
                    <hr />
                    <div className="mt-4">
                      <label>Label name</label>
                      <input
                        autoFocus
                        type="text"
                        placeholder="Label Name"
                        className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
                        defaultValue={projectName}
                        onKeyDown={(e)=>handleInitialSpaceForInput(e)}
                        onChange={(e) => {
                          setProjectName(e.target.value);
                          setProjectNameError("");
                        }}
                      />
                      {projectNameError && <div className="text-red-500">{projectNameError}</div>}
                    </div>
                    <div className="mt-4">
                      <label>Color</label>
                      <Select
                        defaultValue={item && item}
                        getOptionValue={(option) => option.colorCode}
                        getOptionLabel={(option) => (
                          <div className="flex items-center">
                            <span className="color-circle" style={{ backgroundColor: option.colorCode }}></span>
                            <span>{option.colorName}</span>
                          </div>
                        )}
                        options={colors}
                        onChange={handleSelectChange}
                      />
                    </div>

                    <div className="mt-4 hidden">
                      <label className="favorite-toggle">
                        <input type="checkbox" checked={isFavorite} onChange={() => setIsFavorite(!isFavorite)} />
                        <span className="slider round"></span>
                      </label>{" "}
                      <span className="ml-4">Add to Favorite</span>
                    </div>
                    {!id ? (
                      <button onClick={handleAddProject} type="button" className="bg-blue-500 font-bold text-white py-2 w-full flex items-center justify-center mt-4">
                        <IoAdd />
                        {formLoading?"Loading...":"Add Label"}
                      </button>
                    ) : (
                      <button onClick={handleEditProject} type="button" className="bg-blue-500 font-bold text-white py-2 w-full flex items-center justify-center mt-4">
                        <IoAdd />
                        {formLoading?"Loading...":"Edit Label"}
                        
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
















