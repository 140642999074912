// Toast.jsx
import React from "react";
import "./Toast.css";

const Toast = ({ message, type, onClose }) => {
  return (
    <div className={`toast ${type}`}>
      <p>{message}</p>
      <button onClick={onClose}>&times;</button>
    </div>
  );
};

export default Toast;

