import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,

} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Line } from "react-chartjs-2";
import { getAppStactsForAdmin } from 'ApiService';
import { enqueueSnackbar } from 'notistack';
import './adminDashboard.scss'
import { FaUsers } from "react-icons/fa6";
import { BsPersonWorkspace } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { MdOutlineTaskAlt } from "react-icons/md";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
);
const AdminDashboard = () => {
  const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const [registeredUsersData, setRegisteredUsersData] = useState([])
  const [priorityData, setPriorityData] = useState([])

  const [countData,setCountData] = useState('')
  
  const getAppStactsForAdminCall = async () => {
    try {
      const useData = await getAppStactsForAdmin()
      console.log(useData.data.data, 'useData');
      if (useData.data.status === 1) {
        setRegisteredUsersData(useData?.data?.data?.registrationCounts)
        useData?.data?.data.priorityCounts.sort((a, b) => a.priority.localeCompare(b.priority));

        setPriorityData(useData?.data?.data?.priorityCounts.map(pdata => Number(pdata.count)))

        setCountData(useData?.data?.data)
      } else {
        enqueueSnackbarWithClose(useData.data.message, { variant: 'error' })
        setRegisteredUsersData([])
        setPriorityData([])
      }
    } catch (error) {
      console.log(error);
    }

    // setAdminUserDetails(useData?.data?.data)
  }
  useEffect(() => {
    getAppStactsForAdminCall()

  }, [])
  // getAppStactsForAdmin


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Tasks based  on priority',
      },
    },
  };

  const labels = ['Priority 1', 'Priority 2', 'Priority 3', 'Priority 4'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Priority 1',
        data: [priorityData[0]],
        backgroundColor: '#d1453b',
      },
      {
        label: 'Priority 2',
        data: [0, priorityData[1]],
        backgroundColor: '#eb8909',
      },
      {
        label: 'Priority 3',
        data: [0, 0, priorityData[2]],
        backgroundColor: '#246fe0',
      },
      {
        label: 'Priority 4',
        data: [0, 0, 0, priorityData[3]],
        backgroundColor: '#e0e0e0',
      },
    ],
  };

  const data2 = {
    labels: registeredUsersData.map(monthdata => monthdata.month),
    datasets: [
      {
        label: "Users Registered",
        data: registeredUsersData.map(monthdata => Number(monthdata.userCount)),
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
    ]
  };


  return (
    <div className="md:py-8 md:px-10 px-3 w-full md:max-w-8xl lg:max-w-9xl xl:max-w-8xl mx-auto">
      <section className='pb-4'>
        <div class="container">
          <div class="row">
            <br />
            <div class="col text-center">
              {/* <h2>App Statistics</h2> */}
            </div>
          </div>
          <div class="row text-center">
            <div class="col">
              <div class="counter">
                <FaUsers className='DisplayIconDashBoard' />
                <h2 class="timer count-title count-number">{countData?.totalUserRegistrations}</h2>
                <p class="count-text ">Number of Users Registered</p>
              </div>
            </div>
            <div class="col">
              <div class="counter">
                <BsPersonWorkspace className='DisplayIconDashBoard' />
                <h2 class="timer count-title count-number">{countData?.totalWorkspaces}</h2>
                <p class="count-text ">Number of workspaces</p>
              </div>
            </div>
            <div class="col">
              <div class="counter">
                <FaTasks className='DisplayIconDashBoard' />
                <h2 class="timer count-title count-number" >{countData?.totalTasksCreated}</h2>
                <p class="count-text ">Number of tasks created</p>
              </div>
            </div>
            <div class="col">
              <div class="counter">
                <MdOutlineTaskAlt className='DisplayIconDashBoard' />
                <h2 class="timer count-title count-number">{countData?.totalTasksCompleted}</h2>
                <p class="count-text ">Number of tasks completed</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className=' pt-5'>
        <div className='flex'>
          
          <div className='w-1/2'>
            <Line data={data2} />
          </div>
          <div className='w-1/2'>
            <Bar options={options} data={data} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdminDashboard;