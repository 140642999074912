import React, { useState, useEffect, useRef } from "react";
import { getFavoritesAPI, removeFavoriteAPI } from "ApiService/index";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  updateFavoriteProjects } from "store/UserProjectsSlice";
import { Icon } from "@iconify/react";
const FavoritesProjects = () => {
  const dispatch = useDispatch();
  const [favoritesProjects, setFavoritesProjects] = useState([]);
  const [popoverIndex, setPopoverIndex] = useState(null);
  const [reloadComponent, setReloadComponent] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const favoritesData = await getFavoritesAPI();
        if (favoritesData.status === 200) {
          setFavoritesProjects(favoritesData.data.data);
          dispatch(updateFavoriteProjects(favoritesData.data.data));
        }
      } catch (error) {
        console.error("Error fetching workspaces:", error);
      }
    }

    fetchData();

 

    document.addEventListener("click", handleDocumentClick);
    return () => {
      setPopoverIndex(null);
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [reloadComponent]);

 


  const togglePopover = (index) => {
    if (popoverIndex === index) {
      setPopoverIndex(null);
    } else {
      setPopoverIndex(index);
    }
  };
  const closePopover = () => {
    setPopoverIndex(null);
  };

  const handleDocumentClick = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      closePopover();
    }
  };

  const favoriteProjects = useSelector((state) => state.userProjects.favoriteProjects);
  return (
    <div>
     {favoriteProjects &&favoriteProjects.length>0? <div className="w-full my-4">
        <div className="flex items-center justify-between">
          <div className="font-bold text-gray-900 mb-4">Favorites</div>
        </div>
        {favoriteProjects &&favoriteProjects.length>0
          ? favoriteProjects.map((favorites, index) => (
              <ul key={favorites?.id} className="cursor-pointer dark:bg-gray-700">
                <li className="rounded relative flex justify-between group hover-bg-white active-bg-blue-200 items-center">
                  <span className="flex items-center mt-3">
                    <Icon icon="ci:line-l" width="25" color={favorites ? favorites?.colorCode || `#2c3F59` : "#2c3F59"} />
                    <Link to={`workspace/${favorites?.WorkspaceId}/project/${favorites?.id}`} className="block rounded-lg   text-sm font-medium text-gray-800 hover-bg-blue-300 hover-text-black">
                      {favorites?.name}
                    </Link>
                  </span>

                </li>
              </ul>
            ))
          : "You have No Favorites Yet"}
      </div>:""}
    </div>
  );
};
export default FavoritesProjects;











