import React, { useEffect, useRef, useState } from "react";


//----- REDUX ACTIONS
import { UpdateTask } from "store/TodayTasksDataSlice";

import { ImSpinner8 as SpinnerIcon } from "react-icons/im";
import {
    IoFlagOutline as FlagIcon,
    IoAlarmOutline as ClockIcon,
} from "react-icons/io5";
import { ImPriceTag as TagIcon } from "react-icons/im";

import TaskDatePicker from "ActionItems/TaskDatePicker/TaskDatePicker";
import PriorityPicker from "ActionItems/PriorityPicker/PriorityPicker";
import { extractNumberFromString } from "utils/utils";
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
import { updatTaskForUser } from "ApiService";
import { useDispatch } from "react-redux";
import { Editor, EditorState, ContentState, CompositeDecorator, getDefaultKeyBinding } from 'draft-js';

import 'draft-js/dist/Draft.css';
import * as chrono from "chrono-node";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const TaskEditor = ({ task, tasks, setTasks, setEditId }) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
    //**this state holds the key word to highlet in the editoe */
    const [extractedDateTerm, setExtractedDateTerm] = useState("");
    const editorRef = useRef(null);
    //** this is the editor draft state initially empty */
    const [editorState, setEditorState] = useState(() => {
        const content = ContentState.createFromText(
            task.title
        );
        return EditorState.createWithContent(content);
      });

    const dispatch = useDispatch()
    const [taskData, setTaskData] = useState({
        title: task.title,
        description: task.description,
    });
    const [formLoading, setFormLoading] = useState(false);
    const [taskSchedule, setTaskSchedule] = useState({ day: "", date: null });

    const [taskPriority, setTaskPriority] = useState(4)
    /**
     * priority variable
     */
    useEffect(() => {
        // setTaskSchedule()
        if (task?.date) {
            setTaskSchedule(getDayAndDateObjFromISO(task?.date))
        }
        setTaskPriority(extractNumberFromString(task.priority))

    }, [task])

    const modifyTask = async (e) => {
        // e.preventDefault()
        setFormLoading(true)
        const editPayload = {
            "title": taskData.title,
            "description": taskData.description,
            "date": taskSchedule.date ? taskSchedule.date : null,
            "priority": `priority${taskPriority}`,
            "reminders": [],
            "labels": task?.labels,
            "category": "",
            "isFavorite": false,
            "taskEditId": task?.id,
            projectId: task?.projectId


        }
        const updateTaskAPIResponse = await updatTaskForUser(editPayload).finally(() => {
            setFormLoading(false)
        })
        if (updateTaskAPIResponse.data.status === 1) {
            enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: 'success' })
            dispatch(UpdateTask(updateTaskAPIResponse.data.data))
            setEditId('');
        } else {
            enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: 'error' })
        }
    }
    // Create a decorator to highlight specific words
    const keywordDecorator = new CompositeDecorator([
        {
            strategy: findKeywordStrategy(extractedDateTerm),
            component: KeywordSpan,
        },
    ]);

    //** functions for keyword and hightltter START */
    useEffect(() => {
        // setEditorState(EditorState.createEmpty(keywordDecorator));
        const contentState = editorState.getCurrentContent();
        const newEditorState = EditorState.createWithContent(contentState, keywordDecorator);
        const currentSelection = editorState.getSelection();
        const stateWithContentAndSelection = EditorState.forceSelection(newEditorState, currentSelection);
        setEditorState(stateWithContentAndSelection);
    }, [extractedDateTerm]);

    const dateparse = (dateString) => {
        const currentDate = new Date();
        const parsedDate = chrono.parseDate(dateString,currentDate, { forwardDate: true });
        let res = chrono.parse(dateString)[0];
        if (res) {
          setExtractedDateTerm(res?.text);
        } else {
          setExtractedDateTerm("");
        }
        if (parsedDate) {
            const parsedDateTime = parsedDate.getTime(); // Get the timestamp of the parsed date
            const currentDateTime = currentDate.getTime(); // Get the timestamp of the current date
            if (parsedDateTime >= currentDateTime) {
              // Date is either current or in the future
              return parsedDate;
            } else {
              setExtractedDateTerm("");
              // Date is in the past
              return null; // Or handle it as needed
            }
          } else {
            setExtractedDateTerm("");
            return null; // No valid date parsed
          }
      };

    const handleEditorChange = (newEditorState) => {
        const contentState = newEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        // setTaskData({ ...taskData, task: plainText }); //updating this to state variable
        setTaskData({
            ...taskData,
            title: plainText,
        })
        setEditorState(newEditorState);
        let parsedDate = dateparse(plainText);
        let reqDate = getDayAndDateObjFromISO(parsedDate);
        if (parsedDate) {
            setTaskSchedule(reqDate);
        }

        // if (plainText) {
        //     checkForTaskPriority(plainText)
        // }

    };
    const handleKeyCommand = (command) => {
        if (command === "submit-form") {
            // Implement the logic to submit the form here
            modifyTask();
            return "handled";
        }
        return "not-handled";
    };

    const customKeyBinding = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            return "submit-form";
        }
        return getDefaultKeyBinding(e);
    };
    const taskPriorities = ["p1", "p2", "p3", "p4"];
    const checkForTaskPriority = (userString) => {
        let highestPriority = 4; // Initialize with the lowest priority

        for (const priority of taskPriorities) {
            const regex = new RegExp(priority, 'gi');
            const matches = userString.match(regex);
            if (matches) {
                const lastMatch = matches[matches.length - 1];
                const priorityIndex = taskPriorities.indexOf(lastMatch.toLowerCase());
                if (priorityIndex !== -1 && priorityIndex < highestPriority) {
                    highestPriority = priorityIndex;
                }
            }
        }

        // Set the task priority based on the highest found priority
        setTaskPriority(highestPriority >= 4 ? 4 : highestPriority + 1); // Adding 1 to convert index to priority level, but now it's capped at 4
    };

    return (
        <>
            <form
                // onSubmit={(e) => modifyTask(e)}
                className="w-full border-2 rounded-lg">
                <div className="flex flex-col gap-2 dark:border-white/20 rounded-lg p-3">
                    {/* <input
                        type="text"
                        className="text-base font-medium outline-none disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                        placeholder="Task Name"
                        value={taskData.title}
                        disabled={formLoading}
                        autoFocus
                        onChange={({ target }) =>
                            setTaskData({
                                ...taskData,
                                title: target.value,
                            })
                        }
                    /> */}

                    <Editor
                        editorState={editorState}
                        onChange={handleEditorChange}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={customKeyBinding}
                        placeholder="Type your text here..."
                        ref={editorRef}
                    />
                    <textarea
                        placeholder="Description"
                        className="text-sm outline-none h-auto  focus:border   focus:rounded  transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                        disabled={formLoading}
                        value={taskData.description}
                        onChange={({ target }) =>
                            setTaskData({
                                ...taskData,
                                description: target.value,
                            })
                        }
                    />
                    <div className="flex w-full items-center justify-between">
                        <div className="flex w-full items-center gap-2">
                            <TaskDatePicker getTaskDateData={setTaskSchedule} taskSchedule={taskSchedule} />
                            <PriorityPicker setTaskPriority={setTaskPriority} taskPriority={taskPriority} />
                        </div>
                        <div className="items-center gap-2 hidden">
                            <button
                                type="button"
                                className="text-xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <TagIcon />
                            </button>
                            <button
                                type="button"
                                className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <FlagIcon />
                            </button>
                            <button
                                type="button"
                                className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <ClockIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex w-full p-2 items-center justify-end gap-2">
                    {/* <div>
                        <SelectProjectForTask setTaskProjectInfo={setTaskProjectInfo} taskProjectInfo={taskProjectInfo} userProjects={userProjects} projectData={projectData} />
                    </div> */}
                    {/* <div> */}
                        <button
                            type="button"
                            className="bg-gray-100 dark:bg-dark-100 dark:text-white dark:hover:bg-white/20 py-2 px-4 text-sm font-bold text-black/70 rounded hover:bg-gray-200"
                            onClick={() => {
                                setEditId('');
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="bg-primary py-2 px-4 text-sm font-bold text-white/90 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40"
                            disabled={!taskData.title?.trim() || formLoading}
                            onClick={()=>modifyTask()}
                        >
                            {formLoading ? (
                                <span className="flex items-center gap-2">
                                    <SpinnerIcon className="animate-spin" />
                                    <span>Loading...</span>
                                </span>
                            ) : (
                                'Modify Task'
                            )}
                        </button>
                    {/* </div>ProjectDetailView */}

                </div>
            </form>
        </>
    )
}

export default TaskEditor
// Define a function to find the keyword's strategy
function findKeywordStrategy(keyword) {
    return function (contentBlock, callback) {
        const text = contentBlock.getText();
        const startIndex = text.indexOf(keyword);
        if (startIndex !== -1) {
            callback(startIndex, startIndex + keyword.length);
        }
    };
}

// Custom span component for highlighting the keyword
const KeywordSpan = (props) => {
    return <span style={{ backgroundColor: "#d5d5d5", padding: "0 2px" }}>{props.children}</span>;
};