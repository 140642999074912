import React from 'react'
import AppRoutes from './AppRoutes'

const AppRouter = () => {
    return (
        <>
            <AppRoutes />
        </>
    )
}

export default AppRouter