import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { BsBack } from "react-icons/bs";
import { EditProfile } from "ApiService/index";
import { CaptureAuthData } from "store/AuthDataSlice";
import { useSelector, useDispatch } from "react-redux";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
export default function ChangeEmail({ onBack }) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch();
  const userAuthData = useSelector((state) => state.AuthData);
  const [email, setEmail] = useState(userAuthData?.loginData?.userDetails?.email || "");
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [emailMatchError, setEmailMatchError] = useState("");
  const [emailFormatError, setEmailFormatError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    try {
      if (isLoading) {
        return;
      }

      setEmailMatchError("");
      setEmailFormatError("");
      setIsLoading(true);

      if (!newEmail && !confirmEmail) {
        setEmailMatchError("This field can't be empty");
        return;
      }

      if (!newEmail) {
        setEmailFormatError("Please enter a valid email address");
        return;
      } else if (!confirmEmail) {
        setEmailMatchError("This field can't be empty");
        return;
      } else if (newEmail !== confirmEmail) {
        setEmailMatchError("Emails do not match");
        return;
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(newEmail)) {
        setEmailFormatError("Please enter a valid email address");
        return;
      }

      const payload = {
        email: newEmail,
      };

      const UpdateProfileRes = await EditProfile(payload);
      if (UpdateProfileRes.data.status === 0) {
        enqueueSnackbarWithClose(UpdateProfileRes.data.message, { variant: "error" });
      }
      let loginData = {
        refreshToken: userAuthData.loginData.refreshToken,
        accessToken: userAuthData.loginData.accessToken,
        userDetails: {
          ...userAuthData.loginData.userDetails,
          email: payload.email,
        },
      };
      enqueueSnackbarWithClose("Email changed successfully", { variant: "success" });

      dispatch(CaptureAuthData(["email", loginData, true]));
    } catch (error) {
      enqueueSnackbarWithClose(error, { variant: "error" });

      console.error("Error updating user profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-3">
        <button className="flex items-center text-xl text-black font-semibold " onClick={onBack}>
          <BiArrowBack className="mr-3" />
          Change Email Address
        </button>
      </div>
      <div>
        <hr />
        <p className="text-black mb-4">
          Update the email you use for your Todoist account. Your email is currently <b>{email}</b>
        </p>
        <form className="mt-4">
          <div className="mb-5">
            <label htmlFor="newEmail" className="text-black font-bold label ml-0 text-md">
              New Email
            </label>
            <input className="border border-slate-400 rounded p-2 w-[50%]" type="email" name="newEmail" id="newEmail" autoComplete="off" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
            {emailMatchError && <p className="text-red-500">{emailMatchError}</p>}
            {emailFormatError && <p className="text-red-500">{emailFormatError}</p>}
          </div>
          <div>
            <label htmlFor="confirmEmail" className="text-black font-bold label ml-0 text-md">
              Confirm New Email
            </label>
            <input className="border border-slate-400 rounded p-2 w-[50%]" type="email" name="confirmEmail" id="confirmEmail" autoComplete="off" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
            {emailMatchError && <p className="text-red-500">{emailMatchError}</p>}
          </div>
        </form>
        <button className={`bg-slate-100 px-2 py-1 submit-button mt-4 ${isLoading ? "disabled cursor-wait	" : ""}`} onClick={handleUpdate} disabled={isLoading}>
          {isLoading ? "Updating..." : "Update Email"}
        </button>
      </div>
    </div>
  );
}








































