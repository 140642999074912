import React from 'react'
import MobileCalendar from 'react-scroll-calendar';
import "react-scroll-calendar/build/react-scroll-calendar.css";
import './CalenderPickerStyles.scss'
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
import moment from 'moment';
const ScroalableCalenderPicker = ({taskSchedule,handleDateSelected}) => {
    return (
        <>
            <div className="custom-datepicker-container">
                <MobileCalendar
                    minDate={moment()}
                    selectedDate={moment(taskSchedule?.date, 'YYYY-MM-DD')}
                    maxDate={moment().add(2, 'Y')}
                    onSelect={(e) => handleDateSelected(e)}
                />
            </div>
        </>
    )
}

export default ScroalableCalenderPicker