import React, { useEffect, useState } from 'react';
import { TodayIcon } from 'DateHelpers/today-icon';
import moment from 'moment';
import { BsFlagFill, BsFlag } from 'react-icons/bs';
import { BsCalendar2Event } from 'react-icons/bs';
import { BsDot } from 'react-icons/bs';
import { MdOutlineCancel,MdNextWeek } from 'react-icons/md';

import '../PriorityPicker/priorityPick.scss'
import { LiaCouchSolid, LiaSun } from 'react-icons/lia';
import { MdOutlineDoNotDisturbOn } from 'react-icons/md'
import '../TaskDatePicker/taskschedule.scss'
import { usePopper } from "react-popper";
import { getDayAndDateObjFromISO } from 'DateHelpers/Datehelpers';
import ScroalableCalenderPicker from 'ActionItems/ScroalableCalenderPicker/ScroalableCalenderPicker';
import { Tooltip } from 'react-tooltip'


function QuickActionForCompleteTask({ setTaskPriority, taskPriority, getTaskDateData, taskSchedule, modalType, rescheduleViewContent }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "left",
  });
  const popperStyles = {
    ...styles.popper,
    // display: isShown ? "block" : "none"
  };

  const [isModalOpenPriority, setIsModalOpenPriority] = useState(false);
  const [modalPositionPriority, setModalPositionPriority] = useState({ top: 0, left: 0 });
  const openModalPriority = (event) => {
    const buttonRect = event.target.getBoundingClientRect();
    const top = buttonRect.top + window.scrollY;
    const left = buttonRect.left + buttonRect.width; // Position modal to the right of the button
    setModalPositionPriority({ top, left });
    setIsModalOpenPriority(true);
  };

  const closeModalPriority = () => {
    setIsModalOpenPriority(false);
  };
  /**
   * priority variable
   */
  const [selectedPriorValue, setSelectedPriorValue] = useState(4);

  const handlepriorClick = (e) => {
    setSelectedPriorValue(e);
    setTaskPriority(e);
  };

  const removePriority = (e) => {
    e.preventDefault();
    setSelectedPriorValue(4);
    setTaskPriority(4);
    setIsModalOpenPriority(false);
  };
  useEffect(() => {
    setSelectedPriorValue(taskPriority);
  }, [taskPriority]);
  //  --------------------------------------------------------------------------------------
  // CALENDER
  const [isModalOpenDue, setIsModalOpenDue] = useState(false);
  const [modalPositionDue, setModalPositionDue] = useState({ top: 0, left: 0 });

  const [selectedTaskDueInfo, setSelectedTaskDueInfo] = useState(taskSchedule);
  const openModalDue = (event) => {
    const buttonRect = event.target.getBoundingClientRect();
    const top = buttonRect.top + window.scrollY;
    const left = buttonRect.left + buttonRect.width; // Position modal to the right of the button
    // const top = buttonRect.top + window.scrollY - 300; // Adjust this value to control the distance from the top
    // const left = buttonRect.left + buttonRect.width; // Position modal to the right of the button
    setModalPositionDue({ top, left });
    setIsModalOpenDue(true);
  };

  const closeModalDue = () => {
    setIsModalOpenDue(false);
  };

  //************Quick calender variables */

  const [quickCalenderOpen, setQuickCalenderOpen] = useState(false);

  useEffect(() => {
    setSelectedTaskDueInfo(taskSchedule);
  }, [taskSchedule]);

  const setToday = () => {
    setSelectedTaskDueInfo({ day: "Today", date: moment().format("YYYY-MM-DD") });
    getTaskDateData({ day: "Today", date: moment().format("YYYY-MM-DD") });
  };
  const setTomorrow = () => {
    setSelectedTaskDueInfo({ day: "Tomorrow", date: moment().add(1, "day").format("YYYY-MM-DD") });
    getTaskDateData({ day: "Tomorrow", date: moment().add(1, "day").format("YYYY-MM-DD") });
  };
  const setWeekend = () => {
    setSelectedTaskDueInfo({ day: "Weekend", date: moment().day("Saturday").format("YYYY-MM-DD") });
    getTaskDateData({ day: "Weekend", date: moment().day("Saturday").format("YYYY-MM-DD") });
  };
  const setNext7days = () => {
    setSelectedTaskDueInfo({ day: "Next week", date: moment().add(7, "days").format("YYYY-MM-DD") });
    getTaskDateData({ day: "Next week", date: moment().add(7, "days").format("YYYY-MM-DD") });
  };

  const setSunday = () => {
    setSelectedTaskDueInfo({ day: "Sunday", date: moment().startOf('week').add(7, 'days').day('Sunday').format("YYYY-MM-DD") });
    getTaskDateData({ day: "Sunday", date: moment().startOf('week').add(7, 'days').day('Sunday').format("YYYY-MM-DD") });
  };
  const setNoDate = () => {
    setSelectedTaskDueInfo({ day: null, date: null });
    getTaskDateData({ day: null, date: null });
  };
  const getDateStyle = () => {
    if (selectedTaskDueInfo?.day === "Today") {
      let day = "date__today";
      return day;
    }
    if (selectedTaskDueInfo?.day === "Tomorrow") {
      let day = "date__tomorrow";
      return day;
    }
    if (selectedTaskDueInfo?.day === "Weekend") {
      let day = "date__weekend";
      return day;
    }
    if (selectedTaskDueInfo?.day === "Next week") {
      let day = "date__next-week";
      return day;
    }
    if (selectedTaskDueInfo?.day === "Sunday") {
      let day = "date__sunday";
      return day;
    }
  };

  /**
   *
   * @param {*} date will pass in format 2023-10-02  , returns {day: 'Saturday', date: '2023-10-02'}
   *
   * this is callback triggered form calender picker
   */
  function handleDateSelected(date) {
    let dateObjectToSet = getDayAndDateObjFromISO(date);
    setSelectedTaskDueInfo(dateObjectToSet);
    getTaskDateData(dateObjectToSet);
    setIsModalOpenDue(false);
  }

  return (
    <>
      <Tooltip id="my-tooltip" place="top" noArrow offset={0} />

      {modalType === "priority" && (
        <div>
          <div ref={setReferenceElement} className={`border cursor-pointer p-1 rounded-md flex items-center gap-1 text-zinc-500 w-fit`}>
            <button className="flex items-center" type="button" onClick={(e) => setIsModalOpenPriority(true)}>
              <span>
                <BsFlagFill width={"18px"} height={"18px"} className={`mr-1 prior-${selectedPriorValue}`} />
              </span>
              <span className={`prior-${selectedPriorValue}`}>Priority</span>
            </button>
            {selectedPriorValue !== 4 && (
              <button type="button" onClick={(e) => removePriority(e)}>
                <MdOutlineCancel className=" text-red-400" />
              </button>
            )}
            {/*  */}
          </div>

          {isModalOpenPriority && (
            <>
              {/* <Modal onClose={closeModal} style={modalPosition} /> */}

              <div className="modal-overlay-popper" onClick={closeModalPriority}>
                <div ref={setPopperElement} style={popperStyles} {...attributes.popper}>
                  <div
                    className="modal-popper priorityModal rounded-md"
                    // style={{ top: `${modalPositionPriority.top}px`, left: `${modalPositionPriority.left}px` }}
                  >
                    <hr />
                    <ul>
                      <li onClick={() => handlepriorClick(1)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 1 ? "selectedPrior" : ""}`}>
                        <span>
                          <BsFlagFill className="mr-2 prior-1" />
                        </span>
                        <span>Priority 1</span>
                        {selectedPriorValue === 1 && (
                          <span>
                            {" "}
                            <BsDot className="text-red-600" />
                          </span>
                        )}
                      </li>
                      <li onClick={() => handlepriorClick(2)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 2 ? "selectedPrior" : ""}`}>
                        <span>
                          <BsFlagFill className="mr-2 prior-2" />
                        </span>
                        <span>Priority 2</span>
                        {selectedPriorValue === 2 && (
                          <span>
                            {" "}
                            <BsDot className="text-red-600" />
                          </span>
                        )}
                      </li>
                      <li onClick={() => handlepriorClick(3)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 3 ? "selectedPrior" : ""}`}>
                        <span>
                          <BsFlagFill className="mr-2 prior-3" />
                        </span>
                        <span>Priority 3</span>
                        {selectedPriorValue === 3 && (
                          <span>
                            {" "}
                            <BsDot className="text-red-600" />
                          </span>
                        )}
                      </li>
                      <li onClick={() => handlepriorClick(4)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 4 ? "selectedPrior" : ""}`}>
                        <span>
                          <BsFlag className="mr-2 prior-4" />
                        </span>
                        <span> Priority 4</span>
                        {selectedPriorValue === 4 && (
                          <span>
                            {" "}
                            <BsDot className="text-red-600" />
                          </span>
                        )}
                      </li>
                    </ul>
                    <hr />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {modalType === "dueDate" && (
        <div>
          <div ref={setReferenceElement} className={`border cursor-pointer p-1 rounded-md flex items-center gap-2 ${getDateStyle()}`}>
            <button type="button" onClick={(e) => setIsModalOpenDue(true)} className="flex items-center">
              <BsCalendar2Event width={"18px"} height={"18px"} className=" mr-1" />
              {selectedTaskDueInfo?.day === "" ? "Due date" : selectedTaskDueInfo?.day}
            </button>
            {selectedTaskDueInfo.date && (
              <button type="button" onClick={(e) => setNoDate(e)}>
                <MdOutlineCancel className=" text-zinc-600" />
              </button>
            )}
          </div>

          {isModalOpenDue && (
            <>
              {/* <Modal onClose={closeModal} style={modalPosition} /> */}
              <div className="modal-overlay-popper" onClick={closeModalDue}>
                <div ref={setPopperElement} style={popperStyles} {...attributes.popper}>
                  <div
                    className="modal-popper taskScheduler"
                    // style={{ top: `${modalPositionDue.top}px`, left: `${modalPositionDue.left}px` }}
                  >
                    <hr />
                    <ul className="text-gray-900">
                      {/* // todo: change this class name to "scheduler-suggestions-item" */}
                      <li className="set-schedule__popper--option" onClick={() => setToday()}>
                        <div className="">
                          <TodayIcon color={"#25b84c"} />
                        </div>
                        <p className="set-new-task__schedule--name">Today</p>
                        <p className="set-new-task__schedule--weekday">{moment().format("ddd")}</p>
                      </li>
                      <li className="set-schedule__popper--option" onClick={() => setTomorrow()}>
                        <div className="">
                          <LiaSun className="scheduler-icon" fill={"#ff9a14"} />
                        </div>

                        <p className="set-new-task__schedule--name">Tomorrow</p>
                        <p className="set-new-task__schedule--weekday">{moment().add(1, "day").format("ddd")}</p>
                      </li>

                      {moment().add(1, "day").format("ddd") !== "Sat" && (
                        <li className="set-schedule__popper--option" onClick={() => setWeekend()}>
                          <div className="">
                            <LiaCouchSolid className="scheduler-icon" fill={"#5297ff"} />
                          </div>
                          <p className="set-new-task__schedule--name"> This weekend</p>
                          <p className="set-new-task__schedule--weekday">Sat</p>
                        </li>
                      )}

                      {moment().add(1, "day").format("ddd") === "Sat" && (
                        <li className="set-schedule__popper--option" onClick={() => setSunday()}>
                          <div className="">
                            <LiaCouchSolid className="scheduler-icon" fill={"#5297ff"} />
                          </div>
                          <p className="set-new-task__schedule--name"> Day After Tomorrow</p>
                          <p className="set-new-task__schedule--weekday">Sun</p>
                        </li>
                      )}

                      <li className="set-schedule__popper--option" onClick={() => setNext7days()}>
                        <div className="">
                          <MdNextWeek className="scheduler-icon" fill={"#a970ff"} />
                        </div>

                        <p className="set-new-task__schedule--name">Next Week</p>
                        <p className="set-new-task__schedule--weekday">{moment().add(7, "day").format("ddd MMM D ")}</p>
                      </li>
                      <li className="set-schedule__popper--option" onClick={() => setNoDate()}>
                        <div className="">
                          <MdOutlineDoNotDisturbOn className="scheduler-icon" color={"grey"} />
                        </div>

                        <p className="set-new-task__schedule--name">No Date</p>
                      </li>
                      <li onClick={(e) => e.stopPropagation()}>
                        <div className="calender-custom-Buttons">
                          <button type="button"></button>
                        </div>
                        <hr />
                        <ScroalableCalenderPicker taskSchedule={taskSchedule} handleDateSelected={handleDateSelected} />
                      </li>
                    </ul>
                    <hr />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {modalType === "quickCalender" && (
        <div>
          <div data-tooltip-id="my-tooltip" data-tooltip-content="Set Due Date" ref={setReferenceElement} title="Open Calender" onClick={(e) => setQuickCalenderOpen(true)} className=" flex gap-2 align-middle">
            {rescheduleViewContent}
          </div>

          {quickCalenderOpen && (
            <>
              {/* <Modal onClose={closeModal} style={modalPosition} /> */}
              <div className="modal-overlay-popper" onClick={(e) => setQuickCalenderOpen(false)}>
                <div ref={setPopperElement} style={popperStyles} {...attributes.popper}>
                  <div
                    className="modal-popper taskScheduler"
                    // style={{ top: `${modalPositionDue.top}px`, left: `${modalPositionDue.left}px` }}
                  >
                    <hr />
                    <ul className="text-gray-900 text-sm">
                      {/* // todo: change this class name to "scheduler-suggestions-item" */}
                      <li className="set-schedule__popper--option" onClick={() => setToday()}>
                        <div className="">
                          <TodayIcon color={"#25b84c"} />
                        </div>
                        <p className="set-new-task__schedule--name">Today</p>
                        <p className="set-new-task__schedule--weekday">{moment().format("ddd")}</p>
                      </li>
                      <li className="set-schedule__popper--option" onClick={() => setTomorrow()}>
                        <div className="">
                          <LiaSun className="scheduler-icon" fill={"#ff9a14"} />
                        </div>

                        <p className="set-new-task__schedule--name">Tomorrow</p>
                        <p className="set-new-task__schedule--weekday">{moment().add(1, "day").format("ddd")}</p>
                      </li>

                      {moment().add(1, "day").format("ddd") !== "Sat" && (
                        <li className="set-schedule__popper--option" onClick={() => setWeekend()}>
                          <div className="">
                            <LiaCouchSolid className="scheduler-icon" fill={"#5297ff"} />
                          </div>
                          <p className="set-new-task__schedule--name"> This weekend</p>
                          <p className="set-new-task__schedule--weekday">Sat</p>
                        </li>
                      )}

                      {moment().add(1, "day").format("ddd") === "Sat" && (
                        <li className="set-schedule__popper--option" onClick={() => setSunday()}>
                          <div className="">
                            <LiaCouchSolid className="scheduler-icon" fill={"#5297ff"} />
                          </div>
                          <p className="set-new-task__schedule--name"> Day After Tomorrow</p>
                          <p className="set-new-task__schedule--weekday">Sun</p>
                        </li>
                      )}

                      <li className="set-schedule__popper--option" onClick={() => setNext7days()}>
                        <div className="">
                          <MdNextWeek className="scheduler-icon" fill={"#a970ff"} />
                        </div>

                        <p className="set-new-task__schedule--name">Next Week</p>
                        <p className="set-new-task__schedule--weekday">{moment().add(7, "day").format("ddd MMM D ")}</p>
                      </li>
                      <li className="set-schedule__popper--option" onClick={() => setNoDate()}>
                        <div className="">
                          <MdOutlineDoNotDisturbOn className="scheduler-icon" color={"grey"} />
                        </div>

                        <p className="set-new-task__schedule--name">No Date</p>
                      </li>
                      <li onClick={(e) => e.stopPropagation()}>
                        <hr />
                        <ScroalableCalenderPicker taskSchedule={taskSchedule} handleDateSelected={handleDateSelected} />
                      </li>
                    </ul>
                    <hr />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default QuickActionForCompleteTask;



