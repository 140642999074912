import { createSlice } from "@reduxjs/toolkit";

const initialState = { todayTasks: [] };

const TodayTasksDataSlice = createSlice({
  name: "TodayTasksData",
  initialState,
  reducers: {
    AddTask: (state, action) => {
      state.todayTasks.push(action.payload);
    },
    InsertTasks: (state, action) => {
      state.todayTasks = action.payload;
    },
    UpdateTask: (state, action) => {
      const findObj = state.todayTasks.find((data) => data.id === action.payload.id);
      if (findObj) {
        const index = state.todayTasks.findIndex((dataa) => dataa.id === action.payload.id);
        if (index !== -1) {
          state.todayTasks[index] = { ...state.todayTasks[index], ...action.payload };
        }
      }
    },
    DeleteTaskStore: (state, action) => {
      state.todayTasks = state.todayTasks.filter((delData) => delData.id !== action.payload);
    },
    RescheduleOverdueTasks: (state, action) => {
      // Implement the logic for rescheduling overdue tasks
    },
    AddNewSubTaskToTask: (state, action) => {
      for (let i = 0; i < state.todayTasks.length; i++) {
        if (state.todayTasks[i].id === action.payload.taskId) {
          if (!state.todayTasks[i].subtasks) {
            state.todayTasks[i].subtasks = []; // Initialize subtasks if it doesn't exist
          }
          state.todayTasks[i].subtasks.push(action.payload);
          return;
        }
      }
    },
    subTaskAction: (state, action) => {
      for (let i = 0; i < state.todayTasks.length; i++) {
        if (state.todayTasks[i].id === action.payload.taskId) {
          for (let j = 0; j < state.todayTasks[i].subtasks.length; j++) {
            if (state.todayTasks[i].subtasks[j].id === action.payload.id) {
              state.todayTasks[i].subtasks[j] = action.payload;
              return;
            }
          }
        }
      }
    },
    DeleteSubTaskFromStore: (state, action) => {
      for (let i = 0; i < state.todayTasks.length; i++) {
        if (state.todayTasks[i].id === action.payload.taskId) {
          state.todayTasks[i].subtasks = state.todayTasks[i].subtasks.filter((subtask) => subtask.id !== action.payload.subTaskId);
          return;
        }
      }
    },
  },
});

export const { AddTask, InsertTasks, UpdateTask, DeleteTaskStore, RescheduleOverdueTasks, AddNewSubTaskToTask, subTaskAction, DeleteSubTaskFromStore } = TodayTasksDataSlice.actions;

export default TodayTasksDataSlice.reducer;

