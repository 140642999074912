import { UpdateTask } from "store/TodayTasksDataSlice";
import { useDispatch } from "react-redux";
import "./taskBarStyles.scss";
import { Link, useNavigate } from "react-router-dom";
import { TaskDate } from "DateHelpers/task-date";
import CompleteTaskCheckBox from "ActionItems/CompleteTaskCheckBox/CompleteTaskCheckBox";
import MoreTaskOptions from "ActionItems/MoreTaskActions/MoreTaskActions";

import { updateOnlyTaskDate } from "ApiService";
import { FaRotate } from 'react-icons/fa6'

import { TbSubtask } from 'react-icons/tb'
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const UpComeTaskBar = ({ task, tasks, setTasks, setEditId }) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const navigate = useNavigate();

  const dispatch = useDispatch()

  //--------------------------_API CALL FOR SCHEDULE-----------------------//

  async function handleQuickSchedule(dateObj) {
    let datePay = {
      taskId: task.id,
      newDate: dateObj.date,
    };
    try {
      const updateOnlyDateRes = await updateOnlyTaskDate(datePay);
      if (updateOnlyDateRes.data.status === 1) {
        enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "success" });
        //moving the update to store
        dispatch(UpdateTask(updateOnlyDateRes.data.data.task));
      } else {
        enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        className="py-2 w-full flex items-center group bg-white dark:bg-dark-500 dark:border-dark-100 border p-2 rounded-lg hover:border-blue-500">
        <>
          {/* <button className="text-xl text-black/60 dark:text-white/50 md:opacity-0 group-hover:opacity-100 transition-all cursor-grab active:cursor-grabbing" onPointerDown={(event) => dragControls.start(event)}>
            <DragIcon />
          </button> */}
          <div className="flex items-center gap-1 flex-grow w-full">
            <CompleteTaskCheckBox taskInfo={task} />
            <div onClick={() => navigate(`/app/task/${task?.id}`)} className={`task__details cursor-pointer ${task.completed ? "Task-completed" : "task-inprogress"}`}>
              <p className="task__text ">
                {/* <Link to={`/app/task/${task?.id}`} className="flex-grow"> */}
                {task?.title}
                {/* </Link> */}
              </p>
              {/* Show the description if only if the task was nopt ocmpleted */}
              {!task?.completed && <p className=" task__description text-gray-400 text-xs ">{task?.description}</p>}
              <div className="task__info mt-2 flex gap-2">
                {task?.subtasks?.length > 0 && <Link to={`/app/task/${task?.id}`} className="hover:text-black">
                  <div className="task__sub__info flex">
                    <TbSubtask />
                    <span className=" text-xs ">
                      <span className="sub_numerator">{task?.subtasks?.filter((unCom) => unCom.completed)?.length}</span>
                      <span className="">/</span>
                      <span className="sub_denominator">{task?.subtasks?.length}</span>
                    </span>
                  </div>
                </Link>}
                <div>{task.date && <TaskDate date={task?.date} />} </div>
                {task?.due?.is_recurring && <div className="task__recurring__info  ">
                  <span className="text-xs"><FaRotate fill={"#579dff"} /></span>
                </div>}
                <div className="task_label_info gap-1">
                  {/* {[1,2,3].map((data)=>(<LabelInfo/>))} */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full">
            <div className="flex">
              {/* <button className="mr-3 md:opacity-0 group-hover:opacity-100 transition-all display-action-icons" onClick={() => setEditId(task.id)}>
                <EditIcon className=" text-2xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" />
              </button> */}
              {/* <button className="mr-3 md:opacity-0 group-hover:opacity-100 transition-all display-action-icons">
                <EditIcon className=" text-2xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" />
                <QuickActionForCompleteTask modalType={"quickCalender"} getTaskDateData={handleQuickSchedule} />
              </button> */}
              {/* <button className="mr-3 md:opacity-0 group-hover:opacity-100 transition-all display-action-icons"> */}
              {/* <EditIcon className=" text-2xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" /> */}
              <MoreTaskOptions taskInfo={task} />
              {/* </button> */}
            </div>
            {/* <div className="flex items-center gap-1">
              <span className="text-black/60 dark:text-white/50 text-xs">Inbox</span>
              <GoInbox className="text-blue-500 text-xs" />
            </div> */}
          </div>
        </>
      </div>
    </>
  );
};

export default UpComeTaskBar;


