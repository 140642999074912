import React, { useEffect, useState, useRef } from "react";
// utils helpes
import { checkForTaskPriority, extractNumberFromString } from "utils/utils";
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
//redux
import { AddNewSubTaskToTask, UpdateTask } from "store/TodayTasksDataSlice";
// icons
import { FiInbox as GoInbox } from "react-icons/fi";
import { HiChevronUp as UpIcon, HiChevronDown as DownIcon } from "react-icons/hi";
import { BiDotsHorizontalRounded as MenuIcon } from "react-icons/bi";
import { MdOutlineClear as CloseIcon, MdOutlineSort as DescriptionIcon } from "react-icons/md";
import { HiOutlinePencil as EditIcon } from "react-icons/hi";
import { IoFlagOutline as FlagIcon, IoAlarmOutline as ClockIcon } from "react-icons/io5";
import { ImPriceTag as TagIcon, ImSpinner2 as SpinnerIcon } from "react-icons/im";
import { AiOutlinePlus as AddIcon } from "react-icons/ai";
// firebase
import { TaskbarSkeleton } from "layout/Skeleton";
import SubTaskBar from "components/SubTaskBar/SubTaskBar";
import TaskDatePicker from "ActionItems/TaskDatePicker/TaskDatePicker";
import PriorityPicker from "ActionItems/PriorityPicker/PriorityPicker";
import { useParams } from "react-router-dom";
import { addSubTaskForTask, completeTask, getAllSubTasksForTask, getTaskByIdApi, unDoCompletedTask, updatTaskForUser, updateOnlyTaskDate } from "ApiService";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTaskById } from "Hooks/useSelectorHooks";
import "./completeTaskView.scss";
import QuickActionForCompleteTask from "ActionItems/QuickActionForCompleteTask/QuickActionForCompleteTask";
import TaskLabelPicker from "ActionItems/TaskLabelPicker/TaskLabelPicker";
import * as chrono from "chrono-node";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Editor, EditorState, CompositeDecorator, getDefaultKeyBinding } from 'draft-js';
import 'draft-js/dist/Draft.css';
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';

const CompleteTaskView = () => {
  const MAX_LENGTH = 200; // Adjust the character limit as needed
  const { enqueueSnackbarWithClose } = SnackbarWithClose();

  // editor
  //**this state holds the key word to highlet in the editoe */
  const [extractedDateTerm, setExtractedDateTerm] = useState("");
  const editorRef = useRef(null);
  //** this is the editor draft state initially empty */
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { taskID } = useParams();

  const getTaskData = useTaskById(taskID);
  const [edit, setEdit] = useState(false);
  const [subTasksData, setSubTasksData] = useState([]);
  const [showAddSubTaskForm, setShowAddSubTaskForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [subTaskFormData, setSubTaskFormData] = useState({
    task: "",
    description: "",
  });

  const [taskInfo, setTaskInfo] = useState(getTaskData);
  console.log(taskInfo,'taskInfo');
  const getTaskByIdApiMethod = async () => {
    setIsLoading(true);
    const getTaskByIdApiResponse = await getTaskByIdApi(taskID);
    if (getTaskByIdApiResponse.data.status === 1) {
      setTaskInfo(getTaskByIdApiResponse.data.task);
      setIsLoading(false);
    } else {
      // enqueueSnackbarWithClose(getTaskByIdApiResponse.data.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  /**
   * get sub tasks for task
   */

  const getAllSubTasksForTaskApi = async () => {
    setIsLoading(true);
    try {
      const getAllSubTasksForTaskApiRes = await getAllSubTasksForTask(taskID).finally(() => {
        setIsLoading(false);
      });
      if (getAllSubTasksForTaskApiRes.data.status === 1) {
        setSubTasksData(getAllSubTasksForTaskApiRes.data.data.subtasks)
      } else {
        setSubTasksData([])
        enqueueSnackbarWithClose(getAllSubTasksForTaskApiRes.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }

  };

  // useEffect(() => {
  //   getTaskByIdApiMethod();
  // }, []);
  const [taskSchedule, setTaskSchedule] = useState({ day: "", date: null });

  const [taskPriority, setTaskPriority] = useState(4);

  //****************Sub TASK VARIABLE */

  const [subTaskSchedule, setSubTaskSchedule] = useState({ day: "", date: null });

  const [subTaskPriority, setSubTaskPriority] = useState(4);

  useEffect(() => {
    // setTaskSchedule()
    if (taskInfo?.date) {
      setTaskSchedule(getDayAndDateObjFromISO(taskInfo?.date));
    }
    setTaskPriority(extractNumberFromString(taskInfo?.priority));

    //**************API CAL */
    getAllSubTasksForTaskApi()
  }, []);
  //** functions for keyword and hightltter START */
  useEffect(() => {
    // setEditorState(EditorState.createEmpty(keywordDecorator));
    const contentState = editorState.getCurrentContent();
    const newEditorState = EditorState.createWithContent(contentState, keywordDecorator);
    const currentSelection = editorState.getSelection();
    const stateWithContentAndSelection = EditorState.forceSelection(newEditorState, currentSelection);
    setEditorState(stateWithContentAndSelection);
  }, [extractedDateTerm]);

  const modifyTask = async (e) => {
    e.preventDefault();
    if (taskInfo.title && taskInfo.title.trim().length > 0) {
      setFormLoading(true);
      const editPayload = {
        title: taskInfo.title,
        description: taskInfo.description,
        date: taskSchedule.date ? taskSchedule.date : null,
        priority: `priority${taskPriority}`,
        reminders: [],
        labels: taskInfo?.labels,
        category: "",
        isFavorite: false,
        taskEditId: taskInfo?.id,
        projectId: taskInfo?.projectId


      };
      const updateTaskAPIResponse = await updatTaskForUser(editPayload).finally(() => {
        setFormLoading(false);
      });;
      if (updateTaskAPIResponse.data.status === 1) {
        enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: "success" });
        dispatch(UpdateTask(updateTaskAPIResponse.data.data));
        // navigate(-1);
        setEdit(false);
      } else {
        enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: "error" });
      }
    } else {
      enqueueSnackbarWithClose("Task title cannot be empty", {
        variant: "error"
      });
    }
  };

  //--------------------------_API CALL FOR  complete task-----------------------//

  const taskUndoRedoMethod = async (e) => {
    // setShowTick(true);
    try {
      const completeTaskApi = await completeTask(taskInfo.id);
      if (completeTaskApi.data.status === 1) {
        enqueueSnackbarWithClose(completeTaskApi.data.message, { variant: "success" });
        //***********Update the data to redux */
        // setTaskInfo({...taskInfo,taskInfo.completed=true})
        setTaskInfo({ ...taskInfo, completed: true });
        dispatch(UpdateTask(completeTaskApi.data.data));
      } else {
        enqueueSnackbarWithClose(completeTaskApi.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message || error.message, { variant: "error" });
    }

  };

  //--------------------------_API CALL FOR ucomplete the completed task-----------------------//

  const unDoCompletedTaskMethod = async (e) => {
    // setShowTick(true);
    try {
      const unDoCompletedTaskRes = await unDoCompletedTask(taskInfo.id);
      if (unDoCompletedTaskRes.data.status === 1) {
        enqueueSnackbarWithClose(unDoCompletedTaskRes.data.message, { variant: "success" });
        //***********Update the data to redux */
        setTaskInfo({ ...taskInfo, completed: false });

        dispatch(UpdateTask(unDoCompletedTaskRes.data.data));
      } else {
        enqueueSnackbarWithClose(unDoCompletedTaskRes.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message || error.message, { variant: "error" });
    }

  };
  //--------------------------_API CALL FOR PRIORIT-----------------------//

  const handlePriorityChange = async (priorValue) => {
    setTaskPriority(priorValue);
    const editPayload = {

      "title": taskInfo.title,
      "description": taskInfo.description,
      "date": taskInfo.date ? taskInfo.date : null,
      "priority": `priority${priorValue}`,
      "reminders": [],
      "labels": taskInfo?.labels,
      "category": "",
      "isFavorite": false,
      "taskEditId": taskInfo?.id,
      projectId: taskInfo?.projectId
    }
    const updateTaskAPIResponse = await updatTaskForUser(editPayload)
    try {
      if (updateTaskAPIResponse.data.status === 1) {
        enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: 'success' })
        //**************update to redux */
        dispatch(UpdateTask(updateTaskAPIResponse.data.data))
        //**        UPDATE TO LOCAL STATE */
        setTaskInfo({ ...taskInfo, priority: `priority${priorValue}` })
      } else {
        enqueueSnackbarWithClose(updateTaskAPIResponse?.data?.message, { variant: 'error' })
      }
    } catch (error) {
      console.log(error);
    }

  }

  //--------------------------_API CALL FOR SCHEDULE-----------------------//

  async function handleQuickSchedule(dateValue) {

    setTaskSchedule(dateValue)

    let datePay = {
      taskId: taskInfo.id,
      newDate: dateValue.date ? dateValue.date : null
    }
    const updateOnlyDateRes = await updateOnlyTaskDate(datePay)
    if (updateOnlyDateRes.data.status === 1) {
      enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: 'success' })
      //moving the update to store
      dispatch(UpdateTask(updateOnlyDateRes.data.data.task))
      //**        UPDATE TO LOCAL STATE */
      // setTaskInfo({ ...taskInfo, date:  `priority${priorValue}` })
    } else {
      enqueueSnackbarWithClose(updateOnlyDateRes.data.message, { variant: 'error' })
    }
  }
  //*******************ADD SUB TASK API  */

  const addSubTask = async (e) => {
    if (subTaskFormData.task && subTaskFormData.task.trim().length > 0) {
      setFormLoading(true);
      // e.preventDefault()
      const subTaskpayload = {
        title: subTaskFormData.task,
        description: subTaskFormData.description,
        dueDate: subTaskSchedule.date ? subTaskSchedule.date : null,
        priority: `priority${subTaskPriority}`,
        reminders: [],
        labels: [],
        category: "",
        isFavorite: false,
      };
      try {
        const addSubTaskForTaskRes = await addSubTaskForTask(taskInfo?.id, subTaskpayload).finally(() => {
          setFormLoading(false);
        });
        if (addSubTaskForTaskRes.data.status === 1) {
          /**
           * Below line is used to store the return object to local state for client view
           */
          setSubTasksData((pre) => [...pre, addSubTaskForTaskRes.data.data])
          /**
          * Below line is used to store the return object to Redux store state for global state of app
          */
          dispatch(AddNewSubTaskToTask(addSubTaskForTaskRes.data.data))
          enqueueSnackbarWithClose(addSubTaskForTaskRes.data.message, { variant: 'success' })
          //*************clearing the sub task form vallues */
          setSubTaskFormData({
            task: "",
            description: "",
          })

          setSubTaskSchedule({ day: "", date: null });

          setSubTaskPriority(4);

          setEditorState(EditorState.createEmpty())

        } else {
          enqueueSnackbarWithClose(addSubTaskForTaskRes.data.message, { variant: 'error' })
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      enqueueSnackbarWithClose("Please enter sub task title", { variant: 'error' })
    }
  }
  //CHRONO NODE DATE PARSE 
  const dateparse = (dateString) => {
    const currentDate = new Date();
    const parsedDate = chrono.parseDate(dateString, currentDate, { forwardDate: true });
    let res = chrono.parse(dateString)[0];
    if (res) {
      setExtractedDateTerm(res?.text);
    } else {
      setExtractedDateTerm("");
    }
    if (parsedDate) {
      const parsedDateTime = parsedDate.getTime(); // Get the timestamp of the parsed date
      const currentDateTime = currentDate.getTime(); // Get the timestamp of the current date
      if (parsedDateTime >= currentDateTime) {
        // Date is either current or in the future
        return parsedDate;
      } else {
        setExtractedDateTerm("");
        // Date is in the past
        return null; // Or handle it as needed
      }
    } else {
      setExtractedDateTerm("");
      return null; // No valid date parsed
    }
  };

  const handleSubTaskTitleChange = (e) => {
    let taskTitle = e.target.value;

    setSubTaskFormData({ ...subTaskFormData, task: e.target.value });

    let parsedDate = dateparse(taskTitle);
    let reqDate = getDayAndDateObjFromISO(parsedDate);

    if (parsedDate) {
      setSubTaskSchedule(reqDate);
    } else {
      // checkIsTaskingAddingForToday();
      setSubTaskSchedule({ day: "", date: null })
    }
    if (taskTitle) {
      const priorityOfTask = checkForTaskPriority(taskTitle);
      setSubTaskPriority(priorityOfTask)
    }
    // const recurringWords = matchRecurringWords(taskTitle);
  };

  // Example usage:
  function matchRecurringWords(sentence) {
    // Define a regex pattern for recurring phrases
    const regexPattern = /(\bmonthly\b|\bevery\sweek\b|\bevery\s(?:sunday|monday|tuesday|wednesday|thursday|friday|saturday)\b)/gi;

    // Use the regex pattern to find all recurring phrases in the sentence
    const matches = sentence.match(regexPattern);

    return matches || []; // Return an array of matched recurring phrases or an empty array
  }
  const onDragEnd = (result) => {
    if (!result.destination) return; // Task was not dropped in a valid location

    const reorderedTasks = [...subTasksData];
    const [movedTask] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, movedTask);

    // setTasks(reorderedTasks);
    setSubTasksData(reorderedTasks);
  };
  const handleKeyCommand = (command) => {
    if (command === "submit-form") {
      // Implement the logic to submit the form here
      addSubTask();
      return "handled";
    }
    return "not-handled";
  };

  const customKeyBinding = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      return "submit-form";
    }
    return getDefaultKeyBinding(e);
  };
  const keywordDecorator = new CompositeDecorator([
    {
      strategy: findKeywordStrategy(extractedDateTerm),
      component: KeywordSpan,
    },
  ]);

  const handleEditorChange = (newEditorState) => {

    const contentState = newEditorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    setSubTaskFormData({ ...subTaskFormData, task: plainText });
    //updating this to state variable
    setEditorState(newEditorState);
    let parsedDate = dateparse(plainText);
    let reqDate = getDayAndDateObjFromISO(parsedDate);
    if (parsedDate) {
      setSubTaskSchedule(reqDate);
    } else {
      // checkIsTaskingAddingForToday();
      setSubTaskSchedule({ day: "", date: null })
    }
    if (plainText) {
      const priorityOfTask = checkForTaskPriority(plainText);
      setSubTaskPriority(priorityOfTask)
    }
    // 
  };

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return "handled";
    }
  };

  const _handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };

  return (
    <div className="h-screen w-screen fixed top-0 left-0 z-50 pt-10 sm:p-10">
      <div className="backdrop-blur h-full w-full absolute top-0 left-0 bg-black/50 z-10 cursor-pointer" onClick={() => navigate(-1)} />
      <div className="h-full w-full max-w-5xl overflow-y-scroll mx-auto bg-white dark:bg-dark-400 overflow-hidden relative rounded-xl shadow-lg z-50">
        <div className="flex w-full static top-0 left-0 z-20 items-center justify-between  border-b dark:border-dark-100 bg-white dark:bg-dark-500  px-4 py-1">
          <div className="flex-grow">
            <div className="flex items-center text-sm gap-2">
              {taskInfo?.projectId?<span># {taskInfo.projectName}</span>:<><GoInbox className="text-base text-blue-500 " /> Inbox</>}
            </div>
            
          </div>
          <div className="flex items-center">
            <button type="button" className="hidden aspect-square p-2 rounded transition-all hover:bg-black/10 text-black/50 dark:text-white/80 disabled:opacity-50" disabled>
              <UpIcon className="text-3xl" />
            </button>
            <button type="button" className="hidden aspect-square p-2 rounded transition-all hover:bg-black/10 text-black/50 dark:text-white/80 disabled:opacity-50" disabled>
              <DownIcon className="text-3xl" />
            </button>
            <button type="button" className="hidden aspect-square p-2 rounded transition-all hover:bg-black/10 text-black/50 dark:text-white/80 disabled:opacity-50">
              <MenuIcon className="text-3xl" />
            </button>
            <button type="button" className="aspect-square block p-2 rounded transition-all hover:bg-black/10 text-black/50 dark:text-white/80 disabled:opacity-50" onClick={() => navigate(-1)}>
              <CloseIcon className="text-2xl" />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-9">
            {isLoading ? (
              <div className="w-full h-full flex items-center justify-center">
                <SpinnerIcon className="text-primary animate-spin text-4xl" />
              </div>
            ) : (
              <>
                {taskInfo ? (
                  <div className="p-5">
                    {edit ? (
                      <form onSubmit={(e) => modifyTask(e)}>
                        <div className="flex flex-col gap-2 border dark:border-dark-100 rounded-lg p-3">
                          <input
                            type="text"
                            className="text-base font-medium outline-none disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                            placeholder="Task Name"
                            value={taskInfo.title}
                            disabled={formLoading}
                            autoFocus
                            onChange={({ target }) =>
                              setTaskInfo({
                                ...taskInfo,
                                title: target.value,
                              })
                            }
                          />
                          <textarea
                            placeholder="Description"
                            className="text-sm outline-none focus:rounded focus:p-2 transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                            disabled={formLoading}
                            value={taskInfo.description}
                            onChange={({ target }) =>
                              setTaskInfo({
                                ...taskInfo,
                                description: target.value,
                              })
                            }
                          />
                          <div className="flex w-full items-center justify-between">
                            <div className="flex w-full items-center gap-2">
                              {/* <TaskDatePicker getTaskDateData={setTaskSchedule} taskSchedule={taskSchedule} /> */}
                              {/* <PriorityPicker setTaskPriority={setTaskPriority} taskPriority={taskPriority} /> */}
                            </div>
                            {/* <div className="flex items-center gap-2">
                          <button
                            type="button"
                            className="text-xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                          >
                            <TagIcon />
                          </button>
                          <button
                            type="button"
                            className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                          >
                            <FlagIcon />
                          </button>
                          <button
                            type="button"
                            className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                          >
                            <ClockIcon />
                          </button>
                        </div> */}
                          </div>
                        </div>
                        <div className="flex w-full p-2 items-center justify-end gap-2">
                          <button
                            type="button"
                            className="bg-gray-100 dark:bg-dark-100 dark:text-white dark:hover:bg-white/20 py-2 px-4 text-sm font-bold text-black/70 rounded hover:bg-gray-200"
                            onClick={() => {
                              setEdit(false);
                            }}>
                            Cancel
                          </button>
                          <button type="submit" className="bg-primary py-2 px-4 text-sm font-bold text-white/90 dark:text-gray-300 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40" disabled={!taskInfo.title || formLoading}>
                            {formLoading ? (
                              <span className="flex items-center gap-2">
                                <SpinnerIcon className="animate-spin" />
                                <span>Loading...</span>
                              </span>
                            ) : (
                              "Edit Task"
                            )}
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div className="flex gap-4 group">
                        {/* <button className="text-lg pt-2 text-black/50 dark:text-white/50 h-full flex flex-col items-center">
                      <CircleIcon />
                    </button> */}
                        {/* <CompleteTaskCheckBox taskInfo={taskInfo} /> */}
                        <button
                          type="button"

                          className="text-xl text-black/50 dark:text-white/40 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"
                          onClick={(e) => { taskInfo?.completed ? unDoCompletedTaskMethod(e) : taskUndoRedoMethod(e) }}
                        >
                          {taskInfo?.completed ? <FaRegCheckCircle className={`mr-1 ${taskInfo?.priority}`} /> : <FaRegCircle className={`mr-1 ${taskInfo?.priority}`} />}
                        </button>
                        <div
                          className="h-full flex-grow"
                          onClick={(e) => {
                            taskInfo.completed ? e.preventDefault() : setEdit(true);
                          }}>
                          <div className={`text-2xl font-bold ${taskInfo.completed ? "task-completed-task-modal" : ""}`}>
                            <span>{taskInfo?.title}</span>
                          </div>
                          <div className="py-3 flex items-center gap-2 text-black/70  dark:text-white/50">
                            <DescriptionIcon />
                            <p className="font-light">{taskInfo?.description}</p>
                          </div>
                        </div>
                        {!taskInfo.completed && (
                          <button className="text-lg pt-2 text-black/50 hover:text-primary dark:text-white/50 h-full flex flex-col items-center" onClick={() => setEdit(true)}>
                            <EditIcon />
                          </button>
                        )}
                      </div>
                    )}
                    <span className="">
                      <h2>Sub Tasks</h2>
                      {/* tasks list */}
                      {/* <Reorder.Group as="ul" values={subTasksData} axis="y" onReorder={setSubTasksData} dragElastic={0.8} variants={variants.container} initial="hidden" animate="show" className="flex flex-col ">
                        {subTasksData.length > 0 ? subTasksData.map((task, i) =>
                          <SubTaskBar key={task.id} subTask={task} subTasks={subTasksData} setSubTasks={setSubTasksData} />) : <>{isLoading && <TaskbarSkeleton count={10} />}</>}
                      </Reorder.Group> */}
                      {/* SUB TAKS CONTENT VIEW */}
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="task-list">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {subTasksData.map((task, index) => (
                                <Draggable key={task?.id?.toString()} draggableId={task?.id?.toString()} index={index}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {subTasksData.length > 0 ?
                                        <SubTaskBar key={task.id} subTask={task} subTasks={subTasksData} setSubTasks={setSubTasksData} /> : <>{isLoading && <TaskbarSkeleton count={10} />}</>
                                      }
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      {/* SUB TAKS CONTENT VIEW */}

                      {showAddSubTaskForm ? (
                        <form
                          // onSubmit={(e) => addSubTask(e)}
                          className="mt-3">
                          <div className="flex flex-col gap-2 border dark:border-white/20 rounded-lg p-3 ml-7">
                            <Editor editorState={editorState} onChange={handleEditorChange} handleKeyCommand={handleKeyCommand} keyBindingFn={customKeyBinding} placeholder="Type your text here..." ref={editorRef} handleBeforeInput={_handleBeforeInput} handlePastedText={_handlePastedText} />

                            {/* <input
                              type="text"
                              className="text-base font-medium outline-none disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                              placeholder="Sub Task Title"
                              value={subTaskFormData.task}
                              disabled={formLoading}
                              autoFocus
                              onChange={(e) => handleSubTaskTitleChange(e)}
                            /> */}
                            <textarea
                              placeholder="Description"
                              className="text-sm outline-none h-auto dark:border-white/20  focus:rounded focus:p-2 transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                              disabled={formLoading}
                              value={subTaskFormData.description}
                              onChange={({ target }) =>
                                setSubTaskFormData({
                                  ...subTaskFormData,
                                  description: target.value,
                                })
                              }
                            />
                            <div className="flex w-full items-center justify-between">
                              <div className="flex w-full items-center gap-2">

                                <TaskDatePicker getTaskDateData={setSubTaskSchedule} taskSchedule={subTaskSchedule} />
                                <PriorityPicker setTaskPriority={setSubTaskPriority} taskPriority={subTaskPriority} />
                              </div>
                              <div className="items-center gap-2 hidden">
                                <button type="button" className="text-xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20">
                                  <TagIcon />
                                </button>
                                <button type="button" className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20">
                                  <FlagIcon />
                                </button>
                                <button type="button" className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20">
                                  <ClockIcon />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex w-full p-2 items-center justify-end gap-2">
                            <button
                              type="button"
                              className="bg-gray-100 dark:bg-dark-100 dark:text-white dark:hover:bg-white/20 py-2 px-4 text-sm font-bold text-black/70 rounded hover:bg-gray-200"
                              onClick={() => {
                                setShowAddSubTaskForm(false);
                              }}>
                              Cancel
                            </button>
                            <button type="button" onClick={(e) => addSubTask()} className="bg-primary py-2 px-4 text-sm font-bold text-white/90 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40" disabled={!subTaskFormData.task || formLoading}>
                              {formLoading ? (
                                <span className="flex items-center gap-2">
                                  <SpinnerIcon className="animate-spin" />
                                  <span>Loading...</span>
                                </span>
                              ) : (
                                "Add Sub-Task"
                              )}
                            </button>
                          </div>
                        </form>
                      ) : (
                        <div className="py-2 ml-5">
                          <button disabled={taskInfo?.completed} type="button" className="inline-flex items-center gap-2 text-sm p-2 rounded transition-all hover:bg-black/10 text-black/80   dark:hover:bg-dark-100 dark:text-white/50" onClick={() => setShowAddSubTaskForm(true)}>
                            <AddIcon className="text-sm font-medium" />
                            Add sub-task
                          </button>
                        </div>
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="h-full w-full flex items-center justify-center">
                    <div className="flex flex-col gap-4">
                      <img src="https://cdn-icons-png.flaticon.com/512/564/564619.png" alt="" className="h-52" />
                      <h2 className="text-center w-full font-medium text-xl">Unable To Find this task.</h2>
                      <button className="px-4 py-2 rounded-full bg-primary hover:bg-red-600 text-white font-medium" onClick={() => window.location.reload()}>
                        Refresh This Page
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {!taskInfo?.completed && taskInfo && <div className="col-span-3 bg-slate-50">
            <div className="p-5 ">
              <div className=" flex items-center justify-between mb-2">
                <h3 className=" text-lg text-slate-500">Due Date</h3>
              </div>
              <div className="flex items-center">
                <QuickActionForCompleteTask modalType={"dueDate"} getTaskDateData={handleQuickSchedule} taskSchedule={taskSchedule} />
              </div>
            </div>
            <hr />
            <div className="p-5">
              <div className=" flex items-center justify-between mb-2">
                <h3 className=" text-lg text-slate-500">Priority</h3>
              </div>
              <div>
                <QuickActionForCompleteTask modalType={"priority"} setTaskPriority={handlePriorityChange} taskPriority={taskPriority} />
              </div>
            </div>
            <hr />
            <div className="py-2 px-5">
              <div className=" flex items-center justify-between w-webkit-fill-available hover:bg-zinc-100 p-1 cursor-pointer">
                <span className=" text-lg text-slate-500">Labels</span>
                {/* <span className=" text-lg text-slate-500"><IoMdAdd /></span> */}
              </div>
              <div>
                <TaskLabelPicker taskInfo={taskInfo} />
                {/* <QuickActionForCompleteTask modalType={"priority"} setTaskPriority={handlePriorityChange} taskPriority={taskPriority} /> */}
              </div>
            </div>
            {/* <hr />
            <div className="p-5">
              <div className="">
                <h3>Labels</h3>
                <div className="flex flex-wrap">
                  <div className="bg-gray-200 p-2 mb-2 mr-2 rounded-md">
                    Tag1
                    <button className="ml-2 text-lg " onClick={() => { }}>
                      &times;
                    </button>
                  </div>
                  <div className="bg-gray-200 p-2 mb-2 mr-2 rounded-md">
                    Tag2
                    <button className="ml-2 text-lg" onClick={() => { }}>
                      &times;
                    </button>
                  </div>
                  <div className="bg-gray-200 p-2 mb-2 mr-3 rounded-md">
                    Tag3
                    <button className="ml-2 text-lg" onClick={() => { }}>
                      &times;
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr /> */}
          </div>}
        </div>
      </div>
    </div>
  );
};
// Define a function to find the keyword's strategy
function findKeywordStrategy(keyword) {
  return function (contentBlock, callback) {
    const text = contentBlock.getText();
    const startIndex = text.indexOf(keyword);
    if (startIndex !== -1) {
      callback(startIndex, startIndex + keyword.length);
    }
  };
}

// Custom span component for highlighting the keyword
const KeywordSpan = (props) => {
  return <span style={{ backgroundColor: "#d5d5d5", padding: "0 2px" }}>{props.children}</span>;
};

export default CompleteTaskView;


