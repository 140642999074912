import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { enqueueSnackbar } from 'notistack';
import { createLabelAPI, updatTaskForUser } from 'ApiService';
import { useDispatch } from "react-redux";
import { AddLabel } from 'store/LabelsDataSlice';
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const TaskLabelPicker = ({ taskInfo }) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch();
  const userAddedLabels = useSelector((state) => state.labels.labels.map(mapD => ({ "value": mapD.id, "label": mapD.labelName })))
  const [selectedOption, setSelectedOption] = useState([]);

  const handleCreateOption = async (inputValue) => {
    // Create a new option object with the user's input
    let prevSelectedLabelOptions = [...selectedOption]
    
    try {
      const data = {
        labelName: inputValue,
        colorCode: '',
        colorName: ''
      }
      const response = await createLabelAPI(data);
      if (response.data.status === 1) {
        dispatch(AddLabel(response.data.data));
        let newCreatedLabelOption = { value: response.data.data.id, label: response.data.data.labelName };
        prevSelectedLabelOptions.push(newCreatedLabelOption);
        setSelectedOption((pre) => [...pre, newCreatedLabelOption]);
        modifyTask(prevSelectedLabelOptions);
        enqueueSnackbarWithClose(response.data.message, { variant: "success" });

        // closeModal();
      } else {
        enqueueSnackbarWithClose(response.data.message, { variant: "error" });
      }

    } catch (error) {
      console.error("An error occurred while creating the label:", error);
    }
  };
  const modifyTask = async (labelsOptions) => {
    // e.preventDefault()

    const editPayload = {
      "title": taskInfo.title,
      "description": taskInfo.description,
      "date": taskInfo.date ? taskInfo.date : null,
      "priority": taskInfo.priority,
      "reminders": [],
      "labels": labelsOptions?.map(data => data?.label),
      "category": "",
      "isFavorite": false,
      "taskEditId": taskInfo?.id,
      projectId: taskInfo?.projectId
    }
    const updateTaskAPIResponse = await updatTaskForUser(editPayload)
    if (updateTaskAPIResponse.data.status === 1) {
      enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: 'success' })
      // dispatch(UpdateTask(updateTaskAPIResponse.data.data))
      // setEditId('');
    } else {
      enqueueSnackbarWithClose(updateTaskAPIResponse.data.message, { variant: 'error' })
    }
  }
  const handleLabelSelectForTask = (optVal) => {
    setSelectedOption(optVal)
    /************API CALL */
    modifyTask(optVal)
  }
  useEffect(() => {
    setSelectedOption(userAddedLabels?.filter(item => taskInfo?.labels.includes(item?.label)))
  }, [taskInfo])
  return (
    <>
      <div className='task-label-picker-select'>
        {/* <CreatableSelect isMulti options={colourOptions} /> */}
        <CreatableSelect
          options={userAddedLabels}
          value={selectedOption}
          onChange={(selectedOption) => handleLabelSelectForTask(selectedOption)}
          onCreateOption={handleCreateOption}
          isSearchable
          isMulti
        // getOptionLabel={option => option.id}
        />
      </div>
    </>
  )
}


export default TaskLabelPicker