import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_WEB_BACKEND_URL,
});

/*
API Name: Sign Up
Method: Its a POST method
Integratedby: Rasheeda 
 */

export const signUp = async (userData) => {
  try {
    const response = await axiosInstance.post("/register", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
/*
API Name: Login
Method: Its a POST method
Integratedby: Rasheeda 
 */

export const login = async (userData) => {
  try {
    const response = await axiosInstance.post("/login", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/*
API Name: Refresh Token
Method: Its a POST method
Integratedby: Rasheeda 
*/

export const refreshToken = async (userData) => {
  try {
    const response = await axiosInstance.post("/refresh-token", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Export your service functions
export default {
  signUp,
  login,
  refreshToken,
};

