import React, { useState, useEffect } from "react";
import { usePopper } from "react-popper";
import { TodayIcon } from 'DateHelpers/today-icon';
import moment from 'moment';
import { LiaCouchSolid, LiaSun, LiaCalendarAltSolid } from 'react-icons/lia';
import { BsCalendar2Event } from 'react-icons/bs';
import { MdOutlineDoNotDisturbOn } from 'react-icons/md'
import './taskschedule.scss'
import { MdOutlineCancel,MdNextWeek } from 'react-icons/md';
import MobileCalendar from 'react-scroll-calendar';
import "react-scroll-calendar/build/react-scroll-calendar.css";
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
import ScroalableCalenderPicker from "ActionItems/ScroalableCalenderPicker/ScroalableCalenderPicker";
import { Tooltip } from 'react-tooltip'

const TaskDatePicker = ({ getTaskDateData, taskSchedule }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "right"
  });

  const popperStyles = {
    ...styles.popper
    // display: isShown ? "block" : "none"
  };

  const [selectedDay, setSelectedDay] = useState(null);

  var today = new Date();
  var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const [selectedTaskDueInfo, setSelectedTaskDueInfo] = useState(taskSchedule)
  const openModal = (event) => {
    const buttonRect = event.target.getBoundingClientRect();
    const top = buttonRect.top + window.scrollY;
    const left = buttonRect.left + buttonRect.width; // Position modal to the right of the button
    // const top = buttonRect.top + window.scrollY - 300; // Adjust this value to control the distance from the top
    // const left = buttonRect.left + buttonRect.width; // Position modal to the right of the button
    setModalPosition({ top, left });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setSelectedTaskDueInfo(taskSchedule)
  }, [taskSchedule])



  const setToday = () => {
    setSelectedTaskDueInfo({ day: "Today", date: moment().format("YYYY-MM-DD") });
    getTaskDateData({ day: "Today", date: moment().format("YYYY-MM-DD") })
  };
  const setTomorrow = () => {
    setSelectedTaskDueInfo({ day: "Tomorrow", date: moment().add(1, "day").format("YYYY-MM-DD") });
    getTaskDateData({ day: "Tomorrow", date: moment().add(1, "day").format("YYYY-MM-DD") });
  };
  const setWeekend = () => {
    setSelectedTaskDueInfo({ day: "Weekend", date: moment().day("Saturday").format("YYYY-MM-DD") });
    getTaskDateData({ day: "Weekend", date: moment().day("Saturday").format("YYYY-MM-DD") });
  };
  const setNext7days = () => {
    setSelectedTaskDueInfo({ day: "Next week", date: moment().add(7, "days").format("YYYY-MM-DD") });
    getTaskDateData({ day: "Next week", date: moment().add(7, "days").format("YYYY-MM-DD") });
  };

  const setSunday = () => {
    setSelectedTaskDueInfo({ day: "Sunday", date: moment().day("Sunday").format("YYYY-MM-DD") });
    getTaskDateData({ day: "Sunday", date: moment().day("Sunday").format("YYYY-MM-DD") });

  }
  const setNoDate = () => {
    setSelectedTaskDueInfo({ day: "", date: "" });
    getTaskDateData({ day: "", date: "" });
  };



  const getDateStyle = () => {
    if (selectedTaskDueInfo?.day === "Today") {
      let day = "date__today";
      return day;
    }
    if (selectedTaskDueInfo?.day === "Tomorrow") {
      let day = "date__tomorrow";
      return day;
    }
    if (selectedTaskDueInfo?.day === "Weekend") {
      let day = "date__weekend";
      return day;
    }
    if (selectedTaskDueInfo?.day === "Next week") {
      let day = "date__next-week";
      return day;
    }
    if (selectedTaskDueInfo?.day === 'Sunday') {
      let day = 'date__sunday'
      return day;
    }
  };

  /**
   * 
   * @param {*} date will pass in format 2023-10-02  , returns {day: 'Saturday', date: '2023-10-02'}
   */
  function handleDateSelected(date) {
    let dateObjectToSet = getDayAndDateObjFromISO(date)

    setSelectedTaskDueInfo(dateObjectToSet);
    getTaskDateData(dateObjectToSet);
    setIsShown(false)
  }

  return (
    <>
      <Tooltip id="my-tooltip" place='top' noArrow offset={0} />
      <div data-tooltip-id="my-tooltip" data-tooltip-content="Set due date" ref={setReferenceElement} className={`border cursor-pointer p-1 rounded-md flex items-center gap-2 ${getDateStyle()}`} >
        <button type='button' onClick={(e) => setIsShown((x) => !x)} className='flex items-center'>
          <BsCalendar2Event width={"18px"} height={"18px"} className=' mr-1' />
          {selectedTaskDueInfo?.day === "" ? "Due date" : selectedTaskDueInfo?.day}
        </button>
        {selectedTaskDueInfo?.date && <button type='button' onClick={(e) => setNoDate(e)}><MdOutlineCancel className=' text-zinc-600' /></button>}

      </div>

      {isShown && (
        <div className="modal-overlay-popper" onClick={() => setIsShown(false)} >
          <div ref={setPopperElement} style={popperStyles} {...attributes.popper}
          >
            <div
              className="modal-popper taskScheduler"
            >
              <hr />
              <ul>
                {/* // todo: change this class name to "scheduler-suggestions-item" */}
                <li className="set-schedule__popper--option" onClick={() => setToday()}>
                  <div className="">
                    <TodayIcon color={"#25b84c"} />
                  </div>
                  <p className="set-new-task__schedule--name">Today</p>
                  <p className="set-new-task__schedule--weekday">{moment().format("ddd")}</p>
                </li>
                <li className="set-schedule__popper--option" onClick={() => setTomorrow()}>
                  <div className="">
                    <LiaSun className='scheduler-icon' fill={"#ff9a14"} />
                  </div>

                  <p className="set-new-task__schedule--name">Tomorrow</p>
                  <p className="set-new-task__schedule--weekday">{moment().add(1, "day").format("ddd")}</p>
                </li>
                {moment().add(1, "day").format("ddd") !== 'Sat' &&
                  <li className="set-schedule__popper--option" onClick={() => setWeekend()}>
                    <div className="">
                      <LiaCouchSolid className='scheduler-icon' fill={"#5297ff"} />
                    </div>
                    <p className="set-new-task__schedule--name"> This weekend</p>
                    <p className="set-new-task__schedule--weekday">Sat</p>
                  </li>}
                {moment().add(1, "day").format("ddd") === 'Sat' &&
                  <li className="set-schedule__popper--option" onClick={() => setSunday()}>
                    <div className="">
                      <LiaCouchSolid className='scheduler-icon' fill={"#5297ff"} />
                    </div>
                    <p className="set-new-task__schedule--name"> Day After Tomorrow</p>
                    <p className="set-new-task__schedule--weekday">Sun</p>
                  </li>}
                <li className="set-schedule__popper--option" onClick={() => setNext7days()}>
                  <div className="">
                    <MdNextWeek className='scheduler-icon' fill={"#a970ff"} />
                  </div>

                  <p className="set-new-task__schedule--name">Next Week</p>
                  <p className="set-new-task__schedule--weekday">{moment().add(7, "day").format("ddd MMM D ")}</p>

                </li>
                <li className="set-schedule__popper--option" onClick={() => setNoDate()}>
                  <div className="">
                    <MdOutlineDoNotDisturbOn className='scheduler-icon' color={"grey"} />
                  </div>

                  <p className="set-new-task__schedule--name">No Date</p>
                </li>
                <li onClick={(e) => e.stopPropagation()}>
                  <div className="calender-custom-Buttons">
                    <button type="button"></button>
                  </div>
                  <hr />
                  <ScroalableCalenderPicker taskSchedule={taskSchedule} handleDateSelected={handleDateSelected} />
                </li>
              </ul>
              <hr />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TaskDatePicker