import moment from 'moment';
import { useSelector } from 'react-redux';

// Custom hook to get a person by ID
export function useTaskById(taskId) {
  const taskData = useSelector((state) =>
    state.todayTask.todayTasks.find((tasks) => tasks.id == taskId)
  );

  return taskData;
}

export function GetOverDueTasks() {
  const currentDate = moment();
  const taskData = useSelector((state) =>
    state.todayTask.todayTasks.filter((tasks) => {
      const taskDate = moment(tasks.date);
      return taskDate.isBefore(currentDate, 'day') && !tasks.completed
    })
  );
  return taskData;
}

export function GetAllTasksForParticularDate(date) {
  const result = {};
  const taskData = useSelector((state) =>
    state.todayTask.todayTasks.filter((tasks) => {
      const taskDate = moment(tasks.date).format('YYYY-MM-DD');
      return taskDate === date;
    })
  );
   result[date] = taskData;
   return result;;
}


