import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import "./styles/light.scss";
import "./styles/main.scss";
import {  getFavoritesAPI, getAllWorkspacesWithProjectsApi, getUserDeatils } from "ApiService/index";
import NewHead from "layout/AppHeader/AppHeader";
import { DefaultProjects } from "./default-projects";
import FavoritesProjects from "components/WorkSpaces/Projects/FavouriteProjects";
import WorkspaceProjects from "components/WorkSpaces/WorkspaceProjects";
import AddWorkSpace from "Models/WorkSpace/AddWorkSpace";
import AddProjects from "Models/Projects/AddProjects";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createdWorkSpaces, setSelectedWorkspace } from "store/UserProjectsSlice";
import { getAllLabelsAPI,getColorsAPI } from "ApiService";
import { InsertColorsToStore, InsertLabels } from "store/LabelsDataSlice";
import { useNavigate } from "react-router-dom";
import { CaptureAuthData } from "store/AuthDataSlice";
const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openWorkSpace, setOpenWorkSpace] = useState(false);
  const [reloadComponent, setReloadComponent] = useState(false);
  const userAuthData = useSelector((state) => state.AuthData);

  useEffect(() => {
    async function fetchData() {
      try {
        // Use Promise.all to concurrently fetch data from multiple APIs
        const [favoritesData, allWorkspacesWithProjectsResponse,,,getUserDeatilsApiResult] = await Promise.all([getFavoritesAPI(), getAllWorkspacesWithProjectsApi(),GetAllLabels(),getColorsAPICall(),getUserDeatils()]);

        if (favoritesData.status === 200) {
          // setFavoritesProjects(favoritesData.data.data);
        }

        if (allWorkspacesWithProjectsResponse.status === 200) {
          dispatch(createdWorkSpaces(allWorkspacesWithProjectsResponse.data.data));
        }
        if(getUserDeatilsApiResult.data.status===1){

          let UpdateuserData = {
            refreshToken: userAuthData.loginData.refreshToken,
            accessToken: userAuthData.loginData.accessToken,
            userDetails: {
              ...userAuthData.loginData.userDetails,
              username: getUserDeatilsApiResult.data.data.username,
              email:getUserDeatilsApiResult.data.data.email,
              profileImage:getUserDeatilsApiResult.data.data.profileImage
            },
          };
  
          dispatch(CaptureAuthData(["email", UpdateuserData, true]));
        }
        // console.log(getAllColorsApi,'getAllColorsApi');
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  // const getUserDeatilsApiCall = async () => {
  //   const res = await getUserDeatils();
  //   if (res.data.status === 1) {
  //     dispatch(InsertLabels(res.data.data));
  //   }
  // }

  
  const GetAllLabels = async () => {
    const res = await getAllLabelsAPI();
    if (res.data.status === 1) {
      dispatch(InsertLabels(res.data.data));
    }
  };

  const getColorsAPICall = async () => {
    const res = await getColorsAPI();
    if (res.data.status === 1) {
    dispatch(InsertColorsToStore(res.data.data));
      // dispatch(InsertLabels(res.data.data));
    }
  };

  const workspaces = useSelector((state) => state.userProjects.workspaces);
 

  const handleWorkSpaceOpen = () => {
    setOpenWorkSpace(true);
  };
  const handleWorkSpaceClose = () => {
    setOpenWorkSpace(false);
  };
  const handleSuccessAction = () => {
    setReloadComponent(!reloadComponent);
  };

  return (
    <motion.div
      layout
      initial={{
        x: -100,
        opacity: 0.5,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      exit={{
        x: -100,
        opacity: 0.5,
      }}
      className="Sidebar">
      <div className="flex">
        <NewHead />
      </div>
      <div className="fixed-sidebar">
        <DefaultProjects />
        <hr />
        <FavoritesProjects />{" "}
        {workspaces && workspaces.length > 0 ? (
          <>
            <div className="w-full my-4">
              {workspaces.length > 0 ? (
                <>
                  <hr />
                  <div className="w-full my-4">
                    <div className="font-bold">
                      Workspaces
                      <AddWorkSpace />
                    </div>
                    {workspaces.map((workspace) => (
                      <ul key={workspace.id} className="cursor-pointer">
                        <li className="mt-2" onClick={() => setSelectedWorkspace(workspace)}>
                          <details className="group [&_summary::-webkit-details-marker]:hidden">
                            <summary onClick={()=>navigate(`workspace/${workspace.id}`)} className="flex cursor-pointer items-center justify-between text-gray-800 px-2 py-2 hover-bg-white hover-text-black active-bg-blue-200 rounded">
                              {/* <Link to={`workspace/${workspace.id}`} className="text-sm font-medium"> */}
                                {workspace.workspaceName}
                              {/* </Link> */}
                              <div className="flex items-center">
                                <AddProjects id={workspace.id} onAddProject={handleSuccessAction} />
                                <span className=" transition duration-300 group-open:-rotate-180">
                                  <Icon icon="pepicons-pop:angle-down" color="gray" width="25" />
                                </span>
                              </div>
                            </summary>
                            <WorkspaceProjects projectsOfWork={workspace.projects} workspace={workspace} successAction={handleSuccessAction} />
                          </details>
                        </li>
                      </ul>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="fixed border-y">
        <li className="workspacebtn my-3" onClick={handleWorkSpaceOpen}>
          <Icon icon="pajamas:work" className="svgIco" />
          <span className="ml-4 font-bold">Add WorkSpace</span>
        </li>
      </div>
      {openWorkSpace && <AddWorkSpace open={handleWorkSpaceOpen} close={handleWorkSpaceClose} onWorkspaceAdded={handleSuccessAction} />}
    </motion.div>
  );
};





























export default Sidebar;