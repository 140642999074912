import React, { useState } from 'react';
import { HiOutlineAdjustments as AdjustIcon } from 'react-icons/hi';
import { AiOutlineQuestionCircle, AiOutlineCheckCircle } from 'react-icons/ai';



import './taskOptions.scss'
const TaskOptions = ({ showCompletedTasks, setShowCompletedTasks }) => {
  const [checkValue, setCheckValue] = useState(showCompletedTasks);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleViewOption = (event) => {
    const buttonRect = event.target.getBoundingClientRect();
    const top = buttonRect.bottom + window.scrollY; // Position modal below the button
    const left = buttonRect.left - 300;
    setModalPosition({ top, left });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function handleCheckBox(e) {
    setShowCompletedTasks(e.target.checked);
    setCheckValue(e.target.checked);
    closeModal();
  }

  return (
    <>
      <div className=" flex items-center gap-2 mr-3">
        <span className={`text-sm ${checkValue ? "text-sky-500" : "text-gray-800"}`}>Show Completed tasks</span>
        <label className="switch">
          <input type="checkbox" checked={checkValue} onChange={(e) => handleCheckBox(e)} />
          <span className="slider round"></span>
        </label>
      </div>

      {/* <button onClick={(e) => handleViewOption(e)} type="button" className="flex items-center rounded transition-all hover:bg-black/10 text-black/30  p-1">
        <AdjustIcon className=" text-xl" /> <span className="text-zinc-800 text-base ml-3">Completed tasks</span>
      </button> */}

      {isModalOpen && (
        <>
          <div className="modal-overlay" onClick={closeModal}>
            <div onClick={(e) => e.stopPropagation()}>
              <div className="modal viewTaskOptionModal rounded-md" style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}>
                <ul className="task_menu_list gap-2">
                  <li className="mb-2">
                    <div>{/* <AiOutlineQuestionCircle className="action-icons" /> */}</div>
                  </li>
                  <li>
                    <div className=" flex items-center gap-2">
                      <span>
                        <AiOutlineCheckCircle className="action-icons" />
                      </span>{" "}
                      <span className="text-gray-800 text-sm">Show Completed tasks</span>
                    </div>
                    <div>
                      <label className="switch">
                        <input type="checkbox" checked={checkValue} onChange={(e) => handleCheckBox(e)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TaskOptions;


























