import React from 'react';
import { useSnackbar } from 'notistack';
import { IoMdCloseCircle } from "react-icons/io";
const SnackbarWithClose = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueueSnackbarWithClose = (message, options = {}) => {
    const key = enqueueSnackbar(message, {
      ...options,
      action: (key) => (
        <button
          onClick={() => closeSnackbar(key)}
          color="inherit"
          size="small"
        >
          <IoMdCloseCircle className=' text-xl'/>
        </button>
      ),
    });
    return key;
  };

  return { enqueueSnackbarWithClose };
};

export default SnackbarWithClose;
