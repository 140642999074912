import React, { useEffect, useState } from "react";
import { BsFlagFill, BsFlag } from "react-icons/bs";
import { BsCalendar2Event } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";

import { usePopper } from "react-popper";
import { Tooltip } from 'react-tooltip'

import "./priorityPick.scss";
function PriorityPicker({ setTaskPriority, taskPriority }) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
    placement: "left",
  });
  const popperStyles = {
    ...styles.popper,
    // display: isShown ? "block" : "none"
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const openModal = (event) => {
    const buttonRect = event.target.getBoundingClientRect();
    const top = buttonRect.top + window.scrollY;
    const left = buttonRect.left + buttonRect.width; // Position modal to the right of the button
    setModalPosition({ top, left });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  /**
   * priority variable
   */
  const [selectedPriorValue, setSelectedPriorValue] = useState(4);

  const handlepriorClick = (e) => {
    setSelectedPriorValue(e);
    setTaskPriority(e);
  };

  const removePriority = (e) => {
    e.preventDefault();
    setSelectedPriorValue(4);
    setTaskPriority(4);
    setIsModalOpen(false);
  };
  useEffect(() => {
    setSelectedPriorValue(taskPriority);
  }, [taskPriority]);

  return (
    <div>
      <Tooltip id="my-tooltip" place='top' noArrow offset={0} />

      <div data-tooltip-id="my-tooltip" data-tooltip-content="Set priority P1, P2, P3, P4" ref={setReferenceElement} className={`border cursor-pointer p-1 rounded-md flex items-center gap-1 text-zinc-500 w-fit`}>
        <button onClick={(e) => setIsShown((x) => !x)} className="flex items-center" type="button">
          <span>
            <BsFlagFill width={"18px"} height={"18px"} className={`mr-1 prior-${selectedPriorValue}`} />
          </span>
          <span className={`prior-${selectedPriorValue}`}>Priority</span>
        </button>
        {selectedPriorValue !== 4 && (
          <button type="button" onClick={(e) => removePriority(e)}>
            <MdOutlineCancel className=" text-red-400" />
          </button>
        )}
        {/*  */}
      </div>

      {isShown && (
        <div className="modal-overlay-popper" onClick={() => setIsShown(false)}>
          <div
            ref={setPopperElement}
            style={popperStyles}
            {...attributes.popper}
            // onClick={(e)=>e.stopPropagation()}
          >
            <div
              className="modal-popper priorityModal rounded-md"
              // style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
            >
              <hr />
              <ul>
                <li onClick={() => handlepriorClick(1)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 1 ? "selectedPrior" : ""}`}>
                  <span>
                    <BsFlagFill className="mr-2 prior-1" />
                  </span>
                  <span>Priority 1</span>
                  {selectedPriorValue === 1 && (
                    <span>
                      {" "}
                      <BsDot className="text-red-600" />
                    </span>
                  )}
                </li>
                <li onClick={() => handlepriorClick(2)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 2 ? "selectedPrior" : ""}`}>
                  <span>
                    <BsFlagFill className="mr-2 prior-2" />
                  </span>
                  <span>Priority 2</span>
                  {selectedPriorValue === 2 && (
                    <span>
                      {" "}
                      <BsDot className="text-red-600" />
                    </span>
                  )}
                </li>
                <li onClick={() => handlepriorClick(3)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 3 ? "selectedPrior" : ""}`}>
                  <span>
                    <BsFlagFill className="mr-2 prior-3" />
                  </span>
                  <span>Priority 3</span>
                  {selectedPriorValue === 3 && (
                    <span>
                      {" "}
                      <BsDot className="text-red-600" />
                    </span>
                  )}
                </li>
                <li onClick={() => handlepriorClick(4)} className={`flex items-center p-2 cursor-pointer hover:bg-zinc-200 hover:rounded-lg priorityListItem ${selectedPriorValue === 4 ? "selectedPrior" : ""}`}>
                  <span>
                    <BsFlag className="mr-2 prior-4" />
                  </span>
                  <span> Priority 4</span>
                  {selectedPriorValue === 4 && (
                    <span>
                      {" "}
                      <BsDot className="text-red-600" />
                    </span>
                  )}
                </li>
              </ul>
              <hr />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PriorityPicker;

