import React, { useState, useEffect } from "react";
import EyeOpen from "assets/svg/eye-off.svg";
import EyeClosed from "assets/svg/eye-on.svg";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import login from "assets/svg/reset-password.jpg";
import LogoSmall from "assets/svg/taskpareto.svg";
import "./main.scss";
import { useForm } from "react-hook-form"
import { validationRegex } from "utils/Regex";
import { ResetPasswordApi } from "ApiService";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const ResetPassword = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    /**
     * type 1 is regular login
     * type 2 is social login
     */
    const handleFormSubmit = async (event) => {

        if (event.password !== event.confirmPassword) {
            enqueueSnackbarWithClose("Password and confirm password didn't match", { variant: 'error' })
            return
        }
        setLoading(true);
        let payload = {
            "token": token,
            "newPassword": event.confirmPassword
        }

        try {
            const response = await ResetPasswordApi(payload);
            if (response.data.status===1) {
                enqueueSnackbarWithClose(response.data.message, { variant: "success" });
                // setLoading(false);
                setTimeout(() => {
                navigate("/signin");
                }, 500);
            } else {
                enqueueSnackbarWithClose(response.data.message, { variant: "error" });
                setLoading(false);
            }

        } catch (error) {
            enqueueSnackbarWithClose(error?.response?.data?.message||error?.message, { variant: "error" });
            console.error("Login Error:", error);
            setLoading(false);
        }
    };
    return (
        <main className="auth-page">
            <div className="auth-page__container">
                {/* <img src={LogoSmall} /> */}

                <div className="auth-page__frame">
                    <div className="auth-page__wrapper">
                        <div className="auth-page__content">
                            <section className="text-gray-800 body-font">
                                <div className="container-fluid mx-auto">
                                    <div className="flex flex-wrap -mx-4 -mb-10 text-center">
                                        <div className="sm:w-1/2 xs:hidden lg:block">
                                            <div className="max-w-screen-xl m-auto">
                                                <div className="w-full relative select-none">
                                                    <div className="sideImg">
                                                        <img src={login} style={{ height: "100vh", margin: "0 auto" }} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sm:w-1/2">
                                            <div className="auth-page__logo">
                                                <Link to="/">
                                                    <img src={LogoSmall} width={"100px"} alt=";smaal app logo"/>
                                                </Link>
                                            </div>
                                            <div className="signup">
                                                <div className="signup__wrapper">
                                                    <div className="step_one">
                                                        <h1>
                                                            Password reset
                                                        </h1>
                                                        <p>Please enter a new password for your Task-Pareto account.</p>
                                                        <form className="login-form" onSubmit={handleSubmit(handleFormSubmit)}>

                                                            <div className="field">
                                                                <label className="label" htmlFor="password">
                                                                    Enter a new password
                                                                </label>
                                                                <div className="toggle_password">
                                                                    <input {...register('password', { required: { value: true, message: 'Please Enter Password' }, pattern: { value: validationRegex.password_valid_regex, message: 'Password must be at least 8 characters long, contain at least one uppercase letter, and one symbol' } })} className="form_field_control" type={showPassword ? "text" : "password"} id="password" placeholder="Enter password" />
                                                                    <span className="toggle" role="checkbox" tabIndex="0" aria-checked="false" onClick={() => setShowPassword(!showPassword)}>
                                                                        {showPassword ? <img src={EyeOpen} alt="Show Password" /> : <img src={EyeClosed} alt="Hide Password" />}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <span className="error-message">{errors?.password?.message}</span>

                                                            <div className="field">
                                                                <label className="label" htmlFor="confirm-password">
                                                                    Confirm your new password
                                                                </label>
                                                                <div className="toggle_password">
                                                                    <input {...register('confirmPassword', { required: { value: true, message: 'Please Enter Confirm Password' }, pattern: { value: validationRegex.password_valid_regex, message: 'confirmPassword must be at least 8 characters long, contain at least one uppercase letter, and one symbol' } })} className="form_field_control" type={showConfirmPassword ? "text" : "password"} id="confirm-password" placeholder="Enter confirm  password" />
                                                                    <span className="toggle" role="checkbox" tabIndex="0" aria-checked="false" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                                        {showConfirmPassword ? <img src={EyeOpen} alt="Show Password" /> : <img src={EyeClosed} alt="Hide Password" />}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <span className="error-message">{errors?.confirmPassword?.message}</span>

                                                            <span className=" text-zinc-400">Your password must be at least 8 characters long. Avoid common words or patterns.</span>
                                                            <button className="auth-button submit-button" disabled={loading} type="submit">
                                                                {loading ? '...Loading' : "Submit"}
                                                            </button>
                                                            <hr />
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ResetPassword