import React from "react";
import thoughts from "../../assets/svg/organise your thoughts.svg";
export default function About() {
  return (
    <>
      <div className="grid gap-10 lg:grid-cols-2 my-20 px-12 max-xs:p-3 max-xs:my-3">
        <div className="flex items-center justify-center mx-4 lg:pl-8">
          <img loading="lazy" className="object-cover mb-6 w-40 h-40  sm:h-64 xl:h-[100%] sm:w-64 xl:w-[30rem]" src={thoughts} alt="" />
        </div>
        <div className="flex flex-col justify-center xl:pr-9 sm:pr-0">
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-teal-accent-400">
            <svg className="text-blue-500 w-7 h-7" viewBox="0 0 24 24">
              <polyline fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" points=" 8,5 8,1 16,1 16,5" strokeLinejoin="round" />
              <polyline fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" points="9,15 1,15 1,5 23,5 23,15 15,15" strokeLinejoin="round" />
              <polyline fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" points="22,18 22,23 2,23 2,18" strokeLinejoin="round" />
              <rect x="9" y="13" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" width="6" height="4" strokeLinejoin="round" />
            </svg>
          </div>
          <div className=" mb-6">
            <h2 className="  mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Organize <br className="hidden md:block" />
              your thoughts
            </h2>
            <p className="text-base text-gray-800 md:text-lg text-justify">Our story begins with a simple idea – to streamline the way you work and live. At Task Pareto, we're committed to providing you with the tools to achieve more in less time. With Task Pareto, your ideas quickly transform into a neatly organized to-do list. Join us and experience a world where tasks are effortlessly managed and success is just a to-do list away.</p>
          </div>
        </div>
      </div>
    </>
  );
}



