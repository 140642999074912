import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LeftMenuReducer from "./LeftMenuSlice";
import AuthDataSlice from "./AuthDataSlice";
import TodayTasksDataSlice from "./TodayTasksDataSlice";
import userProfileReducer from "./ProfileEdit";
 import TodayLabelsDataSlice from "./LabelsDataSlice";
 import UserProjectsSlice from "./UserProjectsSlice";
import AdminAuthDetails from "./AdminAuthDetails";
 const persistConfig = {
   key: "root",
   storage,
 };

 const rootReducer = combineReducers({
   left_menu_on: LeftMenuReducer,
   AuthData: AuthDataSlice,
   todayTask: TodayTasksDataSlice,
   userProfile: userProfileReducer,
   labels: TodayLabelsDataSlice,
   userProjects: UserProjectsSlice,
   AdminAuthDetails:AdminAuthDetails
 });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);















