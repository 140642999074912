import React, { useState, useRef } from "react";
import AddTaskForm from "components/AddTaskForm/AddTaskForm";
import "./quickAddModal.scss";
import { useSelector } from "react-redux";
const AddQuickTask = (props) => {
  const modalRef = useRef(null);

  const open = props.open;
  const close = props.close;

  const modalVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
      },
    },
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleOverlayClick = () => {
    // close();
  };

  return (
    <>
      {/* <AnimatePresence> */}
      {open && (
        <div className="modal-overlay-popper" onClick={handleOverlayClick}>
          <div ref={modalRef} className="modal-popper quickAddTaskModal rounded-md relative" onClick={handleModalClick}>
            <button className="absolute top-2 right-2 p-1 rounded-full text-gray-800" onClick={close}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <AddTaskForm formAbort={close} isQuick={true} />
          </div>
        </div>
      )}
      {/* </AnimatePresence> */}
    </>
  );
};

export default AddQuickTask;



















