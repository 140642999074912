import AppRouter from "routingConfig/AppRouter";
import "./App.css";
import SampleAddForm from "components/AddTaskForm/SampleAddForm";
function App() {
  return (
    <div className="App">
      <AppRouter />
      {/* <SampleAddForm/> */}

    </div>
  );
}

export default App;