import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { CaptureAuthData } from "store/AuthDataSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { login, signUp } from "Services/api";
import Loading from "components/Loading/Loading";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const GoogleButton = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const location = useLocation();
  const navigate = useNavigate();
  const OAuthClientId = process.env.REACT_APP_SOCIAL_OAUTH;
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();

  const signedInUsingGoogle = async (details) => {
    setLoading(true)
    var token = details.credential;

    // ---------------social SIGN UP-------------------------------------//
    if (location.pathname.toLowerCase() === '/signup') {

      try {
        const signUpRes = await signUp({ token, type: 2 }).finally(() => {
          setLoading(false)
        })
        if (signUpRes.status === 1) {
          enqueueSnackbarWithClose(signUpRes.message, { variant: 'success' })
          localStorage.setItem("refresh_token", signUpRes.data.refreshToken);
          localStorage.setItem("access_token", signUpRes.data.accessToken);
          let loginData = { refreshToken: signUpRes.data.refreshToken, accessToken: signUpRes.data.accessToken, userDetails: signUpRes.data.user };
          dispatch(CaptureAuthData(["email", loginData, true]));
          navigate("/app/today");
        } else {
          enqueueSnackbarWithClose(signUpRes.message, { variant: 'error' })
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbarWithClose(error.response.data.message, { variant: 'error' })

      }

    }
    // ---------------social SIGN IN -------------------------------------//

    if (location.pathname.toLowerCase() === '/signin') {
      try {
        const loginApiRes = await login({ token, type: 2 }).finally(() => {
          setLoading(false)
        })
        if (loginApiRes.status === 1 && loginApiRes.data.user.blockUser === 0) {
          enqueueSnackbarWithClose(loginApiRes.message, { variant: 'success' })
          localStorage.setItem("refresh_token", loginApiRes.data.refreshToken);
          localStorage.setItem("access_token", loginApiRes.data.accessToken);
          let loginData = { refreshToken: loginApiRes.data.refreshToken, accessToken: loginApiRes.data.accessToken, userDetails: loginApiRes.data.user };
          dispatch(CaptureAuthData(["email", loginData, true]));
          navigate("/app/today");
        } else if (loginApiRes.data.user.blockUser === 0) {
          enqueueSnackbarWithClose(loginApiRes.message, { variant: 'error' })
        } else {
          enqueueSnackbarWithClose('Your Account Has Been Blocked By Admin', { variant: 'error' })
        }
      } catch (error) {
        enqueueSnackbarWithClose(error?.response?.data?.message ?? error?.message, { variant: 'error' })

      }

    }

  };
  return (
    <>
      <div className="authstyle auth-form">
        <h1>Unlock Productivity with Task Pareto</h1>
        {loading && <Loading />}
        <GoogleOAuthProvider clientId={OAuthClientId}>
          <GoogleLogin
            size="large"
            width={"390px"}
            onSuccess={(e) => {
              signedInUsingGoogle(e);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          // useOneTap
          />
        </GoogleOAuthProvider>
      </div>
    </>
  );
};

export default GoogleButton;



