import {  useLocation } from "react-router-dom";
import "./main.scss";
import { LoginForm } from "./login-form";
import { SignupForm } from "./signup-form";
export const AuthenticationPage = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <main className="auth-page">
      <div className="auth-page__container">
        {/* <img src={LogoSmall} /> */}

        <div className="auth-page__frame">
          <div className="auth-page__wrapper">
            <div className="auth-page__content">
              {pathname === "/signin" ? (
                <>
                  <LoginForm />
                </>
              ) : (
                <SignupForm />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

