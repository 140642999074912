// import { collatedTasks } from "constants/constants";
// export const collatedTasksExist = (selectedProject) => collatedTasks.find((task) => task.key === selectedProject);
export const getCollatedTitle = (projects, key) => projects.find((project) => project.key === key);
export const getTitle = (projects, projectId) => projects.find((project) => project.projectId === projectId);
export const getProjectTitle = (projects, projectId) => {
  let project = projects.find((project) => project.projectId === projectId);
  return project?.name;
};
export const getProjectInfo = (projects, projectId) => {
  return projects.find((project) => project.projectId === projectId);
};
export const generatePushId = () => {
 return Math.random().toString(36).substring(2, 15);
};

export const getProjectId = (projects, projectName) => projects.find((project) => project.name === projectName);
export const getProjectHex = (projects, projectId) => projects.find((project) => project.projectId === projectId);

export const  extractNumberFromString=(inputString)=> {
  // Regular expression to match the first number in the string
  const regex = /\d+/;
  
  // Use the match method to find the first number in the string
  const match = inputString && inputString.match(regex);

  // Check if a number was found
  if (match) {
    // Convert the matched value to a number
    const number = parseInt(match[0], 10); // Use parseInt to convert to an integer
    return number;
  } else {
    // Return a default value or handle the case where no number is found
    return null; // You can change this to any default value you prefer
  }
}
 // handleCharSpace for first letter
 export const handleCharSpace = (e) => {
  if (e.target.value?.length === 0 && e.which === 32) {
    e.preventDefault();
  }
  // else if(!onlyAlphaRegix.test(inputValue)){
  //   e.preventDefault();
  // }
}
// handleChange input only numbers
export const handleNumInput = (e) => {
  const isNumberKey = /^[0-9]$/.test(e.key);
  const isBackspaceKey = e.key === 'Backspace';
  const isTabKey = e.key === 'Tab';

  if (!isNumberKey && !isBackspaceKey && !isTabKey) {
    e.preventDefault();
  }
  // if (/\D/g.test(e.target.value)) {
  //   e.target.value = e.target.value.replace(/[^0-9]/g, '');//(/\D/g, '');
  // }
  // if (/[\D][^0-9\.\,]/g.test(e.target.value)) {
  //   e.target.value = e.target.value.replace(/[\D][^0-9\.\,]/g, '');
  // }
}

export const checkForTaskPriority = (userString) => {
  const taskPriorities = ["p1", "p2", "p3", "p4"];

  let highestPriority = 4; // Initialize with the lowest priority

  for (const priority of taskPriorities) {
    const regex = new RegExp(priority, 'gi');
    const matches = userString.match(regex);
    if (matches) {
      const lastMatch = matches[matches.length - 1];
      const priorityIndex = taskPriorities.indexOf(lastMatch.toLowerCase());
      if (priorityIndex !== -1 && priorityIndex < highestPriority) {
        highestPriority = priorityIndex;
      }
    }
  }
  // Set the task priority based on the highest found priority
  return(highestPriority >=4?4:highestPriority + 1); // Adding 1 to convert index to priority level, but now it's capped at 4
};

export const handleInitialSpaceForInput=(e)=>{
  if (e.target.value?.length === 0 && e.which === 32) {
    e.preventDefault();
  }
}