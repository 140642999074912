import "../TaskBar/taskBarStyles.scss";
// framer motion
 
// hooks

// icons
 import { HiOutlinePencil as EditIcon } from "react-icons/hi";
 import { GoInbox } from "react-icons/go";
 
 import { Link } from "react-router-dom";
import { TaskDate } from "DateHelpers/task-date";
 import CompleteTaskCheckBox from "ActionItems/CompleteTaskCheckBox/CompleteTaskCheckBox";
import MoreTaskOptions from "ActionItems/MoreTaskActions/MoreTaskActions";
import { TbSubtask } from 'react-icons/tb'
const TaskInfo = ({ task, tasks, setTasks, setEditId }) => {


  return (
    <div className="py-1 border-b w-full flex items-center gap-2 group bg-white dark:bg-dark-500 dark:border-dark-100">
      <div className="flex items-center gap-2 flex-grow w-full">
        <CompleteTaskCheckBox taskInfo={task} />
        <div className={`task__details ${task.completed ? "Task-completed" : "task-inprogress"}`}>
          <p className="task__text">
            <Link to={`/app/task/${task?.id}`} className="flex-grow">
              {task?.title}
            </Link>
          </p>
          {/* Show the description if only if the task was nopt ocmpleted */}
          {!task?.completed && <p className=" text-gray-400 text-xs">{task?.description}</p>}
          <div className="task__info mt-2 flex gap-2">
                {task?.subtasks?.length>0&&<Link to={`/app/task/${task?.id}`} className="hover:text-black">
                  <div className="task__sub__info flex">
                    <TbSubtask />
                    <span className=" text-xs ">
                      <span className="sub_numerator">{task?.subtasks?.filter((unCom)=>unCom.completed)?.length}</span>
                      <span className="">/</span>
                      <span className="sub_denominator">{task?.subtasks?.length}</span>
                    </span>
                  </div>
                </Link>}
                <div>{task.date && <TaskDate date={task?.date} />} </div>
                <div className="task__recurring__info  ">
                  {/* <span className="text-xs"><GrPowerCycle fill={"#579dff"} /></span> */}
                  </div>
                <div className="task_label_info gap-1">
                  {/* {[1,2,3].map((data)=>(<LabelInfo/>))} */}
                </div>
              </div>
        </div>
      </div>
      <div className="flex flex-col h-full">
        <div className="flex">
          <button className="mr-3 md:opacity-0 group-hover:opacity-100 transition-all" onClick={() => setEditId(task.id)}>
            <EditIcon className="text-xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" />
          </button>
          <MoreTaskOptions taskInfo={task} />
        </div>
        <div className="flex items-center gap-1">
          <span className="text-sm text-black/60 dark:text-white/50 ">Inbox</span>
          <GoInbox className="text-blue-500 text-xs" />
        </div>
      </div>
    </div>
  );
};

export default TaskInfo;





