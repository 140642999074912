import { addToFavoriteAPI, deleteProjectAPI, duplicateProjectAPI, removeFavoriteAPI } from "ApiService";
import React, { useEffect, useRef, useState } from "react";
import EditProject from "Models/Projects/EditProject";
import { useNavigate } from "react-router-dom";
import { ProjectDetails, addFavorite, deleteProject, removeFavorite, updateProjectsInWorkspace } from "store/UserProjectsSlice";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
 
export default function ProjectOptions({ workspaceId, projectId, projectData }) {
  const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const [popups, setPopups] = useState(false);
  const [isFavorite, setIsFavorite] = useState(projectData.isFavorite);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const popoverRef = useRef(null);

  const workspacesData = useSelector((state) => state?.userProjects?.workspaces?.find((workspace) => workspace?.id === workspaceId));

  const togglePopover = (e) => {
    e.stopPropagation();
    setPopups(!popups);
  };

  const closePopoverOnClickOutside = (e) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target)) {
      setPopups(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closePopoverOnClickOutside);
    return () => {
      document.removeEventListener("click", closePopoverOnClickOutside);
    };
  }, [projectData]);

  const handleDeleteProject = async (id) => {
    try {
      const deleteResponse = await deleteProjectAPI(projectId);
      if (deleteResponse.status === 200) {
        dispatch(deleteProject({ projectId: projectId, workspaceId: workspaceId, workspaces: workspacesData }));
        setPopups(false);

        enqueueSnackbarWithClose("Project deleted successfully", { variant: "success" });
        setTimeout(() => {
          navigate(`/app/workspace/${workspaceId}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      setPopups(false);
    }
  };
  const workspaces = useSelector((state) => state.userProjects.workspaces);

  const handleDuplicateProject = async () => {
    try {
      const res = await duplicateProjectAPI(projectId);
      const projectDetails = res.data.message.data.project;
      if (res.status === 200) {
        setPopups(false);

        dispatch(ProjectDetails(projectDetails));

        const duplicatedProject = {
          id: projectDetails.id,
          name: projectDetails.name,
          colorCode: projectDetails.colorCode,
          colorName: projectDetails.colorName,
          isFavorite: projectDetails.isFavorite,
          view: projectDetails.selectedView,
        };

        if (duplicatedProject.isFavorite === true) {
          dispatch(addFavorite(duplicatedProject));
        }
        const updatedWorkspace = { ...workspaces.find((workspace) => workspace.id === workspaceId) };

        if (updatedWorkspace) {
          const updatedProjects = [...updatedWorkspace.projects, duplicatedProject];
          updatedWorkspace.projects = updatedProjects;
        }
        dispatch(
          updateProjectsInWorkspace({
            workspaceId: workspaceId,
            projects: updatedWorkspace.projects,
          })
        );

        enqueueSnackbarWithClose("Project Duplicated successfully", { variant: "success" });

        navigate(`/app/workspace/${workspaceId}/project/${projectDetails.id} `);
      }
    } catch (error) {
      console.error("Error duplicating project:", error);
    }
  };

  const removeFromFavorites = async (id) => {
    try {
      const res = await removeFavoriteAPI(projectId);
      if (res.status === 200) {
        console.log(res, "res");

        setPopups(false);
        dispatch(removeFavorite(projectId));
        dispatch(ProjectDetails(res.data.data));
        console.log(projectData);
        setIsFavorite(false);

        enqueueSnackbarWithClose(res.data.message, { variant: "success" });
        const updatedWorkspacesData = { ...workspacesData };
        const updatedProjects = updatedWorkspacesData.projects.map((project) => (project.id === projectId ? { ...project, isFavorite: false } : project));
        updatedWorkspacesData.projects = updatedProjects;
        dispatch(updateProjectsInWorkspace(updatedWorkspacesData));
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      setPopups(false);
    }
  };

  const addToFavorites = async (id) => {
    try {
      const res = await addToFavoriteAPI(projectId);
      if (res.status === 200) {
        setPopups(false);
        dispatch(addFavorite(res.data.data));
        dispatch(ProjectDetails(res.data.data));
        setIsFavorite(true);

        enqueueSnackbarWithClose(res.data.message, { variant: "success" });
        const updatedWorkspacesData = { ...workspacesData };
        const updatedProjects = updatedWorkspacesData.projects.map((project) => (project.id === projectId ? { ...project, isFavorite: true } : project));
        updatedWorkspacesData.projects = updatedProjects;
        dispatch(updateProjectsInWorkspace(updatedWorkspacesData));
      }
    } catch (error) {
      console.error("Error project:", error);
      setPopups(false);
    }
  };

  const copyProjectLink = (projectId) => {
    if (!projectId) {
      console.error("Invalid project ID");
      return;
    }
    const frontendURL = process.env.REACT_APP_WEB_LIVE_FRONTEND_URL;
    const projectLink = `${frontendURL}app/workspace/${workspaceId}/project/${projectId}`;
    navigator.clipboard.writeText(projectLink).then(
      () => {
        console.log("Project link copied to clipboard:", projectLink);
        enqueueSnackbarWithClose("Project link copied to clipboard", { variant: "success" });
      },
      (err) => {
        console.error("Error copying project link:", err);
        enqueueSnackbarWithClose("Error copying project link", { variant: "error" });
      }
    );
  };

  return (
    <div>
      <span className="text-lg relative">
        <button type="button" className="" onClick={(e) => togglePopover(e)}>
          <span className=" ">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" stroke="gray" strokeWidth="1.5">
                <circle cx="5" cy="12" r="2" />
                <circle cx="12" cy="12" r="2" />
                <circle cx="19" cy="12" r="2" />
              </g>
            </svg>{" "}
          </span>
        </button>

        {popups && (
          <div className="project-options" ref={popoverRef}>
            <ul>
              <li className="flex items-center mb-2">
                <EditProject project={projectData} setPopups={setPopups} />
              </li>
              <hr />
              {console.log(isFavorite, "fvvaaf")}{" "}
              {isFavorite !== true ? (
                <li className="flex items-center my-2 cursor-pointer" onClick={addToFavorites}>
                  <Icon icon="mdi:heart-outline" color="#2c3f59" width="20" className="mr-2" />
                  Add to Favorite
                </li>
              ) : (
                <li className="flex items-center my-2 cursor-pointer" onClick={removeFromFavorites}>
                  <Icon icon="mdi:heart-off-outline" color="#2c3f59" width="20" className="mr-2" />
                  Remove from Favorite
                </li>
              )}
              <hr />
              <li className="flex items-center my-2">
                <button type="button" className="flex items-center" onClick={() => copyProjectLink(projectId)}>
                  <Icon icon="carbon:copy-link" color="#2c3f59" width="20" className="mr-2" />
                  Copy Project Link
                </button>
              </li>
              {/* <li className="flex items-center my-2 cursor-pointer" onClick={handleDuplicateProject}>
                <Icon icon="ion:duplicate-outline" color="#2c3f59" width="20" className="mr-2" />
                Duplicate
              </li> */}
              <hr />
              {/* <li className="flex items-center my-2">
                <Icon icon="uil:archive" color="#2c3f59" width="20" className="mr-2" />
                Archive
              </li> */}
              <li className="flex items-center my-2 cursor-pointer" onClick={handleDeleteProject}>
                <Icon icon="fluent:delete-12-regular" color="#2c3f59" width="20" className="mr-2" /> Delete
              </li>
            </ul>
          </div>
        )}
      </span>
    </div>
  );
}





















































