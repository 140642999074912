import React, { useEffect, useState, useRef } from "react";

// framer motion
// icons
import { GoInbox } from "react-icons/go";
import { HiOutlinePencil as EditIcon } from "react-icons/hi";
import { ImSpinner8 as SpinnerIcon } from "react-icons/im";
import { IoFlagOutline as FlagIcon, IoAlarmOutline as ClockIcon } from "react-icons/io5";
import { ImPriceTag as TagIcon } from "react-icons/im";

import { Link, useLocation } from "react-router-dom";
import { TaskDate } from "DateHelpers/task-date";
import CompleteSubTaskCheckBox from "ActionItems/CompleteSubTaskCheckBox/CompleteSubTaskCheckBox";
import TaskDatePicker from "ActionItems/TaskDatePicker/TaskDatePicker";
import PriorityPicker from "ActionItems/PriorityPicker/PriorityPicker";
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
import { checkForTaskPriority, extractNumberFromString } from "utils/utils";
import { updateSubTaskForTask } from "ApiService";
//import MoreSubTaskActions from "ActionItems/MoreSubTaskActions/MoreSubTaskActions";
import {  useDispatch } from "react-redux";
import { subTaskAction } from "store/TodayTasksDataSlice";
import * as chrono from "chrono-node";
import MoreSubTaskActions from "ActionItems/MoreSubTaskActions/MoreSubTaskActions";
import { Editor, EditorState, ContentState, CompositeDecorator, getDefaultKeyBinding, SelectionState } from 'draft-js';
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const SubTaskBar = ({ subTask, subTasks, setSubTasks }) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const MAX_LENGTH = 200; // Adjust the character limit as needed

  //**this state holds the key word to highlet in the editoe */
  const [extractedDateTerm, setExtractedDateTerm] = useState("");
  const editorRef = useRef(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [subTaskFormData, setSubTaskFormData] = useState({
    task: subTask.title,
    description: subTask.description,
  });
  const [subTaskSchedule, setSubTaskSchedule] = useState({ day: "", date: null });

  const [subTaskPriority, setSubTaskPriority] = useState(4);

  const [formLoading, setFormLoading] = useState(false);
  const [subEdit, setSubEdit] = useState(false);

  useEffect(() => {
    // setTaskSchedule()
    if (subTask?.dueDate) {
      setSubTaskSchedule(getDayAndDateObjFromISO(subTask?.dueDate));
    }
    setSubTaskPriority(extractNumberFromString(subTask.priority));
  }, [subTask]);
  // Create a decorator to highlight specific words
  const keywordDecorator = new CompositeDecorator([
    {
      strategy: findKeywordStrategy(extractedDateTerm),
      component: KeywordSpan,
    },
  ]);

  //** functions for keyword and hightltter START */
  useEffect(() => {
    // setEditorState(EditorState.createEmpty(keywordDecorator));
    const contentState = editorState.getCurrentContent();
    const newEditorState = EditorState.createWithContent(contentState, keywordDecorator);
    const currentSelection = editorState.getSelection();
    const stateWithContentAndSelection = EditorState.forceSelection(newEditorState, currentSelection);
    setEditorState(stateWithContentAndSelection);
  }, [extractedDateTerm]);
  /**
   * update the subtasks state and callthe setsubtasks function from child to parent callback
   */

  // Function to update a specific object by ID to the local state for user interface
  const updateObjectById = (id, updatedObject) => {
    const updatedArray = subTasks.map((obj) => {
      if (obj.id === id) {
        return updatedObject; // Replace the object with the updated one
      }
      return obj; // Keep other objects as they are
    });
    setSubTasks(updatedArray); // Update the state with the new array
  };
  /**
   * editSubTask
   */
  const editSubTask = async (e) => {
    if (subTaskFormData.task && subTaskFormData.task.trim().length > 0) {
      setFormLoading(true)
      // e.preventDefault()
      const subTaskpayload = {
        title: subTaskFormData.task,
        description: subTaskFormData.description,
        dueDate: subTaskSchedule.date ? subTaskSchedule.date : null,
        priority: `priority${subTaskPriority}`,
        reminders: [],
        labels: [],
        category: "",
        isFavorite: false,
      };
      const updateSubTaskForTaskRes = await updateSubTaskForTask(subTask?.taskId, subTask?.id, subTaskpayload).finally(() => {
        setFormLoading(false)
      });
      if (updateSubTaskForTaskRes.data.status === 1) {
        // setSubTasksData((pre)=>[...pre,updateSubTaskForTaskRes.data.data])

        //***************THis function update state  */
        updateObjectById(subTask?.id, updateSubTaskForTaskRes.data.data);
        //----------------------------------SUB TASK EDIT-------------------------//
        dispatch(subTaskAction(updateSubTaskForTaskRes.data.data)); //update to redux state

        setSubEdit(false);

        enqueueSnackbarWithClose(updateSubTaskForTaskRes.data.message, { variant: "success" });
        //*************clearing the sub task form vallues */
        // setSubTaskFormData({
        //   task: "",
        //   description: "",
        // })

        setSubTaskSchedule({ day: "", date: null });

        setSubTaskPriority(4);
      } else {
        enqueueSnackbarWithClose(updateSubTaskForTaskRes.data.message, { variant: "error" });
      }
    } else {
      enqueueSnackbarWithClose("Please enter sub task title", { variant: "error" });
    }
  };

  const deleteCallbackAction = (id) => {
    // alert(id);
    setSubTasks(subTasks.filter((subD) => subD.id !== id));
  };

  //CHRONO NODE DATE PARSE
  const dateparse = (dateString) => {
    const currentDate = new Date();
    const parsedDate = chrono.parseDate(dateString, currentDate, { forwardDate: true });
    let res = chrono.parse(dateString)[0];
    if (res) {
      setExtractedDateTerm(res?.text);
    } else {
      setExtractedDateTerm("");
    }
    if (parsedDate) {
      const parsedDateTime = parsedDate.getTime(); // Get the timestamp of the parsed date
      const currentDateTime = currentDate.getTime(); // Get the timestamp of the current date
      if (parsedDateTime >= currentDateTime) {
        // Date is either current or in the future
        return parsedDate;
      } else {
        setExtractedDateTerm("");
        // Date is in the past
        return null; // Or handle it as needed
      }
    } else {
      setExtractedDateTerm("");
      return null; // No valid date parsed
    }
  };

  const handleSubTaskTitleChange = (e) => {
    let taskTitle = e.target.value;

    setSubTaskFormData({ ...subTaskFormData, task: e.target.value });

    let parsedDate = dateparse(taskTitle);
    let reqDate = getDayAndDateObjFromISO(parsedDate);

    if (parsedDate) {
      setSubTaskSchedule(reqDate);
    } else {
      // checkIsTaskingAddingForToday();
      setSubTaskSchedule({ day: "", date: null });
    }
    if (taskTitle) {
      const priorityOfTask = checkForTaskPriority(taskTitle);
      setSubTaskPriority(priorityOfTask);
    }
    const recurringWords = matchRecurringWords(taskTitle);
  };

  // Example usage:
  function matchRecurringWords(sentence) {
    // Define a regex pattern for recurring phrases
    const regexPattern = /(\bmonthly\b|\bevery\sweek\b|\bevery\s(?:sunday|monday|tuesday|wednesday|thursday|friday|saturday)\b)/gi;

    // Use the regex pattern to find all recurring phrases in the sentence
    const matches = sentence.match(regexPattern);

    return matches || []; // Return an array of matched recurring phrases or an empty array
  }
  const handleEditorChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    // setTaskData({ ...taskData, task: plainText }); //updating this to state variable
    setSubTaskFormData({ ...subTaskFormData, task: plainText });

    setEditorState(newEditorState);
    if (!subTask.dueDate) {
      let parsedDate = dateparse(plainText);
      let reqDate = getDayAndDateObjFromISO(parsedDate);
      if (parsedDate) {
        setSubTaskSchedule(reqDate);
      } else {
        // checkIsTaskingAddingForToday();
        setSubTaskSchedule({ day: "", date: null });
      }
    }

    // if (plainText) {
    //   const priorityOfTask = checkForTaskPriority(plainText);
    //   setSubTaskPriority(priorityOfTask);
    // }
  };
  const handleKeyCommand = (command) => {
    if (command === "submit-form") {
      // Implement the logic to submit the form here
      editSubTask();
      return "handled";
    }
    return "not-handled";
  };

  const customKeyBinding = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      return "submit-form";
    }
    return getDefaultKeyBinding(e);
  };
  function handleTaskEdit() {
    const content = ContentState.createFromText(subTask.title);
    setEditorState(EditorState.createWithContent(content));
    setSubTaskFormData({
      task: subTask.title,
      description: subTask.description,
    });

    if (subTask?.dueDate) {
      setSubTaskSchedule(getDayAndDateObjFromISO(subTask?.dueDate));
    }
    setSubTaskPriority(extractNumberFromString(subTask.priority));
    setSubEdit(true);
  }

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const _handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return "handled";
    }
  };

  const _handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };

  return (
    <div className="py-1 border-b w-full flex items-center gap-2 group bg-white ">
      {subEdit ? (
        <form
          // onSubmit={(e) => editSubTask(e)}
          className="w-full">
          <div className="flex flex-col gap-2 border dark:border-white/20 rounded-lg p-3">
            {/* <input
              type="text"
              className="text-base font-medium outline-none disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
              placeholder="Sub Task Title"
              value={subTaskFormData.task}
              disabled={formLoading}
              autoFocus
              // onChange={({ target }) =>
              //   setSubTaskFormData({
              //     ...subTaskFormData,
              //     task: target.value,
              //   })
              // }
              onChange={(e) => handleSubTaskTitleChange(e)}
            /> */}
            <Editor editorState={editorState} onChange={handleEditorChange} handleKeyCommand={handleKeyCommand} keyBindingFn={customKeyBinding} placeholder="Type your text here..." ref={editorRef} className=" cursor-text" handleBeforeInput={_handleBeforeInput} handlePastedText={_handlePastedText} />
            <textarea
              placeholder="Description"
              className="text-sm outline-none dark:border-white/20 transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
              disabled={formLoading}
              value={subTaskFormData.description}
              onChange={({ target }) =>
                setSubTaskFormData({
                  ...subTaskFormData,
                  description: target.value,
                })
              }
            />
            <div className="flex w-full items-center justify-between">
              <div className="flex w-full items-center gap-2">
                <TaskDatePicker getTaskDateData={setSubTaskSchedule} taskSchedule={subTaskSchedule} />
                <PriorityPicker setTaskPriority={setSubTaskPriority} taskPriority={subTaskPriority} />
              </div>
              <div className="items-center gap-2 hidden">
                <button type="button" className="text-xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20">
                  <TagIcon />
                </button>
                <button type="button" className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20">
                  <FlagIcon />
                </button>
                <button type="button" className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20">
                  <ClockIcon />
                </button>
              </div>
            </div>
          </div>
          <div className="flex w-full p-2 items-center justify-end gap-2">
            <button
              type="button"
              className="bg-gray-100 dark:bg-dark-100 dark:text-white dark:hover:bg-white/20 py-2 px-4 text-sm font-bold text-black/70 rounded hover:bg-gray-200"
              onClick={() => {
                setSubEdit(false);
              }}>
              Cancel
            </button>
            <button type="button" className="bg-primary py-2 px-4 text-sm font-bold text-white/90 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40" disabled={!subTaskFormData.task || formLoading} onClick={(e) => editSubTask(e)}>
              {formLoading ? (
                <span className="flex items-center gap-2">
                  <SpinnerIcon className="animate-spin" />
                  <span>Loading...</span>
                </span>
              ) : (
                "Modify Sub-Task"
              )}
            </button>
          </div>
        </form>
      ) : (
        <>
          {/* <button
            className="text-xl text-black/60 dark:text-white/50 md:opacity-0 group-hover:opacity-100 transition-all cursor-grab active:cursor-grabbing"
          // onPointerDown={(event) => dragControls.start(event)}
          >
            <DragIcon />
          </button> */}
          <div className="flex items-center gap-2 flex-grow w-full">
            <CompleteSubTaskCheckBox subTaskInfo={subTask} checkBoxActionData={updateObjectById} />
            <div className={`task__details ${subTask.completed ? "Task-completed" : "task-inprogress"}`}>
              {pathname.toLowerCase() === "/app/today" || pathname.toLowerCase() === "/app/inbox" ? (
                <p className="task__text">
                  <Link to={`/app/task/${subTask?.taskId}`} className="flex-grow">
                    {subTask?.title}
                  </Link>
                </p>
              ) : (
                <p className="task__text">{subTask?.title}</p>
              )}
              {/* Show the description if only if the task was nopt ocmpleted */}
              {!subTask?.completed && <p className=" text-gray-400 text-xs">{subTask?.description}</p>}
              <div className="task__info mt-2">
                <div>{subTask?.dueDate && <TaskDate date={subTask?.dueDate} />} </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-full">
            <div className="flex">
              {!subTask.completed && (
                <button className={`md:opacity-0 group-hover:opacity-100 transition-all `} onClick={() => handleTaskEdit()}>
                  <EditIcon className="text-xl text-black/70 dark:text-white/50  border-b border-black/80 pb-1" />
                </button>
              )}
            </div>
            <div className="flex items-center gap-1 hidden">
              <span className="text-sm text-black/60 dark:text-white/50 ">Inbox</span>
              <GoInbox className="text-blue-500 text-xs" />
            </div>
          </div>
          <MoreSubTaskActions subTaskInfo={subTask} callBackAction={updateObjectById} deleteCallbackAction={deleteCallbackAction} />
        </>
      )}
    </div>
  );
};

export default SubTaskBar;
// Define a function to find the keyword's strategy
function findKeywordStrategy(keyword) {
  return function (contentBlock, callback) {
    const text = contentBlock.getText();
    const startIndex = text.indexOf(keyword);
    if (startIndex !== -1) {
      callback(startIndex, startIndex + keyword.length);
    }
  };
}

// Custom span component for highlighting the keyword
const KeywordSpan = (props) => {
  return <span style={{ backgroundColor: "#d5d5d5", padding: "0 2px" }}>{props.children}</span>;

};