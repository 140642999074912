import { motion } from "framer-motion";
import "../AppSideBar/styles/light.scss";
import "../AppSideBar/styles/main.scss";
import { AdminProjects } from "./AdminProjects";
import AdminSideBarHead from "./AdminSideBarHead";

const AdminSideBar = () => {

  return (
    <motion.div
      layout
      initial={{
        x: -100,
        opacity: 0.5,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      exit={{
        x: -100,
        opacity: 0.5,
      }}
      className="Sidebar w-[300px]">
      <div className="flex">
        <AdminSideBarHead />
      </div>
      <div className="fixed-sidebarrr">
        <AdminProjects />
        <hr />
      </div>
     
    </motion.div>
  );
};
export default AdminSideBar;