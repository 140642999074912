import axios from 'axios';
import jwt_decode from "jwt-decode";


// Create an instance of Axios requires access token
const paretoHttp = axios.create({
  baseURL: process.env.REACT_APP_WEB_BACKEND_URL,
});


// Function to check if the access token is expired
function isAccessTokenExpired() {
  const expiration = localStorage.getItem('access_token');
  const authDecodedData = jwt_decode(expiration);
  const currentTimeInMilliseconds = new Date().getTime();
  const expirationTimeInSeconds = Number(authDecodedData.exp);
  const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000; // Convert to milliseconds

  // Check if the token has expired
  const hasExpired = currentTimeInMilliseconds > expirationTimeInMilliseconds;
  return hasExpired;
}

// Function to refresh the access token
async function refreshAccessToken() {
  const refreshToken = localStorage.getItem('refresh_token');
  // Make a request to your server to refresh the access token using the refresh token
  // Server should return a new access token
  const response = await fetch(`${process.env.REACT_APP_WEB_BACKEND_URL}/refresh-token`, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  if (data.status === 0 && data.message === 'Invalid refresh token') {
    localStorage.clear()
    window.location.replace('/')
    return
  }
  // Update the access token and its expiration in localStorage
  localStorage.setItem('access_token', data.data.accessToken);
  // localStorage.setItem('access_token_expiration', data.accessTokenExpiration);
  return data.data.accessToken;
}

// Request Interceptor: Add headers, authentication, or any other request configuration
paretoHttp.interceptors.request.use(
  async (config) => {
    // Modify the request configuration here
    if (isAccessTokenExpired()) {
      // alert()
      const newAccessToken = await refreshAccessToken();
      config.headers.Authorization = newAccessToken;
    } else {
      const accessToken = localStorage.getItem('access_token');
      config.headers.Authorization = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor: Handle responses, error handling, logging, etc.
paretoHttp.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error) => {
    // Handle errors, e.g., show error messages to the user
    return Promise.reject(error);
  }
);

export default paretoHttp;
