import { createSlice } from "@reduxjs/toolkit";

const initialState = { loginType: "", loginData: "", isUserLogged: false };

const AuthDataSlice = createSlice({
  name: "authData",
  initialState,
  reducers: {
    CaptureAuthData: (state, action) => {
      state.loginType = action.payload[0];
      state.loginData = action.payload[1];
      state.isUserLogged = action.payload[2];
    },
  },
});

export const { CaptureAuthData } = AuthDataSlice.actions;

export default AuthDataSlice.reducer;

