import { completeSubTaskApi, unCompleteSubTaskAPi, unDoCompletedTask } from "ApiService";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";

import { useDispatch } from "react-redux";
import {subTaskAction } from "store/TodayTasksDataSlice";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';

const CompleteSubTaskCheckBox = (props) => {
  const { enqueueSnackbarWithClose } = SnackbarWithClose();
  
  const dispatch = useDispatch()
  const { subTaskInfo, checkBoxActionData } = props;

  const [taskInfoState, setTaskInfoState] = useState(subTaskInfo)

  const completeSubTaskApiCall = async (e) => {
    //--------------------- FOR CHECK BOX EFFECT
    setTaskInfoState({ ...taskInfoState, completed: true })

    const completeSubTaskApiRes = await completeSubTaskApi(subTaskInfo.id);
    document.getElementById(`task-check-button_${subTaskInfo.id}`).classList.add('animate-bounce')

    if (completeSubTaskApiRes.data.status === 1) {
      enqueueSnackbarWithClose(completeSubTaskApiRes.data.message, { variant: "success" });
      //***********Update the data to redux  only the task id and sub task id enough to store*/
      // -------------------------  STORE
      //----------------------------------SUB TASK EDIT-------------------------//

      setTimeout(() => {
        dispatch(subTaskAction(completeSubTaskApiRes.data.data)) //update to redux state
        document.getElementById(`task-check-button_${subTaskInfo.id}`).classList.remove('animate-bounce')
      }, 100)

      // dispatch(UpdateTask(completeSubTaskApiRes.data.data))
      checkBoxActionData(subTaskInfo.id, completeSubTaskApiRes.data.data);
    } else {
      enqueueSnackbarWithClose(completeSubTaskApiRes.data.message, { variant: "error" });
      setTaskInfoState({ ...taskInfoState, completed: false })

    }
  };

  const unDoCompletedSubTaskMethod = async (e) => {
    setTaskInfoState({ ...taskInfoState, completed: false })

    document.getElementById(`task-check-button_${subTaskInfo.id}`).classList.add('animate-bounce')

    const unDoCompletedSubTaskRes = await unCompleteSubTaskAPi(subTaskInfo.id);
    if (unDoCompletedSubTaskRes.data.status === 1) {
      enqueueSnackbarWithClose(unDoCompletedSubTaskRes.data.message, { variant: "success" });
      //***********Update the data to redux */
      setTimeout(() => {
        dispatch(subTaskAction(unDoCompletedSubTaskRes.data.data)); //update to redux state
        document.getElementById(`task-check-button_${subTaskInfo.id}`).classList.remove("animate-bounce");
      }, 1000);
      checkBoxActionData(subTaskInfo.id, unDoCompletedSubTaskRes.data.data);
    } else {
      enqueueSnackbarWithClose(unDoCompletedSubTaskRes.data.message, { variant: "error" });
      setTaskInfoState({ ...taskInfoState, completed: true });
    }
  };
  return (
    <>
      <button
        type="button"
        className={`text-xl   dark:text-white/40 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 ${subTaskInfo?.completed ? " opacity-50" : ""}`}
        onClick={(e) => {
          subTaskInfo?.completed ? unDoCompletedSubTaskMethod(e) : completeSubTaskApiCall(e);
        }}
        id={`task-check-button_${subTaskInfo.id}`}>
        {taskInfoState?.completed || subTaskInfo?.completed ? <FaRegCheckCircle className={`mr-1 ${subTaskInfo?.priority}`} /> : <FaRegCircle className={`mr-1 ${subTaskInfo?.priority} task_complete_checkbox`} />}
      </button>
    </>
  );
};

export default CompleteSubTaskCheckBox;



