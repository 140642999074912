import paretoHttp from "ParetoClient";
import { axiosInstance } from "Services/api";

export const createTaskAPI = async (payload) => {
  const createRes = await paretoHttp.post("/task/createTask", payload);
  return createRes;
};

export const getAllTasksForUserAPI = async () => {
  const getAllTasksForUserRes = await paretoHttp.post("/task/getAllTasksForUsers");//s ADD SMALL S TO GET ONLY Completed tasks

  return getAllTasksForUserRes;
};
export const getTodayForUserAPI = async () => {
  const getTodayForUserRes = await paretoHttp.post("/task/getTodayTasks");

  return getTodayForUserRes;
};
export const updatTaskForUser = async (taskObj) => {
  const updatTaskRes = await paretoHttp.post(`/task/updateTask/${taskObj.taskEditId}`, taskObj);

  return updatTaskRes;
};

export const completeTask = async (taskId) => {
  const completeTaskRes = await paretoHttp.post(`/task/${taskId}/complete`);
  return completeTaskRes;
};

export const unDoCompletedTask = async (taskId) => {
  const unDoCompletedTaskRes = await paretoHttp.post(`/task/${taskId}/undo-complete`);
  return unDoCompletedTaskRes;
};

export const getTaskByIdApi = async (taskId) => {
  const getTaskByIdApiRes = await paretoHttp.post(`/task/getTaskById/${taskId}`);
  return getTaskByIdApiRes;
};
export const deleteTaskByIDApi = async (taskId) => {
  const deleteTaskByIDApiRes = await paretoHttp.delete(`/task/deleteTaskById/${taskId}`);
  return deleteTaskByIDApiRes;
};

export const updateOnlyTaskDate = async (payload) => {
  const updateOnlyTaskDateRes = await paretoHttp.post(`/task/updateTaskDate`, payload);
  return updateOnlyTaskDateRes;
};

//-------------------------Workspace APIS Start -------------------------//


/**
 *  R
 * @param {*} i
 * @returns
 * status DONE
 */

export const EditProfile = async (payload) => {
  const UpdateProfileRes = await paretoHttp.post(`/updateUserProfile`, payload);
  return UpdateProfileRes;
};

export const createWorkSpaceAPI = async (payload) => {
  const createWorkSpace = await paretoHttp.post("workspace/createWorkspace", payload);
  return createWorkSpace;
};
export const InviteMembersAPI = async (payload, id) => {
  const inviteMembers = await paretoHttp.post(`workspace/inviteMemberToWorkspace/${id}`, payload);
  return inviteMembers;
};


export const getAllWorkspacesAPI = async () => {
  const getAllWorkspaces = await paretoHttp.get("workspace/getAllWorkspaces");
  return getAllWorkspaces;
};

export const updateWorkspaceAPI = async (id, payload) => {
  const updateWorkSpace = await paretoHttp.put(`workspace/updateWorkspace/${id}`, payload);
  return updateWorkSpace;
};


export const deleteWorkSpaceAPI = async (id) => {
  const deleteWorkSpace = await paretoHttp.delete(`workspace/deleteWorkspace/${id}`,);
  return deleteWorkSpace;
};

export const getAllWorkspaceUsersAPI = async (id) => {
  const getAllWorkspaceUsers = await paretoHttp.get(`workspace/getallworkspacemembers/${id}`);
  return getAllWorkspaceUsers;
};

export const removeCollaboratorsAPI = async (id, payload) => {
  const removeCollaborators = await paretoHttp.post(`workspace/${id}/removecollaborators`, payload);
  return removeCollaborators;
};

export const getAllProjectsAPI = async (id) => {
  const getAllProjects = await paretoHttp.get(`projects/${id}/getAllProjects`);
  return getAllProjects;
};

export const getColorsAPI = async (id) => {
  const getColors = await paretoHttp.get("colors");
  return getColors;
};

export const createProjectAPI = async (payload) => {
  const createProject = await paretoHttp.post("createProject", payload);
  return createProject;
};

export const updateProjectAPI = async (projectId, payload) => {
  const updateProject = await paretoHttp.put(`${projectId}/update`, payload);
  return updateProject;
};

export const getProjectDetailsAPI = async (id) => {
  const getProjectDetail = await paretoHttp.get(`${id}`);
  return getProjectDetail;
};

export const duplicateProjectAPI = async (id) => {
  const duplicateProject = await paretoHttp.post(`duplicateProjectWithTasks/${id}/duplicate`);
  return duplicateProject;
};

export const deleteProjectAPI = async (id) => {
  const deleteProject = await paretoHttp.delete(`${id}`);
  return deleteProject;
};

export const getFavoritesAPI = async (i) => {
  const getFavourites = await paretoHttp.get("projects/favorites");
  return getFavourites;
};
export const removeFavoriteAPI = async (id) => {
  const removeFavorite = await paretoHttp.put(`projects/removeFromFavorites/${id}`);
  return removeFavorite;
};

export const addToFavoriteAPI = async (id) => {
  const addToFavorite = await paretoHttp.put(`projects/${id}/favorite`);
  return addToFavorite;
};

export const getAllTasksForProjectsAPI = async (id) => {
  const getAllTasksForProjects = await paretoHttp.get(`/projects/${id}/tasks`);

  return getAllTasksForProjects;
};

export const exportProjectAPI = async (id) => {
  const exportProject = await paretoHttp.get(`/exportCSVProject/${id}`);

  return exportProject;
};
//-------------------------Workspace APIS End -------------------------//

//-------------------------SUB tasks APIS
/**
 *  N
 * @param {*} i
 * @returns
 * status DONE
 */
export const getAllSubTasksForTask = async (taskId) => {
  const getAllSubTasksRes = await paretoHttp.get(`subtask/${taskId}`);
  return getAllSubTasksRes;
};

/**
 *  N
 * @param {*} i
 * @returns
 *
 * status DONE
 *
 */
export const addSubTaskForTask = async (taskId, addSubTaskPay) => {
  const addSubTaskForTaskRes = await paretoHttp.post(`subtask/${taskId}`, addSubTaskPay);
  return addSubTaskForTaskRes;
};

/**
 *  N
 * @param {*} i
 * @returns
 * status DONE
 *
 */
export const updateSubTaskForTask = async (taskId, subTaskId, updatePay) => {
  const updateSubTaskForTaskRes = await paretoHttp.put(`subtask/${taskId}/${subTaskId}`, updatePay);
  return updateSubTaskForTaskRes;
};

/**
 *  N
 * @param {*} i
 * @returns
 */
export const deleteSubTaskForTask = async (taskId, subTaskId) => {
  const deleteSubTaskForTaskRes = await paretoHttp.delete(`subtask/${taskId}/${subTaskId}`);
  return deleteSubTaskForTaskRes;
};

/**
 *  N
 * @param {*} i
 * @returns
 */
export const getSubTaskById = async (subTaskId) => {
  const getSubTaskByIdRes = await paretoHttp.get(`subtask/${subTaskId}`);
  return getSubTaskByIdRes;
};

/**
 *  N
 
 * @param {*} i 
 * @returns 
 * status DONE
  */
export const completeSubTaskApi = async (subTaskId) => {
  const completeSubTaskApiRes = await paretoHttp.put(`subtask/subtasks/${subTaskId}/complete`);
  return completeSubTaskApiRes;
};

/**
 *  N
 
 * @param {*} i 
 * @returns 
 * status DONE
  */
export const unCompleteSubTaskAPi = async (subTaskId) => {
  const unCompleteSubTaskAPiRes = await paretoHttp.put(`subtask/subtasks/${subTaskId}/uncomplete`);
  return unCompleteSubTaskAPiRes;
};

/**
 * api to duplicate the existing task
 */
export const DuplicateTaskApi = async (taskId) => {
  const DuplicateTaskApiRes = await paretoHttp.post(`task/duplicate/${taskId}`);
  return DuplicateTaskApiRes;
};

// export const rescheduleOverdueTasksApi = async (reschedulrPayload) => {
//   const rescheduleOverdueTasksRes = await paretoHttp.put("task/rescheduleOverdueTasks", reschedulrPayload);
//   return rescheduleOverdueTasksRes;
// };

export const rescheduleOverdueTasksApi = async (reschedulrPayload) => {
  const rescheduleOverdueTasksRes = await paretoHttp.put("task/rescheduleOverdueTasks", reschedulrPayload);
  return rescheduleOverdueTasksRes;
};

/**
 * task/DateRange
 * 
 * For upcomming task api response
 * 
 * {
"fromDate": "2023-10-14",
"toDate": "2023-10-21"
}
 */
export const GetDateRangeTasksForUpcomming = async (payload) => {
  const GetDateRangeTasksForUpcommingRes = await paretoHttp.post("task/DateRange", payload);
  return GetDateRangeTasksForUpcommingRes;
};

/**
 * api to labels add,update,delete
 */

export const getAllLabelsAPI = async () => {
  const getAllLabelsRes = await paretoHttp.get("/task/label/getAllLabels");

  return getAllLabelsRes;
};

export const createLabelAPI = async (payload) => {
  const createLabelsRes = await paretoHttp.post("/task/label/createlabel", payload);

  return createLabelsRes;
};

export const getLabelById = async (labelId) => {
  const createLabelsRes = await paretoHttp.get(`/task/label/getLabelById/${labelId}`);

  return createLabelsRes;
};

export const updateLabelById = async (labelId, payload) => {
  const createLabelsRes = await paretoHttp.put(`/task/label/updateLabel/${labelId}`, payload);

  return createLabelsRes;
};

export const deleteLabel = async (labelId) => {
  const createLabelsRes = await paretoHttp.delete(`/task/label/deleteLabel/${labelId}`);

  return createLabelsRes;
};

export const getAllWorkspacesWithProjectsApi = async () => {
  const result = await paretoHttp.post("/getAllWorkspacesWithProjects");
  return result;
};

export const searchTaskApi = async (queryStr) => {
  const result = await paretoHttp.get(`/task/searchTask?query=${queryStr}`);
  return result;
};

export const requestPasswordResetApi = async (email) => {
  const resetResult = await axiosInstance.post("/requestPasswordReset", { email });
  return resetResult;
};

export const ResetPasswordApi = async (payload) => {
  const resetResult = await axiosInstance.post("/resetPassword", payload);
  return resetResult;
};
// *************ADMIN APIS
export const AdminGetUsersDetailsApi = async () => {
  const adminUsersResult = await axiosInstance.get('/getUsersDetails')
  return adminUsersResult
}

export const AdminToggleBlockUserApi = async (userId) => {
  const blockData = await axiosInstance.post(`/toggleBlockUser/${userId}`)
  return blockData
}

export const getusersbyidAdminApi = async(userId)=>{
  const userData = await axiosInstance.get(`/getusersbyid/${userId}`)
  return userData
}

export const getAppStactsForAdmin = async()=>{
  const dashData = await axiosInstance.get(`/getDashboardCount`)
  return dashData
}

//ADMIN LOGIIN AS USER API WITH USER ID
export const AdminLoginWithUserId = async(payload)=>{
  const UserData = await axiosInstance.post(`/loginwithuserid`,payload)
  return UserData
}

export const getAllCompletedTasks = async()=>{
    const completedTasks = await paretoHttp.post(`/task/completed?completionStatus=completed`)
    return completedTasks
}
export const getUserDeatils = async()=>{
    const userDeatils = await paretoHttp.get(`/getUserDetails`)
    return userDeatils
}