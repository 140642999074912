import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import AppSideBar from "layout/AppSideBar/AppSideBar";
import ToggleMenu from "components/ToggleMenu/ToggleMenu";
import { ImSpinner2 as SpinnerIcon } from "react-icons/im";

const Layout = () => {
  const appSideBarState = useSelector((state) => state.left_menu_on);
  // const userAuthData = useSelector((state) => state.AuthData);

  return (
    <>
      {/* <div className="light app">
        <Header />
      <div className="flex gap-2 mx-auto w-full ">
        {appSideBarState.isOpen && <Sidebar />}
        <main className={"w-full mt-14  pb-10"} style={{height:'450px'}}>
         <Outlet/>
        </main>
      </div>
    </div> */}
      <div className="bg-white dark:bg-dark-500 dark:text-white fixed-height min-h-screen">
        {/* <AppHeader /> */}
        <motion.div className="flex gap-2 mx-auto w-full ">
          {appSideBarState.isOpen ? <AppSideBar /> : <ToggleMenu />}
          <motion.main className={"w-full mt-4  pb-10 overflow-x-hidden overflow-y-auto h-[100vh]"}>
            {appSideBarState.isAppLoading&&<div className="loading-container">
              <div className="loading-spinner w-full h-full flex items-center justify-center">
              <SpinnerIcon className="text-primary animate-spin text-4xl" />
              </div>
            </div>}
            <Outlet />
          </motion.main>
        </motion.div>
      </div>
    </>
  );
};

export default Layout;




