import React, { useEffect, useState } from "react";
// import Header from "../components/Header";
import './Styles.scss'
// framer motion
import { Icon } from "@iconify/react";
// icons
import { HiOutlineAdjustments as AdjustIcon } from "react-icons/hi";
import { HiOutlinePlus as AddIcon } from "react-icons/hi";
// components
import TaskBar from "components/TaskBar/TaskBar";
// auth context
import TaskEditor from "../TaskEditor/TaskEditor";
import AddTaskForm from "components/AddTaskForm/AddTaskForm";
import { useSelector, useDispatch } from "react-redux";
import { getAllTasksForUserAPI, rescheduleOverdueTasksApi } from "ApiService";
import { InsertTasks, UpdateTask } from "store/TodayTasksDataSlice";
import moment from "moment";
import TaskInfo from "components/TaskInfo/TaskInfo";
import QuickActionForCompleteTask from "ActionItems/QuickActionForCompleteTask/QuickActionForCompleteTask";
import NoTaskIco from "assets/NoTaskIco";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BiTask } from "react-icons/bi";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const MainTasks = () => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch();
  const todayTasks = useSelector((state) => state.todayTask.todayTasks?.filter((tData) => moment(tData.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && !tData.completed));

  const priorityOrder = ["priority1", "priority2", "priority3", "priority4"];

  todayTasks.sort((a, b) => {
    const priorityA = a.priority;
    const priorityB = b.priority;

    const indexA = priorityOrder.indexOf(priorityA);
    const indexB = priorityOrder.indexOf(priorityB);

    // Sort based on the index in the priorityOrder array
    return indexA - indexB;
  });

  // Now the 'category' array is sorted by the custom priority order.
  // const overDueTasks = GetOverDueTasks();

  const currentDate = moment();
  const overDueTasks = useSelector((state) =>
    state.todayTask.todayTasks.filter((tasks) => {
      const taskDate = moment(tasks.date);
      return taskDate.isBefore(currentDate, "day") && !tasks.completed;
    })
  );

  const [showTaskInput, setShowTaskInput] = useState(false);

  const [EditId, setEditId] = useState("");

  // const [todayTasks, setTodayTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const GetTodayTasks = async () => {
    try {
      const res = await getAllTasksForUserAPI();
      if (res.data.status === 1) {
        dispatch(InsertTasks(res.data.tasks));
      } else {
        enqueueSnackbarWithClose(res.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }

    //only todays tasks
  };

  useEffect(() => {
    GetTodayTasks();
  }, []);


  const RenderComponent = () => {
    return (
      <div className="flex align-center">
        <Icon icon="mdi:calendar-refresh-outline" color="#2c3f59" width="22" />
        <button className="text-red-600 ml-1">{"Reschedule"}</button>
      </div>
    );
  };

  async function handleQuickSchedule(e) {
    let overdueIds = await overDueTasks.map((item) => {
      return {
        taskId: item.id,
        newDate: e.date,
      };
    });
    try {
      const rescheduleOverdue = await rescheduleOverdueTasksApi(overdueIds);
      if (rescheduleOverdue.data.status === 1) {
        enqueueSnackbarWithClose(rescheduleOverdue.data.message, { variant: "success" });

        rescheduleOverdue?.data?.data.forEach((data) => {
          dispatch(UpdateTask(data));
        });
      } else {
        enqueueSnackbarWithClose(rescheduleOverdue.data.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const onDragEnd = (result) => {
    if (!result.destination) return; // Task was not dropped in a valid location

    const reorderedTasks = [...todayTasks];
    const [movedTask] = reorderedTasks.splice(result.source.index, 1);
    reorderedTasks.splice(result.destination.index, 0, movedTask);

    // setTasks(reorderedTasks);
    dispatch(InsertTasks(reorderedTasks));
  };
  return (
    <>
      <div className="md:py-8 md:px-10 px-3 w-full xl:max-w-5xl mx-auto">
        {/* <Header /> */}
        <div className="flex items-center justify-between w-full gap-2 static top-0 left-0">
          <div className="flex items-center gap-2">
            <span className="block  text-lg font-bold">Today</span>
            <span className="block text-sm text-gray-800 dark:text-gray-400">
              {new Date().toLocaleDateString("en-US", {
                month: "short",
                weekday: "short",
                day: "numeric",
              })}
            </span>
            {todayTasks.length>0&& !overDueTasks.length > 0&&<span className=" flex items-center gap-2 text-xs"><BiTask/>{todayTasks.length} Tasks</span>}
          </div>
          <button type="button" className="hidden text-xl aspect-square p-2 rounded transition-all hover:bg-black/10 text-black/30 dark:text-gray-400">
            <AdjustIcon />
          </button>
        </div>
        {overDueTasks && overDueTasks.length > 0 && (
          <div className="row">
            <div className="col">
              <div className="tabs">
                <div className="tab">
                  <input type="checkbox" id="chck1" />
                  <label className="tab-label" htmlFor="chck1">
                    <div className="flex align-middle">
                      <span className="accordainIcon"></span>
                      <span className="ml-2">Overdue</span>
                      <span className="badge__danger">{overDueTasks.length}</span>
                    </div>
                    <QuickActionForCompleteTask modalType={"quickCalender"} getTaskDateData={handleQuickSchedule} rescheduleViewContent={<RenderComponent />} />
                  </label>
                  <div className="tab-content">
                    {overDueTasks &&
                      overDueTasks?.length > 0 &&
                      overDueTasks?.map((task, i) => (
                        <div key={task.id}>
                          {EditId != task.id && (
                            <TaskInfo
                              key={task.id}
                              task={task}
                              tasks={overDueTasks}
                              // setTasks={setTasks}
                              setEditId={setEditId}
                            />
                          )}
                          {EditId == task.id && (
                            <TaskEditor
                              key={i}
                              task={task}
                              tasks={overDueTasks}
                              // setTasks={setTasks}
                              setEditId={setEditId}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        )}
     
        {/* tasks list */}
        {overDueTasks.length > 0 && (
          <div className="flex items-center justify-between w-full gap-0 static top-0 left-0 mt-3">
            <div className="flex items-center gap-0">
              <h2 className="title">{moment().format("D MMMM . dddd")} . Today ({todayTasks.length} Tasks's)</h2>

            </div>
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="task-list">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {todayTasks.map((task, index) => (
                  <Draggable key={task?.id?.toString()} draggableId={task?.id?.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        // className='border p-2'
                      >
                        <TaskBar key={task.id} task={task} tasks={todayTasks} setEditId={setEditId} EditId={EditId} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* <hr /> */}
        {showTaskInput ? (
          <AddTaskForm formAbort={() => setShowTaskInput(false)} isQuick={false} />
        ) : (
          <>
            {!isLoading && (
              <button
                type="button"
                className="flex gap-2 py-2 my-2 items-center group w-full"
                onClick={() => {
                  setShowTaskInput(true);
                }}>
                <span className="text-primary transition-all group-hover:rounded-full group-hover:bg-primary group-hover:text-white p-1" aria-hidden="true">
                  <AddIcon />
                </span>
                <span className="group-hover:text-primary dark:text-gray-400">Add task</span>
              </button>
            )}
          </>
        )}

        {todayTasks?.length <= 0 && !isLoading && (
          <>
            {!showTaskInput && (
              <div className="flex items-center flex-col gap-2">
                <NoTaskIco />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default MainTasks;