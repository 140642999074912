import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  workspaces: [],
  selectedProject: null,
  selectedWorkspace: null,
  favoriteProjects: [],
  projectDetails: {},
  projects: [],
};
const UserProjectsSlice = createSlice({
  name: "user created workspaces and projects",
  initialState,
  reducers: {
    createdWorkSpaces: (state, action) => {
      state.workspaces = action.payload;
    },
    addNewWorkspace: (state, action) => {
      const newWorkspace = action.payload;
      newWorkspace.projects = [];
      state.workspaces.push(newWorkspace);
    },

    deleteWorkspace: (state, action) => {
      const workspaceIdToDelete = action.payload;
      state.workspaces = state.workspaces.filter((workspace) => workspace.id !== workspaceIdToDelete);

      state.favoriteProjects = state.favoriteProjects.reduce((acc, project) => {
        if (project.WorkspaceId !== workspaceIdToDelete) {
          acc.push(project);
        }
        return acc;
      }, []);
    },
    editWorkspaceName: (state, action) => {
      const { workspaceId, newName } = action.payload;
      const workspaceToEdit = state.workspaces?.find((workspace) => workspace.id === workspaceId);
      if (workspaceToEdit) {
        workspaceToEdit.workspaceName = newName;
      }
    },
    addMembersToWorkspace: (state, action) => {
      const { workspaceId, members } = action.payload;
      const workspaceToUpdate = state?.workspaces?.find((workspace) => workspace?.id === workspaceId);
      if (workspaceToUpdate) {
        workspaceToUpdate.members = [...workspaceToUpdate.members, ...members];
      }
    },
    updateProjectsInWorkspace: (state, action) => {
      return {
        ...state,
        workspaces: state.workspaces.map((workspace) => {
          if (workspace.id === action.payload.workspaceId) {
            return {
              ...workspace,
              projects: action.payload.projects,
            };
          }
          return workspace;
        }),
      };
    },
    updateProject: (state, action) => {
      const { workspaceId, projectId, updatedProject } = action.payload;
      state.workspaces = state.workspaces.map((workspace) => {
        if (workspace.id === workspaceId) {
          const updatedProjects = workspace.projects.map((project) => {
            if (project.id === projectId) {
              return updatedProject;
            }
            return project;
          });
          return {
            ...workspace,
            projects: updatedProjects,
          };
        }
        return workspace;
      });
    },
    deleteProject: (state, action) => {
      const data = action.payload;
      state.favoriteProjects = state.favoriteProjects.filter((project) => project.id !== data.projectId);
      state.workspaces = state.workspaces.map((workspace) => {
        if (workspace.id === data.workspaceId) {
          return {
            ...workspace,
            projects: workspace.projects.filter((project) => project.id !== data.projectId),
          };
        }
        return workspace;
      });
    },
    addFavorite: (state, action) => {
      const projectToAdd = action.payload;
      state.favoriteProjects = [...state.favoriteProjects, projectToAdd];
    },
    removeFavorite: (state, action) => {
      const projectIdToRemove = action.payload;
      state.favoriteProjects = state.favoriteProjects.filter((project) => project.id !== projectIdToRemove);
    },
    updateFavoriteProjects: (state, action) => {
      state.favoriteProjects = action.payload;
    },
    setSelectedWorkspace: (state, action) => {
      state.selectedWorkspace = action.payload;
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProject = action.payload;
    },
    ProjectDetails: (state, action) => {
      state.projectDetails = action.payload;
    },
  },
});
export const { addNewWorkspace, addMembersToWorkspace, createdWorkSpaces, editWorkspaceName, deleteWorkspace, setSelectedWorkspace, ProjectDetails, setSelectedProjectId, updateProjectsInWorkspace, updateProject, updateFavoriteProjects, deleteProject, addFavorite, removeFavorite } = UserProjectsSlice.actions;
export default UserProjectsSlice.reducer;



































