import React, { useEffect } from 'react'
import { RiPriceTag3Line } from 'react-icons/ri'
import { useSelector } from 'react-redux';

const LabelInfo = ({ taskLabels }) => {
    const userAddedLabels = useSelector((state) => state.labels.labels.find(mapD => mapD.labelName === taskLabels))
    return (
        <>
            <div>
                <span className=' flex text-xs items-center' style={{ color: `${userAddedLabels?.colorCode ?? '#808080'}` }}>
                    <span className=' p-[2px] text-xs'>
                        <RiPriceTag3Line />
                    </span>
                    <span>
                        {taskLabels}
                    </span>
                </span>

            </div>
        </>
    )
}

export default LabelInfo