import { completeTask, createTaskAPI, unDoCompletedTask } from 'ApiService';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react'
import { FaRegCircle, FaRegCheckCircle } from "react-icons/fa";
import { AddTask, UpdateTask } from "store/TodayTasksDataSlice";

import { useDispatch } from "react-redux";
import dingAudio from 'assets/audioFiles/dingAudio.wav'
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const CompleteTaskCheckBox = (props) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();

  const { taskInfo } = props
  const [taskInfoState, setTaskInfoState] = useState(taskInfo)

  const dispatch = useDispatch()
  /**
 * Play the chime sound
 */
  function playSound () {
  	let ding = new Audio(dingAudio);
  	ding.play();
  }

  const completeTaskApiCall = async (e) => {
    setTaskInfoState({ ...taskInfoState, completed: true })
    document.getElementById(`task-check-button_${taskInfo.id}`).classList.add('animate-bounce')
    // setShowTick(true);
    const completeTaskApi = await completeTask(taskInfo.id)
    try {
      if (completeTaskApi.data.status === 1) {
        enqueueSnackbarWithClose(completeTaskApi.data.message, { variant: 'success' })
        //***********Update the data to redux */
        setTimeout(() => {
          dispatch(UpdateTask(completeTaskApi.data.data.task))
          document.getElementById(`task-check-button_${taskInfo.id}`).classList.remove('animate-bounce')
        }, 50)
        playSound()
      } else {
        enqueueSnackbarWithClose(completeTaskApi.data.message, { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message ||error?.message,{variant:'error'})
      console.log(error);
    }
  };
  const unDoCompletedTaskMethod = async (e) => {
    // setShowTick(true);
    const unDoCompletedTaskRes = await unDoCompletedTask(taskInfo.id)
    try {
      if (unDoCompletedTaskRes.data.status === 1) {
        enqueueSnackbarWithClose(unDoCompletedTaskRes.data.message, { variant: "success" });
        //***********Update the data to redux */
        dispatch(UpdateTask(unDoCompletedTaskRes.data.data));
      } else {
        enqueueSnackbarWithClose(unDoCompletedTaskRes.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbarWithClose(error?.response?.data?.message ||error?.message,{variant:'error'})
      console.log(error);
    }

  };

  const addRecurringTask = async (e) => {
    // let payload = {
    //   title: taskData.task,
    //   description: taskData.taskDescription,
    //   date: taskSchedule.date ? taskSchedule.date : null,
    //   priority: `priority${taskPriority}`,
    //   reminders: [],
    //   labels: [],
    //   category: "",
    //   isFavorite: false,
    //   due: {
    //     date: taskSchedule.date ? taskSchedule.date : null,
    //     is_recurring: reccurring.is_recurring,
    //     string: reccurring.string
    //   },
    // };
    let payload = taskInfoState

    const responseDate = await createTaskAPI(payload);

    if (responseDate.data.status === 1) {
      enqueueSnackbarWithClose(responseDate.data.message, { variant: "success" });
      dispatch(AddTask(responseDate.data.data));//{}

    } else {
      enqueueSnackbarWithClose(responseDate.data.message, { variant: "error" });
    }
  }
  return (
    <>

      <button
        type="button"
        className={`text-xl text-black/50 dark:text-white/40 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 `}
        onClick={(e) => { taskInfo?.completed ? unDoCompletedTaskMethod(e) : completeTaskApiCall(e) }}
        id={`task-check-button_${taskInfo.id}`}
      >
        {taskInfoState?.completed ? <FaRegCheckCircle className={`mr-1 ${taskInfo?.priority}`} /> : <FaRegCircle className={`mr-1 ${taskInfo?.priority} task_complete_checkbox`} />}
      </button>
    </>
  )
}


export default CompleteTaskCheckBox