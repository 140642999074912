import React, { useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { EditProfile } from "../../../ApiService/index";
import { CaptureAuthData } from "store/AuthDataSlice";
import { updateUserProfile } from "../../../store/ProfileEdit";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
import { handleCharSpace } from "utils/utils";
export default function Accounts({ formAbort }) {
const { enqueueSnackbarWithClose } = SnackbarWithClose();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const userAuthData = useSelector((state) => state.AuthData);
  const [isEmailClicked, SetIsEmailClicked] = useState(false);
  const [isPasswordClicked, setIsPasswordClicked] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(userAuthData?.loginData?.userDetails?.profileImage);
  // const [fileO]
  const [name, setName] = useState(userAuthData?.loginData?.userDetails?.username || "");
  const [email, setEmail] = useState(userAuthData?.loginData?.userDetails?.email || "");

  const [isDirty, setIsDirty] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");


  const openPassword = () => {
    setIsPasswordClicked(true);
    SetIsEmailClicked(false);
  };
  const handleGoBack = () => {
    SetIsEmailClicked(false);
    setIsPasswordClicked(false);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    
    if (file && file.type.startsWith('image/')) {
      const obj = URL.createObjectURL(file)
      const reader = new FileReader();
      reader.onload = () => {
        setProfilePhoto(reader.result);
        //**********************API CALL TO FILE UPLOAD */
        handleUserProfilePic(file, reader.result);
      };
      reader.readAsDataURL(file);
    }else{
      enqueueSnackbarWithClose('Please select valid image file', { variant: 'error' })
    }
  };
  const handleUpdate = async () => {
    try {
      if (isLoading) {
        return;
      }
      setError("");
      setIsLoading(true);

      if (!name) {
        setError("This field can't be empty");
      } else {
        const payload = {
          username: name,
        };

        const UpdateProfileRes = await EditProfile(payload);

        dispatch(updateUserProfile(payload));
        let loginData = {
          refreshToken: userAuthData.loginData.refreshToken,
          accessToken: userAuthData.loginData.accessToken,
          userDetails: {
            ...userAuthData.loginData.userDetails,
            username: payload.username,
          },
        };

        dispatch(CaptureAuthData(["email", loginData, true]));
        enqueueSnackbarWithClose("Username Updated Successfully", { variant: "success" });

        setIsDirty(false);
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserProfilePic = async (fileObj, previewUrl) => {
    const profileLoad = new FormData();
    profileLoad.append("profileImage", fileObj);
    try {
      setLoading(true);
      const UpdateProfileRes = await EditProfile(profileLoad);

      if (UpdateProfileRes.data.status === 1) {
        // dispatch(updateUserProfile(payload));
        let loginData = {
          refreshToken: userAuthData.loginData.refreshToken,
          accessToken: userAuthData.loginData.accessToken,
          userDetails: {
            ...userAuthData.loginData.userDetails,
            profileImage: previewUrl,
          },
        };

        dispatch(CaptureAuthData(["email", loginData, true]));
        setLoading(false);
        enqueueSnackbarWithClose(UpdateProfileRes.data.message, { variant: "success" });
      } else {
        enqueueSnackbarWithClose(UpdateProfileRes.data.message, { variant: "success" });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbarWithClose(error.message, { variant: "error" });
      setProfilePhoto(userAuthData?.loginData?.userDetails?.profileImage);
      console.log(error);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setIsDirty(true);
  };

  const handleCancel = () => {
    setName(userAuthData?.loginData?.userDetails?.username || "");
    setIsDirty(false);
  };

  return (
    <>
      {!isEmailClicked && !isPasswordClicked && (
        <div>
          <h2 className="text-xl text-black font-semibold ">Account</h2>
          <hr />
          {/* <div className="flex mt-4 items-center justify-between">
            <div>
              <div className="leading-none text-black text-lg font-bold">Plan</div>
              <div className="text-black text-lg font-bold">Free</div>
            </div>
            <div className="text-black bg-gray-100 p-2 font-bold">Manage Plan</div>
          </div>
          <hr /> */}
          <div className="profile-container mt-2">
            {profilePhoto ? <img referrerPolicy="no-referrer" src={profilePhoto} alt="" className="object-cover rounded-[50%] h-28 w-28" /> : <span className="user-display-character py-[20px] px-[30px] text-4xl text-white uppercase border rounded-[50%] bg-blue-500 font-semibold  ">{userAuthData?.loginData?.userDetails?.username?.charAt(0)}</span>}
            {loading ? (
              <strong className=" ml-2 text-blue-500">Loading...</strong>
            ) : (
              <>
                <label htmlFor="photo-upload" className="ml-3 bg-slate-100 px-3 py-1 cursor-pointer">
                  Upload Photo
                </label>
                <input type="file" id="photo-upload" accept="image/*" className="file-input" onChange={handlePhotoChange} />
              </>
            )}
          </div>
          <form className="mt-5">
            <div>
              <label htmlFor="username" className="text-black font-bold label ml-0 text-lg">
                Name
              </label>
              <input className="border border-slate-400 rounded p-2 w-[50%]" type="text" name="username" id="username" autoComplete="off" value={name} onChange={handleNameChange} onKeyDown={handleCharSpace}/>
              {error && <p className="text-red-500">{error}</p>}
            </div>
          </form>
          <div className="mt-4">
            <label htmlFor="email" className="label ml-0 font-bold text-black text-lg">
              Email
            </label>
            <div>{email}</div>

            {/* <button type="button" className="bg-slate-100 px-2 py-1 submit-button mt-2" onClick={openEmail}>
              Change Email
            </button> */}
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="label ml-0 text-lg font-bold text-black">
              Password
            </label>
            {/* <button type="button" className="bg-slate-100 px-2 py-1 submit-button cursor-not-allowed" onClick={openPassword}> */}
            <button type="button" className="bg-slate-100 px-2 py-1 submit-button" onClick={openPassword}>
              Change Password
            </button>
          </div>

          <div className="flex justify-end">
            {isDirty && (
              <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={handleCancel}>
                Cancel
              </button>
            )}
            {isDirty && (
              <button disabled={name?.trim()?.length===0} className="bg-green-500 text-white px-4 py-2 rounded" onClick={handleUpdate}>
                Update
              </button>
            )}
          </div>
        </div>
      )}
      {isEmailClicked && <ChangeEmail setActive={() => SetIsEmailClicked(true)} onBack={handleGoBack} />}
      {isPasswordClicked && <ChangePassword setActive={() => setIsPasswordClicked(true)} onBack={handleGoBack} />}
    </>
  );
}