import React, { useEffect, useState } from "react";

//----- REDUX ACTIONS
import { UpdateTask } from "store/TodayTasksDataSlice";

import { ImSpinner8 as SpinnerIcon } from "react-icons/im";
import {
    IoFlagOutline as FlagIcon,
    IoAlarmOutline as ClockIcon,
} from "react-icons/io5";
import { ImPriceTag as TagIcon } from "react-icons/im";

import TaskDatePicker from "ActionItems/TaskDatePicker/TaskDatePicker";
import PriorityPicker from "ActionItems/PriorityPicker/PriorityPicker";
import { extractNumberFromString } from "utils/utils";
import { getDayAndDateObjFromISO } from "DateHelpers/Datehelpers";
import { updatTaskForUser } from "ApiService";
import { useDispatch } from "react-redux";
import SnackbarWithClose from 'ActionItems/SnackbarWithClose/SnackbarWithClose';
const UpcomeTaskEditor = ({ task, tasks, setTasks, setEditId }) => {
const { enqueueSnackbarWithClose } = SnackbarWithClose();

    const dispatch = useDispatch()

    const [taskData, setTaskData] = useState({
        title: task.title,
        description: task.description,
    });
    const [formLoading, setFormLoading] = useState(false);
    const [taskSchedule, setTaskSchedule] = useState({ day: "", date: null });

    const [taskPriority, setTaskPriority] = useState(4)

    /**
     * priority variable
     */
    useEffect(() => {
        // setTaskSchedule()
        if (task?.date) {
            setTaskSchedule(getDayAndDateObjFromISO(task?.date))
        }
        setTaskPriority(extractNumberFromString(task.priority))
    }, [task])

    const modifyTask = async(e) => {
        setFormLoading(true)
        e.preventDefault()

        const editPayload = {
            "title": taskData.title,
            "description": taskData.description,
            "date": taskSchedule.date ? taskSchedule.date : null,
            "priority": `priority${taskPriority}`,
            "reminders": [],
            "labels": task?.labels,
            "category": "",
            "isFavorite": false,
            "taskEditId":task?.id,
            projectId: task?.projectId
        }
        const updateTaskAPIResponse = await updatTaskForUser(editPayload).finally(() => {
            setFormLoading(false)
        })
        if(updateTaskAPIResponse.data.status===1){
            enqueueSnackbarWithClose(updateTaskAPIResponse.data.message,{variant:'success'})
            dispatch(UpdateTask(updateTaskAPIResponse.data.data))
            setEditId('');
        }else{
            enqueueSnackbarWithClose(updateTaskAPIResponse.data.message,{variant:'error'})
        }
    }

    return (
        <>
            <form
                  onSubmit={(e)=>modifyTask(e)} 
                className="w-full border-2 rounded-lg">
                <div className="flex flex-col gap-2 dark:border-white/20 rounded-lg p-3">
                    <input
                        type="text"
                        className="text-base font-medium outline-none disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                        placeholder="Task Name"
                        value={taskData.title}
                        disabled={formLoading}
                        autoFocus
                        onChange={({ target }) =>
                            setTaskData({
                                ...taskData,
                                title: target.value,
                            })
                        }
                    />
                    <textarea
                        placeholder="Description"
                        className="text-sm outline-none h-auto  focus:border   focus:rounded  transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-40 bg-transparent"
                        disabled={formLoading}
                        value={taskData.description}
                        onChange={({ target }) =>
                            setTaskData({
                                ...taskData,
                                description: target.value,
                            })
                        }
                    />
                    <div className="flex w-full items-center justify-between">
                        <div className="flex w-full items-center gap-2">
                            <TaskDatePicker getTaskDateData={setTaskSchedule} taskSchedule={taskSchedule} />
                            <PriorityPicker setTaskPriority={setTaskPriority} taskPriority={taskPriority} />
                        </div>
                        <div className="items-center gap-2 hidden">
                            <button
                                type="button"
                                className="text-xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <TagIcon />
                            </button>
                            <button
                                type="button"
                                className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <FlagIcon />
                            </button>
                            <button
                                type="button"
                                className="text-2xl p-2 rounded transition-all hover:bg-black/10 dark:hover:bg-white/10 text-black/30 dark:text-white/20"
                            >
                                <ClockIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex w-full p-2 items-center justify-end gap-2">
                    <button
                        type="button"
                        className="bg-gray-100 dark:bg-dark-100 dark:text-white dark:hover:bg-white/20 py-2 px-4 text-sm font-bold text-black/70 rounded hover:bg-gray-200"
                        onClick={() => {
                            setEditId('');
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-primary py-2 px-4 text-sm font-bold text-white/90 rounded hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-40"
                        disabled={!taskData.title?.trim() || formLoading}
                    >
                        {formLoading ? (
                            <span className="flex items-center gap-2">
                                <SpinnerIcon className="animate-spin" />
                                <span>Loading...</span>
                            </span>
                        ) : (
                            'Modify Task'
                        )}
                    </button>
                </div>
            </form>
        </>
    )
}

export default UpcomeTaskEditor